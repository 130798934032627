import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Chip } from "@mui/material";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import {
  ScholarshipApplicationRecord,
  ScholarshipRecord,
  StudentRecord,
} from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import { AvatarCell } from "../../components/scholarships/AvatarCell";

type Row = {
  id: string;
  scholarshipId: string;
  scholarshipName: string | null;
  studentName: string | null;
  submittedOn: string | null;
  status: string | null;
  awardAmount: number | null;
  hasApplications: boolean;
  applicationCount: number;
  avatar: string | null;
};

const useScholarshipTable = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const allStudents = useRecoilValue(allSchoolStudentsAtom);
  const { getFSDocs } = useGetFSDocs();

  const buildRow = ({
    scholarships,
    applications,
    students,
  }: {
    scholarships: ScholarshipRecord[];
    applications: ScholarshipApplicationRecord[];
    students: StudentRecord[];
  }) => {
    const rows: Row[] = [];
    scholarships.forEach((scholarship) => {
      const studentApplications = applications.filter(
        (app) => app.scholarshipId === scholarship.id
      );
      studentApplications.forEach((application) => {
        const student = students.find((student) => student.id === application.studentId);
        if (!student) return;
        rows.push({
          id: application.id,
          scholarshipName: `${scholarship.name} - ${scholarship.sponsorName}`,
          scholarshipId: scholarship.id,
          studentName: `${student.firstName} ${student.lastName}`,
          submittedOn: application?.appliedDate || "",
          status: application.status,
          awardAmount: Number(application.awardAmount) || 0,
          hasApplications: true,
          applicationCount: 1, // Count of applications
          avatar: student.avatar ? student.avatar : "",
        });
      });
    });
    return rows;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tempscholarshipApplications, tempscholarships] = await Promise.all([
          getFSDocs<ScholarshipApplicationRecord>({
            col: Collection.SCHOLARSHIP_APPLICATIONS,
          }),
          getFSDocs<ScholarshipRecord>({
            col: Collection.SCHOLARSHIPS,
          }),
        ]);
        const tempRows = buildRow({
          scholarships: tempscholarships,
          applications: tempscholarshipApplications,
          students: allStudents,
        });

        setRows(tempRows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [allStudents, getFSDocs]);

  const columns: GridColDef[] = [
    {
      field: "scholarshipId",
      headerName: "Scholarship Name",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        // Check if params.value is defined, since it's used for row grouping
        if (params.value) {
          const row = rows.find((row) => row.scholarshipId === params.value);
          if (!row || !row.scholarshipName) return null;
          const [scholarshipName, sponsorName] = row.scholarshipName.split(" - ");

          return (
            <div>
              <div style={{ lineHeight: 1.3 }}>{scholarshipName}</div>
              {sponsorName && (
                <div style={{ fontSize: "0.55rem", color: "#888", lineHeight: 1.3 }}>
                  {sponsorName}
                </div>
              )}
            </div>
          );
        }
        return null; // Handle cases where there is no value
      },
      sortComparator: (v1, v2, param1, param2) => {
        const row1 = rows.find((r) => r.scholarshipId === param1.value);
        const row2 = rows.find((r) => r.scholarshipId === param2.value);
        
        const name1 = row1?.scholarshipName?.split(" - ")[0] || '';
        const name2 = row2?.scholarshipName?.split(" - ")[0] || '';
        
        return name1.localeCompare(name2);
      },
    },
    {
      field: "applicationCount",
      headerName: "Total",
      minWidth: 40,
      type: "number",
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return params.value;
        } else {
          return null;
        }
      },
    },
    {
      field: "awardAmount",
      headerName: "Award Amount",
      minWidth: 80,
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return `$${params.formattedValue}`;
      },
    },
    {
      field: "studentName",
      headerName: "Student Name",
      minWidth: 120,
      flex: 2,
      renderCell: (params) => {
        // Only show avatars for student rows
        if (params.row.studentName) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvatarCell avatar={params.row.avatar} studentName={params.row.studentName} />
              <span>{params.row.studentName}</span>
            </div>
          );
        }
        return params.row.studentName; // No avatar for scholarship rows
      },
    },
    {
      field: "submittedOn",
      headerName: "Submitted On",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Chip
            sx={{ fontSize: 10 }}
            label={params.value}
            color={
              params.value === "applied"
                ? "lightGreen"
                : params.value === "awarded"
                ? "primary"
                : params.value === "rejected"
                ? "lightPink"
                : "default"
            }
          />
        ) : (
          ""
        );
      },
    },
  ];
  return { rows, columns };
};

export default useScholarshipTable;
