import ExploreCareersContainer from "../components/exploreCareers/ExploreCareersContainer";

const ExploreCareersPage = () => {
  return (
    <>
      <ExploreCareersContainer />
    </>
  );
};

export default ExploreCareersPage;
