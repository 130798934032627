import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useSetRecoilState } from "recoil";
import { loggedInStudentAtom, mentorUsersAtom } from "../../recoil/userAtoms";
import useUpdateFSDoc from "../db/useUpdateFSDoc";
import { Collection } from "../../types/enums";
import useLogger from "../logging/useLogger";
import { LogEventType } from "../../types/logEnums";

type Props = {
  fileName: string;
  collection: Collection;
  documentId: string;
  url?: string;
};

const useCustomAvatar = ({ fileName, collection, documentId, url }: Props) => {
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const [avatarURL, setAvatarURL] = useState<string | null>(url ?? null);
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  const getImageURL = useCallback(
    async (fileName: string) => {
      if (fileName.length <= 5) return "";
      try {
        const newURL = await getDownloadURL(ref(storage, `${fileName}_200x200`));
        return newURL;
      } catch (error) {
        console.warn("200x200 image not available yet, falling back to original image.");
        try {
          const newURL = await getDownloadURL(ref(storage, `${fileName}`));
          return newURL;
        } catch (error) {
          submitLog({
            eventType: LogEventType.AVATAR_DOWNLOAD_ERROR,
            file: "useCustomAvatar.ts",
            error: error,
          });

          return "";
        }
      }
    },
    [submitLog, storage]
  );

  useEffect(() => {
    const getURL = async () => {
      const imageURL = await getImageURL(fileName);
      setAvatarURL(imageURL);
    };
    if (fileName) {
      getURL();
    }
  }, [fileName, getImageURL]);

  const uploadImage = useCallback(
    async ({
      image,
      col,
      id,
    }: {
      image: File | null;
      col: Collection;
      id: string | null;
    }): Promise<string | null> => {
      if (!image || !id) return null;

      const newFileName = Number(new Date()).toString();
      const storageRef = ref(storage, newFileName);
      await uploadBytes(storageRef, image);

      try {
        await updateFSDoc({
          col,
          id,
          data: { avatar: newFileName },
        });
        submitLog({
          eventType: LogEventType.AVATAR_UPLOADED,
          file: "useCustomAvatar.ts",
        });
      } catch (error) {
        submitLog({
          eventType: LogEventType.AVATAR_UPLOAD_ERROR,
          file: "useCustomAvatar.ts",
          error: error,
        });
      }
      return newFileName;
    },
    [storage, submitLog, updateFSDoc]
  );

  const handleSelectImage = useCallback(
    async ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (!files || !files[0]) return;
      setLoading(true);
      const newFileName = await uploadImage({
        image: files[0],
        col: collection,
        id: documentId,
      });

      if (newFileName) {
        const newURL = await getImageURL(newFileName);
        setAvatarURL(newURL);
        if (collection === Collection.STUDENTS) {
          setLoggedInStudent((prev) =>
            prev ? { ...prev, avatar: newFileName, avatarURL: newURL } : null
          );
        } else if (collection === Collection.MENTOR_USERS) {
          setMentorUsers((prev) =>
            prev.map((user) =>
              user.id === documentId ? { ...user, avatar: newFileName, avatarURL: newURL } : user
            )
          );
        }
      }
      setLoading(false);
    },
    [collection, documentId, getImageURL, setLoggedInStudent, setMentorUsers, uploadImage]
  );

  return { loading, avatarURL, handleSelectImage };
};

export default useCustomAvatar;
