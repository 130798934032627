import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import useResetQuizResultsDialog from "../../hooks/quizResults/useResetQuizResultsDialog";
import { UserType } from "../../types/enums";

type Props = {
  open: boolean;
  userType: UserType;
  setOpen: (open: boolean) => void;
};

const ResetQuizResultsDialog = ({ open, userType, setOpen }: Props) => {
  const { loading, resetQuizResults } = useResetQuizResultsDialog({ userType });

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Quiz Results</DialogTitle>
      <DialogContent sx={{ px: 0, textAlign: "center" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography sx={{ p: 2 }}>
              Are you sure you want to reset your quiz results? This will reset all your answers and
              you will have to retake the quiz.
            </Typography>
            <Button variant="contained" onClick={resetQuizResults}>
              Yes, Reset My Quiz Results
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetQuizResultsDialog;
