import React, { useState, useCallback, useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import {
  Hits,
  InstantSearch,
  Configure,
  connectSearchBox,
} from "react-instantsearch-dom";
import { TextField, Box, Autocomplete } from "@mui/material";
import { SearchedProvider } from "../../../../shared/types/types";

type Props = {
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<
    React.SetStateAction<SearchedProvider[]>
  >;
};

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID
    ? process.env.REACT_APP_ALGOLIA_APP_ID
    : "",
  process.env.REACT_APP_ALGOLIA_APP_KEY
    ? process.env.REACT_APP_ALGOLIA_APP_KEY
    : ""
);

type CustomSearchBoxProps = {
  currentRefinement: string;
  refine: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  onChange: (value: string) => void;
  tags: string[];
  onTagChange: (value: string[]) => void;
};

const CustomSearchBox = React.memo(
  ({
    refine,
    onFocus,
    onBlur,
    value,
    onChange,
    tags,
    onTagChange,
  }: CustomSearchBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: any, newValue: string) => {
      onChange(newValue);
      refine(newValue);
    };

    const handleTagChange = (event: any, newTags: string[]) => {
      onTagChange(newTags);
    };

    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={tags}
        onChange={handleTagChange}
        inputValue={value}
        onInputChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            autoComplete="off"
            name="search"
            placeholder="Search for a college or organization by name"
            fullWidth
            onFocus={onFocus}
            onBlur={onBlur}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "8px",
              },
            }}
          />
        )}
      />
    );
  }
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

export const SearchFilter = ({
  searchedProviders,
  setSearchedProviders,
}: Props) => {
  const [showHits, setShowHits] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const hitClickedRef = useRef(false); // Add a ref to track if a hit was clicked

  const handleSearchFocus = useCallback(() => {
    //setShowHits(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    setTimeout(() => {
      if (!hitClickedRef.current) {
        // Only hide the hits if no hit was clicked
        setShowHits(false);
        if (searchedProviders.length === 0) {
          setSearchValue("");
        }
      }
      hitClickedRef.current = false; // Reset the ref after blur handling
    }, 200); // Delay the blur action
  }, [searchedProviders]);

  const handleHitClick = useCallback(
    (hit: any) => {
      hitClickedRef.current = true; // Mark that a hit was clicked
      setSearchValue("");
      setSearchedProviders((prev) => {
        const exists = prev.some(
          (provider) => provider.providerId === hit.objectID
        );

        if (!exists) {
          return [
            ...prev,
            {
              providerId: hit.objectID,
              providerName: hit.providerName,
            },
          ];
        }

        return prev;
      });
      setShowHits(false);
    },
    [setSearchValue, setSearchedProviders, setShowHits]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (value.length > 0) {
        setShowHits(true);
      }
    },
    [setSearchValue, setShowHits]
  );

  const handleSearchTagChange = useCallback(
    (value: string[]) => {
      const filteredProviders = searchedProviders.filter((provider) =>
        value.includes(provider.providerName)
      );

      setSearchedProviders(filteredProviders);
    },
    [searchedProviders, setSearchedProviders]
  );

  return (
    <InstantSearch searchClient={searchClient} indexName="providerName">
      <Configure hitsPerPage={10} />
      <Box sx={{ position: "relative", width: "100%" }}>
        <ConnectedSearchBox
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          value={searchValue}
          onChange={handleSearchChange}
          tags={searchedProviders.map((provider) => provider.providerName)}
          onTagChange={handleSearchTagChange}
        />

        {showHits && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              zIndex: 999,
              backgroundColor: "white",
              boxShadow: 2,
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <Hits
              hitComponent={({ hit }) => (
                <Box
                  sx={{
                    margin: "-20px",
                    width: "100%",
                    height: "100%",
                    padding: 1,
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                    cursor: "pointer",
                  }}
                  onClick={() => handleHitClick(hit)}
                >
                  {hit.providerName}
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
    </InstantSearch>
  );
};
