import { UserType } from "../types/enums";
import {
  EFCDataRecord,
  MentorUserRecord,
  Message,
  StaffRecord,
  VideoInteractionRecord,
} from "../types/types";

export const BLANK_EFC_DATA_FORM: EFCDataRecord = {
  state: "",
  maritalStatus: "married",
  householdSize: 0,
  childrenUnder18: 0,
  childrenInCollege: 0,
  parentIncome: 0,
  parentUntaxedIncome: 0,
  parentAssets: 0,
  studentIncome: 0,
  studentAssets: 0,
  ageOfOldestParent: 0,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
};

export const BLANK_MENTOR_USER_FORM: MentorUserRecord = {
  firstName: "",
  lastName: "",
  avatar: "",
  userType: UserType.MENTOR,
  email: "",
  klaviyoProfileId: "",
  studentIds: [],
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
};

export const BLANK_VIDEO_INTERACTION_FORM: VideoInteractionRecord = {
  studentId: "",
  videoId: "",
  watched: false,
  interaction: "none",
  timestamp: "",
  onetCode: "",
  clickedLearnMore: false,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  schoolId: "",
  districtId: "",
};

export const BLANK_MESSAGE_FORM: Message = {
  message: "",
  timestamp: "",
  staffId: "",
  studentId: "",
  studentPhoneNumber: "",
  authorId: "",
  staffPhoneNumber: "",
  schoolId: "",
  districtId: "",
};

export const BLANK_STAFF_FORM: StaffRecord = {
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  schoolId: null,
  enrolled: true,
  districtId: null,
  userType: UserType.TEACHER,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  jobTitle: "",
  initialPasswordReset: false,
  smsNumber: "",
  klaviyoProfileId: "",
  uidId: true,
  oldId: "",
};
