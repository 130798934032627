import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";

type Props = {
  admit: boolean;
  scholarship: boolean;
};

const useAutoAdmitMessage = ({ scholarship }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const lowIncome = loggedInStudent?.frlStatus;
  const autoAdmitText = `Based on your profile, you are pre-admitted to the program ${
    lowIncome && scholarship ? "with a scholarship" : ""
  } pending you complete the following:`;

  return {
    autoAdmitText,
    loggedInStudent,
    lowIncome,
  };
};

export default useAutoAdmitMessage;
