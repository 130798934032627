import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color } from "../../../shared/types/enums";
import blankHeartedCareersImage from "../../../shared/assets/icons/blank-hearted-careers-image.svg";
import { Box, Button, Typography } from "@mui/material";
import StaffJobTitleCard from "./StaffJobTitleCard";
import { JobDetailsRecord } from "../../../shared/types/types";
import RecommendJobDialog from "../exploreCareers/RecommendJobDialog";

type Props = {
  recommendedJobs: JobDetailsRecord[];
  handleSwitchMode: () => void;
};

const RecommendedCareers = ({ recommendedJobs, handleSwitchMode }: Props) => {
  return (
    <>
      <DisplayBox
        title="Recommended Careers"
        titleSX={{ color: Color.CHALKBOARD }}
        actionButton={<Button onClick={handleSwitchMode}>Switch to Bookmarked Careers</Button>}
      >
        {recommendedJobs.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 1.5,
              height: "300px",
            }}
          >
            <img src={blankHeartedCareersImage} alt="icon" width="200px" />
            <Typography variant="body1" color={Color.GRAY_800}>
              This student doesn't have any recommended careers, yet.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "300px",
              overflowY: "scroll",
            }}
          >
            {recommendedJobs.map((job, index: number) => (
              <StaffJobTitleCard key={index} job={job} />
            ))}
          </Box>
        )}
      </DisplayBox>

      <RecommendJobDialog />
    </>
  );
};

export default RecommendedCareers;
