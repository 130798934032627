import { atom } from "recoil";
import { AnswerRecord, QuestionRecord } from "../../shared/types/types";

export const questionsAtom = atom<QuestionRecord[]>({
  key: "questionsAtom",
  default: [],
});

export const answersAtom = atom<AnswerRecord[]>({
  key: "answersAtom",
  default: [],
});

export const currentQuestionIndexAtom = atom<number>({
  key: "currentQuestionIndexAtom",
  default: 0,
});
