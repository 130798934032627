import {
  Box,
  Typography,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Grid,
  IconButton,
  DialogContent,
} from "@mui/material";
import Reactions from "../../../students/components/exploreCareers/Reactions";
import useJobDetails from "../../hooks/jobDetails/useJobDetails";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Color } from "../../../shared/types/enums";
import QuickLook from "../../../shared/components/jobDetails/QuickLook";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import VideoListPlayer from "../videoList/VideoListPlayer";
import { Player } from "@lottiefiles/react-lottie-player";
import { useCallback, useEffect, useRef, useState } from "react";
import useGetWageData from "../../hooks/jobDetails/useGetWageData";
import { WageDataRecord } from "../../../shared/types/types";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";
import SiteLoadingPage from "../../../shared/pages/SiteLoadingPage";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import jobDescriptions from "../../../shared/assets/data/jobDescriptions.json";
import WhatIsThat from "../../../shared/components/whatIsThat/WhatIsThat";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

const JobDetailsContainer = () => {
  const [wageData, setWageData] = useState<WageDataRecord | null>(null);
  const [wageLabel, setWageLabel] = useState<string | null>(null);
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  const {
    mobile,
    jobData,
    industryData,
    industryGroupData,
    fieldData,
    tableStyles,
    progreeBarstyles,
    similarJobsStyles,
    educationAttainmentData,
    videos,
    interactions,
  } = useJobDetails({ jobId: selectedJobId });
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const lottieRef = useRef<any>(null);
  const { getWageData } = useGetWageData();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const callGetWageData = async () => {
      const wageDataResponse = await getWageData({
        city: loggedInStudent?.address.city || "",
        state: loggedInStudent?.address.state || "",
        onetCode: selectedJobId ? selectedJobId : "",
      });
      setWageData(wageDataResponse.wageData);
      setWageLabel(wageDataResponse.label);
    };
    callGetWageData();
  }, [getWageData, selectedJobId, loggedInStudent]);

  const onSimilarJobSelect = useCallback((jobId: string) => {
    setSelectedJobId(jobId);
    scrollRef.current && scrollRef.current.scrollIntoView({behavior: 'smooth'})
  }, [setSelectedJobId])

  if (jobData) {
    return (
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} ref={scrollRef}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {jobData.bright_outlook && (
                <Box sx={{ display: "flex" }}>
                  <Chip
                    label="Bright Outlook"
                    size="small"
                    sx={{
                      color: Color.CHALKBOARD,
                      backgroundColor: Color.SURFACE_GREEN,
                    }}
                  />
                  <WhatIsThat information={jobDescriptions.bright_outlook} />
                </Box>
              )}
              <Typography variant="h4" fontSize={mobile ? 24 : 30} sx={{ mt: 0.5 }}>
                {jobData?.title}
              </Typography>
            </Box>
            <Reactions jobId={jobData.id} />
          </Box>

          <Grid container spacing={mobile ? 4 : 5}>
            <Grid item xs={12} sm={6}>
              <Box>
                {/* <Typography variant="h5">Recommendations</Typography> */}
                {loggedInStudent?.willowRecommendedJobIds.includes(jobData.id) && (
                  <Chip
                    label="Willow Recommended"
                    size="small"
                    sx={{ color: Color.SURFACE_GREEN, backgroundColor: Color.MINT }}
                  />
                )}
              </Box>

              <Box sx={{ mt: mobile ? 4 : 5 }}>
                <Typography variant="h5">Job Description</Typography>
                <Typography sx={{ mt: 1 }} variant="body1">
                  {jobData.description}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    Industry: {industryData.find(item => item.id === jobData.industry_id)?.title}
                  </Typography>
                  <Typography variant="body2">
                    Field: {fieldData.find(item => item.id === jobData.industry_sub_id)?.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    mt: 2,
                  }}
                >
                  <Typography variant="h5">Overview</Typography>
                  <Typography
                    variant="body1"
                    color={Color.DARK_TEAL}
                    fontSize={mobile ? 10 : 12}
                    fontWeight={500}
                  >
                    National
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    px: 1,
                    py: 1.25,
                    backgroundColor: Color.WHITE,
                    border: 1,
                    borderColor: Color.GRAY_200,
                    borderRadius: "12px",
                  }}
                >
                  <QuickLook job={jobData} />
                </Box>
              </Box>

              <Box sx={{ mt: mobile ? 4 : 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h5">Pay Range</Typography>
                    <WhatIsThat information={jobDescriptions.entry_salary_and_medium_salary_and_high_salary} />
                  </Box>
                  <Typography
                    variant="body1"
                    color={Color.DARK_TEAL}
                    fontSize={mobile ? 10 : 12}
                    fontWeight={500}
                  >
                    {wageLabel}
                  </Typography>
                </Box>

                <TableContainer component={Paper} sx={tableStyles.container}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={tableStyles.tableHeader}>Type</TableCell>
                        <TableCell sx={tableStyles.tableHeader} align="center">
                          Entry
                        </TableCell>
                        <TableCell sx={tableStyles.tableHeader} align="center">
                          Medium
                        </TableCell>
                        <TableCell sx={tableStyles.tableHeader} align="center">
                          High
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={tableStyles.header}>
                          Salary
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.A_PCT10
                            ? formatCurrency({ amount: parseInt(wageData.A_PCT10) })
                            : "Unknown"}
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.A_MEDIAN
                            ? formatCurrency({ amount: parseInt(wageData.A_MEDIAN) })
                            : "Unknown"}
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.A_PCT90
                            ? formatCurrency({ amount: parseInt(wageData.A_PCT90) })
                            : "Unknown"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={tableStyles.header}>
                          Hourly Wage
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.H_PCT10
                            ? formatCurrency({
                                amount: parseFloat(wageData.H_PCT10),
                                decimalPlaces: 2,
                              })
                            : "Unknown"}
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.H_MEDIAN
                            ? formatCurrency({
                                amount: parseFloat(wageData.H_MEDIAN),
                                decimalPlaces: 2,
                              })
                            : "Unknown"}
                        </TableCell>
                        <TableCell align="center" sx={tableStyles.typeCell}>
                          {wageData?.H_PCT90
                            ? formatCurrency({
                                amount: parseFloat(wageData.H_PCT90),
                                decimalPlaces: 2,
                              })
                            : "Unknown"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box sx={{ mt: mobile ? 4 : 5 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h5">Education Attainment</Typography>
                  <WhatIsThat information={jobDescriptions.education_attainment} />
                </Box>
                <Box sx={progreeBarstyles.container}>
                  <Box>
                    <Box sx={progreeBarstyles.progressLabel}>
                      <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                        High School Diploma
                      </Typography>
                      <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                        {educationAttainmentData?.highSchool}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Number(educationAttainmentData?.highSchool)}
                      sx={progreeBarstyles.progressBar}
                    />
                  </Box>
                  <Box>
                    <Box sx={progreeBarstyles.progressLabel}>
                      <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                        Associates
                      </Typography>
                      <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                        {educationAttainmentData?.associates}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Number(educationAttainmentData?.associates)}
                      sx={progreeBarstyles.progressBar}
                    />
                  </Box>
                  <Box>
                    <Box sx={progreeBarstyles.progressLabel}>
                      <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                        Bachelors
                      </Typography>
                      <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                        {educationAttainmentData?.bachelors}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Number(educationAttainmentData?.bachelors)}
                      sx={progreeBarstyles.progressBar}
                    />
                  </Box>
                  <Box>
                    <Box sx={progreeBarstyles.progressLabel}>
                      <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                        Masters
                      </Typography>
                      <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                        {educationAttainmentData?.masters}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Number(educationAttainmentData?.masters)}
                      sx={progreeBarstyles.progressBar}
                    />
                  </Box>
                  <Box>
                    <Box sx={progreeBarstyles.progressLabel}>
                      <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                        PHD
                      </Typography>
                      <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                        {educationAttainmentData?.phd}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Number(educationAttainmentData?.phd)}
                      sx={progreeBarstyles.progressBar}
                    />
                  </Box>
                </Box>

                <Box sx={{ mt: mobile ? 4 : 5 }}>
                <Typography variant="h5">Similar Jobs</Typography>
                  <Box sx={similarJobsStyles.container}>
                    {industryGroupData?.filter(i => i.id !== selectedJobId ).map((i, index) => (
                      <Typography key={index} variant="body1" sx={similarJobsStyles.similarJobButton}>
                        {i.title}
                        <IconButton 
                          onClick={() => onSimilarJobSelect(i.id)}
                          aria-label="arrow-right"
                          content="span"
                          sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <FeatherIcon
                            icon="arrow-right"
                            strokeColor={"transparent"}
                            fillColor={"black"}
                            width="22px"
                            height="22px"
                          />
                        </IconButton>
                      </Typography>                    
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="h5">Videos</Typography>
                {videos.length === 0 ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="h4">
                      We haven't found any videos about this career, yet!
                    </Typography>
                    {!disableLottie && (
                      <Player
                        ref={lottieRef}
                        loop={true}
                        autoplay={true}
                        src="https://lottie.host/a3a8bd74-2165-4d1c-9192-63d7942aca8f/pWKQST98wh.json"
                      ></Player>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ height: "calc(100VH - 81px)", overflowY: "scroll" }}>
                    <VideoListPlayer
                      videos={videos}
                      interactions={interactions}
                      showInteractionBar={false}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    );
  } else {
    return (
      <Box component="main" sx={{ flexGrow: 1 }}>
        <SiteLoadingPage />
      </Box>
    )
  }
};

export default JobDetailsContainer;
