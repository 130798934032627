import { MouseEvent, useState } from "react";

import Careers from "./Careers";
import Videos from "./Videos";
import Programs from "./Programs";
import NavigationToggle from "./NavigationToggle";
import { StudentRecord } from "../../../shared/types/types";
import { useMyListContext } from "../../contexts/myListContext";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { Grid } from "@mui/material";
import MyListSidebar from "./MyListSidebar";

type Props = {
  loggedInStudent: StudentRecord;
};

const MyListContainer = ({ loggedInStudent }: Props) => {
  const [selected, setSelected] = useState<"videos" | "careers" | "programs">("videos");
  const { width } = useWindowDimensions();

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === selected || value === null) return;
    setSelected(value as "videos" | "careers" | "programs");
  };
  const { loading } = useMyListContext();
  return (
    <>
      {!loading && (
        <>
          {width < 900 ? (
            <>
              <NavigationToggle selected={selected} handleSelected={handleSelected} />
              {selected === "videos" && <Videos loggedInStudent={loggedInStudent} />}
              {selected === "careers" && <Careers />}
              {selected === "programs" && <Programs />}
            </>
          ) : (
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs={12} sm={3} md={3}>
                <MyListSidebar selectedMenuItem={selected} setSelectedMenuItem={setSelected} />
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                {selected === "videos" && <Videos loggedInStudent={loggedInStudent} />}
                {selected === "careers" && <Careers />}
                {selected === "programs" && <Programs />}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default MyListContainer;
