import { QuestionRecord } from "../../shared/types/types";

export const generateQuizQuestion = ({ id, name }: { id: string; name: string }) => {
  switch (id) {
    case "myWhy":
      return {
        questionText: `${name}, why is making a great next step after you graduate important to you?`,
        helperText: "(e.g. find a great career, love of learning, make your family proud, etc.)",
        id: "myWhy",
      } as QuestionRecord;
    case "whatDescribesMe":
      return {
        questionText:
          "Now, which of the following best describes how you are thinking about what comes after high school?",
        helperText: "",
        id: "whatDescribesMe",
        options: [
          {
            optionText:
              "I’m most interested in the classic “college experience,” a place with great prestige and reputation that gives me the opportunity to meet new people or reinvent myself.",
          },
          {
            optionText:
              "Honestly, I’m not all that excited about my options, but I want to do what my family, teachers, or friends think I should since they know what's best for me.",
          },
          {
            optionText:
              "I’m not sure where I need to be, but I know my current environment isn’t doing it for me. Getting away will help point me in the right direction.",
          },
          {
            optionText:
              "I know where I want to end up, I just need some structured guidance to get the skills and certifications to get started.",
          },
          {
            optionText:
              "I’m ready to learn more and challenge myself. I have some idea of the impact I want to have. For now, I want to keep learning and growing towards that vision.",
          },
        ],
      } as QuestionRecord;
    default:
      return {
        questionText: ` Thanks for sharing that ${name}.`,
        helperText: "",
        id: "whatDescribesMeFeedback",
      } as QuestionRecord;
  }
};
