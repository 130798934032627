import { useRecoilValue } from "recoil";
import { answersAtom } from "../../recoil/quizAtoms";
import { OptionAlignment } from "../../../shared/types/enums";

const determineCount = (
  alignment: string,
  count: number
): { alignment: OptionAlignment; count: number } => {
  if (alignment === "Introversion, low Extraversion") {
    return { alignment: OptionAlignment.EXTRAVERSION, count: -1 * count };
  }
  if (alignment === "Neuroticism") {
    return { alignment: OptionAlignment.EMOTIONAL_STABILITY, count: -1 * count };
  }
  if (alignment === "Low Agreeableness") {
    return { alignment: OptionAlignment.AGREEABLENESS, count: -1 * count };
  } else {
    return { alignment: alignment as OptionAlignment, count: count };
  }
};

const useDetermineQuizResults = () => {
  const answers = useRecoilValue(answersAtom);
  const determineQuizResults = () => {
    const alignmentCounts: { [key in OptionAlignment]: number } = {
      [OptionAlignment.INVESTIGATIVE]: 0,
      [OptionAlignment.ARTISTIC]: 0,
      [OptionAlignment.SOCIAL]: 0,
      [OptionAlignment.ENTERPRISING]: 0,
      [OptionAlignment.CONVENTIONAL]: 0,
      [OptionAlignment.REALISTIC]: 0,
      [OptionAlignment.OPENNESS]: 0,
      [OptionAlignment.CONSCIENTIOUSNESS]: 0,
      [OptionAlignment.EXTRAVERSION]: 0,
      [OptionAlignment.AGREEABLENESS]: 0,
      [OptionAlignment.EMOTIONAL_STABILITY]: 0,
    };

    for (const answer of answers) {
      for (const choice of answer.answerChoices) {
        if (choice.optionAlignment) {
          const count = choice.choice === 1 ? 3 : 1;
          const results = determineCount(choice.optionAlignment, count);
          alignmentCounts[results.alignment] += results.count;
        }
      }
    }

    const hollandAlignments = [
      OptionAlignment.INVESTIGATIVE,
      OptionAlignment.ARTISTIC,
      OptionAlignment.SOCIAL,
      OptionAlignment.ENTERPRISING,
      OptionAlignment.CONVENTIONAL,
      OptionAlignment.REALISTIC,
    ];

    const raciesAlignments = [
      OptionAlignment.OPENNESS,
      OptionAlignment.CONSCIENTIOUSNESS,
      OptionAlignment.EXTRAVERSION,
      OptionAlignment.AGREEABLENESS,
      OptionAlignment.EMOTIONAL_STABILITY,
    ];

    const combinedScores: { [key: string]: number } = {};

    for (const holland of hollandAlignments) {
      for (const racies of raciesAlignments) {
        const key = `${holland}_${racies}`;
        combinedScores[key] = alignmentCounts[holland] * alignmentCounts[racies];
      }
    }

    let maxCombinedAlignment = "";
    let maxCombinedScore = 0;

    for (const [alignment, score] of Object.entries(combinedScores)) {
      if (score > maxCombinedScore) {
        maxCombinedScore = score;
        maxCombinedAlignment = alignment;
      }
    }
    return maxCombinedAlignment;
  };
  return { determineQuizResults };
};

export default useDetermineQuizResults;
