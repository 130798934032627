import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import StudentOverviewContainer from "../studentOverview/StudentOverviewContainer";
import { Color } from "../../../shared/types/enums";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StudentDashboardDialog = ({ open, setOpen }: Props) => {
  const { width } = useWindowDimensions();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      PaperProps={{ className: width < 900 ? "mobileDialog" : "" }}
    >
      <DialogContent
        sx={{
          backgroundColor: Color.LIGHT_BACKGROUND_COLOR,
          padding: width < 900 ? 1 : 2,
          margin: 0,
        }}
      >
        <StudentOverviewContainer />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="close" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentDashboardDialog;
