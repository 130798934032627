import React from "react";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import ExploreScholarshipsContainer from "../components/exploreScholarships/ExploreScholarshipsContainer";

const StudentExploreScholarshipsPage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>{loggedInStudent && <ExploreScholarshipsContainer loggedInStudent={loggedInStudent} />}</>
  );
};

export default StudentExploreScholarshipsPage;
