import { atom, selector } from "recoil";
import { AlmaChatRecord } from "../types/types";

export const studentAlmaChatsAtom = atom<AlmaChatRecord[]>({
  key: "studentAlmaChatsAtom",
  default: [],
});

export const currentStudentAlmaChatAtom = selector<AlmaChatRecord | null>({
  key: "currentStudentAlmaChatAtom",
  get: ({ get }) => {
    const studentAlmaChats = get(studentAlmaChatsAtom);
    console.log("studentAlmaChats from currentStudentAlmaChatAtom", studentAlmaChats);
    return studentAlmaChats.find((chat) => chat.current === true) || null;
  },
});
