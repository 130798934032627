import * as Yup from "yup";
import { EFCDataRecord } from "../../../shared/types/types";
import { getIncomeProtectionAllowance, getStateTaxAllowance } from "../../../shared/utils/efcUtils";

type Props = {
  form: EFCDataRecord;
  handleSave: (formData: EFCDataRecord, efc: number) => void;
};

const assetConversionRate: number = 0.12;
const studentIncomeProtectionAllowance: number = 6840;
const studentAssetConversionRate: number = 0.2;

export const useEFCForm = ({ form, handleSave }: Props) => {
  const initialValues: EFCDataRecord = form;

  const validationSchema = Yup.object({
    state: Yup.string().required("State is required"),
    householdSize: Yup.number().min(1, "Household size must be greater than 0").required("Household size is required"),
    childrenUnder18: Yup.number().required("Children Under 18 is required"),
    childrenInCollege: Yup.number().min(1, "Children in College must be at least 1").required("Children in College is required"),
    parentIncome: Yup.number().required("Parent Income is required"),
    parentUntaxedIncome: Yup.number().required("Parent Untaxed Income is required"),
    parentAssets: Yup.number().required("Parent Assets are required"),
    studentIncome: Yup.number().required("Student Income is required"),
    studentAssets: Yup.number().required("Student Assets are required"),
    ageOfOldestParent: Yup.number().min(1, "Age of oldest parent must be greater than 0").required("Age of oldest parent is required"),
  });

  const handleSubmit = (values: EFCDataRecord, { setSubmitting }: any) => {
    const tempEFC = calculateEFC(values);
    if (tempEFC == null) {
      setSubmitting(false);
    } else {
      handleSave(values, tempEFC);
      setSubmitting(false);
    }
  };

  function calculateEFC({
    state,
    parentIncome,
    householdSize,
    childrenInCollege,
    parentUntaxedIncome,
    ageOfOldestParent,
    maritalStatus,
    parentAssets,
    studentAssets,
    studentIncome,
  }: EFCDataRecord): number | null {
    if (
      parentIncome === undefined ||
      !householdSize ||
      householdSize < 1 ||
      childrenInCollege === undefined ||
      parentUntaxedIncome === undefined ||
      ageOfOldestParent === undefined ||
      ageOfOldestParent < 1 ||
      maritalStatus === undefined ||
      parentAssets === undefined ||
      studentAssets === undefined ||
      studentIncome === undefined
    )
      return null;
    // Calculate parent contribution from income
    const parentTotalIncome = parentIncome + parentUntaxedIncome;
    const incomeProtectionAllowance = getIncomeProtectionAllowance({
      householdSize: householdSize,
      childrenInCollege: childrenInCollege,
    });

    const parentSocialSecurityTax = 0.0765 * parentIncome;
    const employmentExpenseAllowance = Math.min(
      0.35 * Math.min(parentIncome, parentUntaxedIncome),
      4000
    );
    const parentTotalAllowance =
      getStateTaxAllowance({ state: state, income: parentTotalIncome }) +
      incomeProtectionAllowance +
      parentSocialSecurityTax +
      employmentExpenseAllowance;

    const parentAvailableIncome = Math.max(0, parentTotalIncome - parentTotalAllowance);

    // Calculate parent contribution from assets
    const assetProtectionAllowance =
      ageOfOldestParent >= 45
        ? 9400
        : ageOfOldestParent < 45 && maritalStatus === "married"
        ? 6500
        : 3000;
    const parentDiscretionaryNetWorth = Math.max(0, parentAssets - assetProtectionAllowance);
    const parentContributionFromAssets = parentDiscretionaryNetWorth * assetConversionRate;

    // Parent adjusted available income
    const parentAAI = parentAvailableIncome + parentContributionFromAssets;

    // Calculate Parent Contribution using AAI
    let parentContribution;
    if (parentAAI < 0) {
      parentContribution = 0;
    } else if (parentAAI <= 17000) {
      parentContribution = 0.22 * parentAAI;
    } else if (parentAAI <= 21400) {
      parentContribution = 3740 + 0.25 * (parentAAI - 17000);
    } else if (parentAAI <= 25700) {
      parentContribution = 4840 + 0.29 * (parentAAI - 21400);
    } else if (parentAAI <= 30100) {
      parentContribution = 6087 + 0.34 * (parentAAI - 25700);
    } else if (parentAAI <= 34500) {
      parentContribution = 7583 + 0.4 * (parentAAI - 30100);
    } else {
      parentContribution = 9343 + 0.47 * (parentAAI - 34500);
    }

    parentContribution = parentContribution / childrenInCollege;

    // Calculate student contribution from income
    const studentTotalIncome = studentIncome;
    const studentTotalAllowance = studentIncomeProtectionAllowance;
    const studentAvailableIncome = Math.max(0, studentTotalIncome - studentTotalAllowance);
    const studentContributionFromIncome = studentAvailableIncome * 0.5;

    // Calculate student contribution from assets
    const studentDiscretionaryNetWorth = Math.max(0, studentAssets);
    const studentContributionFromAssets = studentDiscretionaryNetWorth * studentAssetConversionRate;

    // Student total contribution
    const studentContribution = studentContributionFromIncome + studentContributionFromAssets;

    // Total EFC
    const efc = parentContribution + studentContribution;

    return efc;
  }

  return { initialValues, validationSchema, handleSubmit };
};
