import { useEffect, useState } from "react";
import { Industry } from "../../types/types";
import { Box, Typography } from "@mui/material";
import { Color } from "../../types/enums";
import { getCareerImage } from "../../utils/imageUtils";
import { useSetRecoilState } from "recoil";
import { selectedIndustryIdAtom } from "../../recoil/exploreCareersAtoms";
import { LogEventType } from "../../types/logEnums";
import useLogger from "../../hooks/logging/useLogger";

type Props = {
  industry: Industry;
};

const IndustryCard = ({ industry }: Props) => {
  const setSelectedIndustryId = useSetRecoilState(selectedIndustryIdAtom);
  const [industryImage, setIndustryImage] = useState<string | undefined>(undefined);
  const { submitLog } = useLogger();
  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageModule = await getCareerImage({ id: industry.id });
        setIndustryImage(imageModule.default);
      } catch (error) {
        submitLog({
          error,
          snackbarMessage: "Failed to load industry image",
          eventType: LogEventType.GET_IMAGE_BY_INDUSTRY_ID_ERROR,
          file: "IndustryCard.tsx",
        });
      }
    };

    loadImage();
  }, [submitLog, industry.id, industry.title]);

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.WHITE,
        borderRadius: 4,
        boxShadow: "0px 2px 8px -1px #10182814",
        cursor: "pointer",
      }}
      onClick={() => setSelectedIndustryId(industry.id)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.5,
          px: 2.5,
          py: 1.25,
          borderBottom: "1px solid",
          borderColor: Color.GRAY_200,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 0.5,
          }}
        >
          <img
            src={industryImage}
            alt="industry"
            style={{ width: 64, height: 64, borderRadius: 8 }}
          />
        </Box>
        <Box sx={{ p: 1, width: "100%" }}>
          <Typography
            textAlign={"right"}
            variant="h6"
            sx={{ lineHeight: 1.2, color: Color.CHALKBOARD }}
          >
            {industry.title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: 2.5, py: 1.25 }}>
        <Typography>{industry.description}</Typography>
      </Box>
    </Box>
  );
};

export default IndustryCard;
