import { collection, doc, getDoc } from "firebase/firestore";
import { Collection } from "../../../shared/types/enums";
import { db } from "../../../firebase";
import { WageDataRecord } from "../../../shared/types/types";
import { parseSingeWageDataResponse } from "../../../shared/utils/parserUtils";
import { useRecoilValue } from "recoil";
import { msaCodeAtom } from "../../recoil/msaCodeAtoms";
import { useCallback } from "react";
import { LogEventType } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";

type GetWageDataProps = {
  city: string;
  state: string;
  onetCode: string;
};

const useGetWageData = () => {
  const msaCode = useRecoilValue(msaCodeAtom);
  const { submitLog } = useLogger();

  const getWageData = useCallback(
    async ({
      city,
      state,
      onetCode,
    }: GetWageDataProps): Promise<{ wageData: WageDataRecord | null; label: string }> => {
      const shortOnet = onetCode.slice(0, 7);
      const colRef = collection(db, Collection.FULL_WAGE_DATA);
      const nationalRef = doc(colRef, `${shortOnet}_US`);
      const stateRef = doc(colRef, `${shortOnet}_${state}`);
      const msaRef = doc(colRef, `${shortOnet}_${state}_${msaCode?.MSA}`);
      try {
        if (msaCode?.MSA) {
          const msaSnapshot = await getDoc(msaRef);
          if (msaSnapshot.exists()) {
            const wageDataToReturn = parseSingeWageDataResponse(
              msaSnapshot.data() as WageDataRecord
            );
            return { wageData: wageDataToReturn, label: `For ${city}` };
          }
        }
        if (state) {
          const stateSnapshot = await getDoc(stateRef);
          if (stateSnapshot.exists()) {
            const wageDataToReturn = parseSingeWageDataResponse(
              stateSnapshot.data() as WageDataRecord
            );
            return { wageData: wageDataToReturn, label: `Statewide: ${state}` };
          }
        }
        const nationalSnapshot = await getDoc(nationalRef);
        const wageDataToReturn = parseSingeWageDataResponse(
          nationalSnapshot.data() as WageDataRecord
        );
        return { wageData: wageDataToReturn, label: "National" };
      } catch (error) {
        console.error("Error getting wage data", error);
        submitLog({
          error,
          snackbarMessage: "Error getting wage data",
          eventType: LogEventType.GOT_WAGE_DATA_ERROR,
          file: "useGetWageData.ts",
        });
      }
      return { wageData: null, label: "" };
    },
    [submitLog, msaCode?.MSA]
  );
  return { getWageData };
};

export default useGetWageData;
