import { useState } from "react";
import StudentProfileSetupNotComplete from "./StudentProfileSetupNotComplete";
import StudentProfileSetupComplete from "./StudentProfileSetupComplete";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  loggedInStudent: StudentRecord;
};

const StudentProfileContainer = ({ loggedInStudent }: Props) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      {loggedInStudent && (
        <>
          {!loggedInStudent.setupComplete ? (
            <StudentProfileSetupNotComplete
              loggedInStudent={loggedInStudent}
              navOpen={navOpen}
            />
          ) : (
            <StudentProfileSetupComplete
              loggedInStudent={loggedInStudent}
              navOpen={navOpen}
              setNavOpen={setNavOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default StudentProfileContainer;
