import { useRecoilValue } from "recoil";
import { recommendedProgramsAtom } from "../../recoil/recommendedProgramAtoms";
import ProgramCard from "../programCard/ProgramCard";
import { Box, Typography } from "@mui/material";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import { ProviderProgramRecord } from "../../../shared/types/types";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import noProgramsImage from "../../../shared/assets/profileImages/sections/college-majors-you-might-enjoy.svg";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import InfoBox from "../../../shared/components/infoBox/InfoBox";

const RecommendedProgramsContainer = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [noProgramsMessage, setNoProgramsMessage] = useState("");
  const recommendedPrograms = useRecoilValue(recommendedProgramsAtom);
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecord | null>(
    null
  );
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    if (!loggedInStudent) return;
    let favoriteJobMessage = false;
    if (loggedInStudent.favoriteJobIds.length < 3) {
      setNoProgramsMessage(
        "You don't have many careers bookmarked. Please bookmark more careers to get better recommendations. \n"
      );
      favoriteJobMessage = true;
    }
    if (
      loggedInStudent.citiesForRecommendations.length > 0 &&
      loggedInStudent.citiesForRecommendations.length < 3
    ) {
      setNoProgramsMessage(
        (prev) =>
          prev +
          `${
            favoriteJobMessage ? "Also, y" : "Y"
          }ou don't have many cities selected. Broader your geographic preferences to get better recommendations. \n`
      );
    }
  }, [loggedInStudent]);

  return (
    <>
      {loggedInStudent && (
        <>
          <Box
            sx={{
              height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 110px)",
              overflowY: "scroll",
            }}
          >
            <Box sx={{ mt: 2, mx: 1 }}>
              {recommendedPrograms.length > 0 && loggedInStudent.favoriteJobIds.length === 0 && (
                <InfoBox
                  message={`We noticed you haven't bookmarked any careers on the "Explore Careers" page. Bookmarking careers helps us provide you with better program recommendations. Click the bookmark icon on a career to bookmark it.`}
                />
              )}
              {recommendedPrograms.length > 0 &&
                loggedInStudent.favoriteJobIds.length > 0 &&
                loggedInStudent.favoriteJobIds.length < 4 && (
                  <InfoBox
                    message={`We noticed you've only bookmarked ${loggedInStudent.favoriteJobIds.length} careers. Bookmarking careers helps us provide you with better program recommendations. Click the bookmark icon on a career to bookmark it.`}
                  />
                )}
            </Box>
            {recommendedPrograms.length === 0 ? (
              <>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {
                    <img
                      src={noProgramsImage}
                      alt="No Programs"
                      width="40%"
                      style={{ minWidth: 300 }}
                    />
                  }
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
                    No Program Matches Are Available
                  </Typography>
                  <Typography textAlign={"center"} sx={{ mt: 2 }}>
                    {noProgramsMessage}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 1 }}>
                {recommendedPrograms.map((program) => (
                  <ProgramCard
                    key={program.id}
                    program={program}
                    setSelectedLearnMoreProgram={setSelectedProgramDetail}
                  />
                ))}
              </Box>
            )}
          </Box>
          {selectedProgramDetail && (
            <ProgramDetailsDialog
              selectedProgram={selectedProgramDetail}
              setSelectedProgram={setSelectedProgramDetail}
            />
          )}
        </>
      )}
    </>
  );
};

export default RecommendedProgramsContainer;
