import { collection, getDocs, query, where, DocumentData } from "firebase/firestore";
import { db } from "../../firebase";

interface GetCipCodesProps {
  onetCodes: string[];
}

interface JobDocument extends DocumentData {
  cip_codes?: string[];
}

export const getCipCodesForOnetCodes = async ({
  onetCodes,
}: GetCipCodesProps): Promise<string[]> => {
  try {
    const jobsRef = collection(db, "jobs");
    const queries = onetCodes.map((onetCode) => query(jobsRef, where("__name__", "==", onetCode)));

    const snapshots = await Promise.all(queries.map((q) => getDocs(q)));

    const allCipCodes: string[] = snapshots.flatMap((snapshot) => {
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        const data = doc.data() as JobDocument;
        return data.cip_codes || [];
      }
      return [];
    });

    return Array.from(new Set(allCipCodes));
  } catch (error) {
    console.error("Error fetching CIP codes:", error);
    throw error;
  }
};

export const reformatCIPCodes = (arr: string[]): string[] => {
  return arr.map((str) => {
    // Split the string at the decimal point
    const [beforeDecimal, afterDecimal] = str.split(".");

    // Remove leading zero if present
    const beforePart = beforeDecimal.replace(/^0/, "");

    // Take the first two digits after the decimal point
    const afterPart = afterDecimal.slice(0, 2);

    // Combine the parts
    return beforePart + afterPart;
  });
};
