export const getFunctionURL = (functionName: string) => {
  const env = process.env.REACT_APP_ENV;
  if (env === "development") {
    return `http://127.0.0.1:5001/willow-dev-f6a2e/us-central1/${functionName}`;
  } else if (env === "qa") {
    return `https://${functionName}-rwz32tyhga-uc.a.run.app`;
  } else {
    return `https://${functionName}-shungiyeaa-uc.a.run.app`;
  }
};
