import { StepType } from "@reactour/tour";
import { atom } from "recoil";
import { TourName } from "../types/tourEnums";

export const stepsAtom = atom<StepType[]>({
  key: "stepsAtom",
  default: [],
});

export const tourNameAtom = atom<TourName | null>({
  key: "tourNameAtom",
  default: null,
});