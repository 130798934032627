import ExploreCareersRouter from "../../../shared/components/exploreCareers/ExploreCareersRouter";
import JobDetailsDialog from "../../../shared/components/exploreCareers/JobDetailsDialog";
import RecommendJobDialog from "./RecommendJobDialog";

const ExploreCareersContainer = () => {
  return (
    <>
      <ExploreCareersRouter />
      <RecommendJobDialog />
      <JobDetailsDialog />
    </>
  );
};

export default ExploreCareersContainer;
