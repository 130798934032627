import { atom } from "recoil";
import { ProviderProgramRecord } from "../types/types";

export const programsAtom = atom<ProviderProgramRecord[]>({
  key: "programsAtom",
  default: [],
});

export const allMatchedProgramsAtom = atom<ProviderProgramRecord[]>({
  key: "allMatchedProgramsAtom",
  default: [],
});