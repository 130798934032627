import { useCallback, useEffect, useRef, useState } from "react";
import { StudentRecord } from "../../../shared/types/types";
import useVideoQuery from "./useVideoQuery";
import { useRecoilValue } from "recoil";
import { videoInteractionsAtom } from "../../recoil/videoInteractionAtoms";
import useLoadInitialFilterState from "./useLoadInitialFilterState";
import { createQuery } from "../../utils/videoUtils";

type Props = {
  loggedInStudent: StudentRecord;
};

const useFeedContainer = ({ loggedInStudent }: Props) => {

  const [hasMoreVideos, setHasMoreVideos] = useState(true);
  const interactions = useRecoilValue(videoInteractionsAtom);
  const listRef = useRef(null);
  const [selectedIndustryIds, setSelectedIndustryIds] = useState<number[]>([]);
  const { isLoading: initialStateLoading } = useLoadInitialFilterState({
    loggedInStudent,
    setSelectedIndustryIds,
  });

  // Custom hook to handle video queries
  const { handleQuery, videos, lastVideo, setVideos, setLastVideo, loading } = useVideoQuery({
    loggedInStudent,
    setHasMoreVideos,
    selectedIndustryIds,
  });

  useEffect(() => {
    if (initialStateLoading) return;
    setLastVideo(null);
    setVideos([]);
    setHasMoreVideos(true);
    const q = createQuery(selectedIndustryIds);
    handleQuery({ q, firstLoad: true, accumulatedVideos: [] });
  }, [initialStateLoading, selectedIndustryIds, setLastVideo, setVideos, handleQuery]);

  const handleGetMoreVideos = useCallback(() => {
    if (!lastVideo || !hasMoreVideos) return;
    const q = createQuery(selectedIndustryIds, lastVideo);
    handleQuery({ q, firstLoad: false, accumulatedVideos: videos });
  }, [lastVideo, hasMoreVideos, selectedIndustryIds, handleQuery, videos]);

  const handleScroll = useCallback(() => {
    if (!hasMoreVideos || !listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      handleGetMoreVideos();
    }
  }, [hasMoreVideos, handleGetMoreVideos]);

  useEffect(() => {
    const listElement = listRef.current as HTMLElement | null;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return {
    videos,
    interactions,
    listRef,
    selectedIndustryIds,
    setSelectedIndustryIds,
    loading,
  };
};

export default useFeedContainer;
