import { FilterProps } from "../types/types";

export const initialFiltersValues: FilterProps = {
  sizes: ["small", "medium", "large"],
  states: [],
  cities: [],
  cost: [0, 999999999],
  hbcuStatus: false,
  menOnly: false,
  womenOnly: false,
  programTypes: [1, 2, 3],
  industries: [],
  tags: [],
  cipCodes: [],
  religiousAffiliation: false,
  locale: [],
  admissionRate: [0, 100],
  completionRate: [0, 100],
  onlineOnly: false,
  providerId: "",
  showNegativeROI: false,
  collegeOnly: false,
  nonCollegeOnly: false,
};
