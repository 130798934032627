import { Alert, Box, Button, TextField } from "@mui/material";
import useCreateAuthAccount from "../../hooks/signup/useCreateAuthAccount";

type Props = {
  email: string;
};

const CreateAuthAccount = ({ email }: Props) => {
  const {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    userExistsError,
    touched,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleCreateAuthUser,
  } = useCreateAuthAccount({ email });

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <TextField
          name="password"
          autoComplete="newpassword"
          required
          id="password"
          type="password"
          value={password}
          label="Create Password"
          fullWidth
          variant="outlined"
          onChange={handlePasswordChange}
        />
        {passwordError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {passwordError}
          </Alert>
        )}
        <TextField
          name="confirmPassword"
          autoComplete="newpassword"
          required
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          label="Confirm Password"
          fullWidth
          variant="outlined"
          onChange={handleConfirmPasswordChange}
          sx={{ mt: 2 }}
        />
        {confirmPasswordError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {confirmPasswordError}
          </Alert>
        )}

        <Button
          variant="contained"
          onClick={handleCreateAuthUser}
          sx={{ mt: 2 }}
          fullWidth
          disabled={
            Boolean(passwordError) ||
            Boolean(confirmPasswordError) ||
            !touched.password ||
            !touched.confirmPassword
          }
        >
          Next
        </Button>
        {userExistsError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userExistsError}
          </Alert>
        )}
      </Box>
    </>
  );
};

export default CreateAuthAccount;
