import { createContext, useContext } from "react";

type Props = {
  loading: boolean;
};

export const QuizContext = createContext<Props>({ loading: true });

export const useQuizDataContext = () => {
  return useContext(QuizContext);
};
