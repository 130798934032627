import { Box, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../../shared/types/enums";
import { PersonalityTypeRecord } from "../../../shared/types/types";
import NavigationButtons from "./NavigationButtons";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const ProfessionalProgramsToConsider = ({
  mobile,
  personalityType,
  onActionButtonClick,
}: Props) => {
  return (
    <Box>
      <Typography
        sx={{ mt: 2, fontWeight: 600 }}
        variant="h6"
        fontSize={16}
        color={Color.ORANGE_700}
      >
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        Professional Programs to Consider
      </Typography>
      <Box>
        {personalityType.possibleProfessionalPrograms.map((program, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              py: 4,
              gap: 1,
              borderBottom:
                index !== personalityType.possibleProfessionalPrograms.length - 1 ? 0.5 : "none",
              borderColor: Color.BLACK,
            }}
          >
            <Box>
              <Typography variant="h4" fontSize={20} color={Color.CHALKBOARD}>
                {program.title}
              </Typography>
              <Typography variant="body1" fontSize={14} color={Color.CHALKBOARD} sx={{ mt: 1 }}>
                {program.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.PROFESSIONAL_PROGRAMS_TO_CONSIDER}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default ProfessionalProgramsToConsider;
