import { Container } from "@mui/material";
import CreateProfileContainer from "../components/createProfile/CreateProfileContainer";

const StudentCreateProfilePage = () => {
  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 8 }}>
        <CreateProfileContainer />
      </Container>
    </>
  );
};

export default StudentCreateProfilePage;
