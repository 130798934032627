import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { AlmaChatRecord, ChatGPTEntry } from "../../shared/types/types";
import { Collection } from "../../shared/types/enums";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import { StudentAlmaDataContext } from "../contexts/studentAlmaDataContext";
import { useAuth } from "../../shared/contexts/AuthContext";
import { studentAlmaChatsAtom } from "../../shared/recoil/almaAtoms";

type Props = {
  children: JSX.Element;
};

export const StudentAlmaProvider = ({ children }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { getFSDocs } = useGetFSDocs();
  const currentAuthUser = useAuth();
  const [studentAlmaChats, setStudentAlmaChats] = useRecoilState(studentAlmaChatsAtom);
  const [loading, setLoading] = useState(true);

  const getDataPromises = useCallback(() => {
    if (!loggedInStudent) return [];
    const dataLoaderPromises: [Promise<AlmaChatRecord[]>] = [
      getFSDocs<AlmaChatRecord>({
        col: Collection.ALMA_CHATS,
        config: { where: ["studentId", "==", loggedInStudent.id] },
      }),
    ];
    return dataLoaderPromises;
  }, [getFSDocs, loggedInStudent]);

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      //keep from recalling the db if chats have already been retrieved
      if (studentAlmaChats.length !== 0) {
        setLoading(false);
        return;
      }
      const dataLoaderPromises = getDataPromises();
      const [chats] = await Promise.all(dataLoaderPromises);
      setLoading(false);
      if (!chats || chats.length === 0) return;
      const currentChat = chats.find((chat) => chat.current);
      console.log("The current chat from the provider is", currentChat);
      if (!currentChat) return;
      if (new Date(currentChat.createdAt) < new Date("2024-10-22T00:00:00.000Z")) {
        const newCurrentChatHistory: ChatGPTEntry[] = [];
        for (let i = 0; i < currentChat.chatHistory.length; i++) {
          if (i === 0) continue;
          if (!currentChat.chatHistory[i].content) {
            const currentEntry = currentChat.chatHistory[i] as any;
            newCurrentChatHistory.push({
              role:
                currentEntry.role === "user"
                  ? "user"
                  : ("assistant" as "user" | "assistant" | "system"),
              content: currentEntry.parts[0].text as string,
              timestamp: currentEntry.timestamp as string,
            } as ChatGPTEntry);
          } else {
            newCurrentChatHistory.push(currentChat.chatHistory[i] as ChatGPTEntry);
          }
        }
        const updatedCurrentChat: AlmaChatRecord = {
          ...currentChat,
          chatHistory: newCurrentChatHistory,
        };
        const updatedChats: AlmaChatRecord[] = chats.map((chat) =>
          chat.id === currentChat.id ? updatedCurrentChat : chat
        );
        setStudentAlmaChats(updatedChats);
      } else {
        setStudentAlmaChats(chats);
      }
    };
    loadData();
  }, [getDataPromises, getFSDocs, currentAuthUser, setStudentAlmaChats, studentAlmaChats.length]);

  return (
    <StudentAlmaDataContext.Provider value={{ loading }}>
      {children}
    </StudentAlmaDataContext.Provider>
  );
};
