import { useCallback, useState } from "react";
import { CareerVideoRecord } from "../../../shared/types/types";
import useIntersectionObserver from "../../../shared/hooks/videoListPlayer/useIntersectionObserver";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { Box } from "@mui/material";
import VideoPlayer from "../../../shared/components/video/VideoPlayer";

type Props = {
  videos: CareerVideoRecord[];
};

const VideoListPlayer = ({ videos }: Props) => {
  const { width } = useWindowDimensions();
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState<string | null>(null);
  const [globalMute, setGlobalMute] = useState(true);

  const pauseOtherVideos = useCallback((currentSrc: string | null) => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      if (video.src !== currentSrc) {
        video.pause();
      }
    });
  }, []);
  const { observe, unobserve } = useIntersectionObserver(
    setCurrentlyPlayingVideo,
    pauseOtherVideos,
    globalMute
  );

  //TODO: Make this work w/o logged in student for public  viewing / staff viewing

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {videos.map((video, index) => (
          <Box key={video.fileName}>
            {video.url && (
              <VideoPlayer
                mobile={width < 900}
                url={video.url}
                pauseOtherVideos={pauseOtherVideos}
                video={videos[index]}
                globalMute={globalMute}
                setGlobalMute={setGlobalMute}
                interaction={null}
                currentlyPlayingVideo={currentlyPlayingVideo}
                setCurrentlyPlayingVideo={setCurrentlyPlayingVideo}
                observe={observe}
                unobserve={unobserve}
              />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default VideoListPlayer;
