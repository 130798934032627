import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Dispatch } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import roiGraphic from "../../../shared/assets/static/roi.svg";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const WhatIsROIDialog = ({ open, setOpen }: Props) => {
  const typographySX = { fontSize: 16, mb: 0, textAlign: "justify" };
  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        What is Return on Investment (ROI)?
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={roiGraphic} alt="ROI" />
        </Box>
        <Typography sx={typographySX}>
          Return on Investment (ROI) is a metric used to evaluate the financial return you get from
          investing money, time, or other resources into something.
        </Typography>

        <Typography sx={typographySX}>
          While there's a lot more to an education program than just ROI, almost all students expect
          that they will be financially better off after completing a program than worse off!
        </Typography>

        <Typography sx={{ fontSize: 16 }}>We calculate this by looking at:</Typography>

        <List>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Future Earnings:</b> This is the estimated increase in income you'll get by
              completing this program compared to not doing it over the course of 10 years.
            </Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Tuition & Fees:</b> The money you pay for the course itself.
            </Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Avg Time to Completion:</b> The average time it takes to complete the course.
            </Typography>
          </ListItem>
        </List>

        <Typography sx={typographySX}>
          So, the formula takes the future money you'll earn completing this course over ten years,
          and then subtracting the tuition and fees you'll pay over the time it takes to complete
          the course.
        </Typography>

        <Typography sx={typographySX}>
          In simpler terms, it helps you understand if the course is financially worth it. If the
          ROI is positive, you're expected to earn back more than you invested. If it's negative,
          you might want to think twice about taking the program.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatIsROIDialog;
