import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Color } from "../../../shared/types/enums";
import { ProviderProgramRecord, StudentRecord } from "../../../shared/types/types";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import WhatIsROIDialog from "../../../shared/components/whatIsROI/WhatIsROIDialog";
import WhatIsRTSSchool from "../../../shared/components/whatIsRTS/WhatIsRTSDialog";
import useProgramCard from "../../hooks/programCard/useProgramCard";
import ProgramDescription from "./ProgramDescription";
import QualityMeasures from "./QualityMeasures";
import { selectedProgramForRecommendationsAtom } from "../../recoil/programAtoms";
import { useSetRecoilState } from "recoil";
import infoIcon from "../../../shared/assets/icons/info.svg";

type Props = {
  program: ProviderProgramRecord;
  student?: StudentRecord | null;
  setSelectedProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  setRecommendOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
};

const StaffProgramCard = ({ program, student, setSelectedLearnMoreProgram }: Props) => {
  const setSelectedProgramForRecommendations = useSetRecoilState(
    selectedProgramForRecommendationsAtom
  );

  const { renderDuration } = useProgramCard();
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [whatIsRTSSchoolOpen, setWhatIsRTSSchoolOpen] = useState(false);

  const handleLearnMore = () => {
    if (!setSelectedLearnMoreProgram) return;
    setSelectedLearnMoreProgram(program);
  };

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedProgramForRecommendations(program);
    },
    [program, setSelectedProgramForRecommendations]
  );

  type Props = {
    gpaValue: number | null;
    gpaMax: number | null;
    act25th: number | null;
    act75th: number | null;
    openAdmin: boolean;
    admissionPercent: number | null;
    act: string | null;
  };

  const generateAccessLevel = ({
    gpaValue,
    gpaMax,
    act25th,
    act75th,
    openAdmin,
    admissionPercent,
    act,
  }: Props) => {
    // Ensure GPA percentage is within the valid range (0 to 100)
    if (openAdmin) return "Safety";
    const actNumber = act ? parseInt(act) : null;
    const gpaPercent = gpaValue && gpaMax ? gpaValue / gpaMax : null;
    if ((!gpaPercent || gpaPercent < 0 || gpaPercent > 1) && !act) {
      return "Unknown";
    }
    if ((!act25th || !act75th) && !admissionPercent) {
      return "Unknown";
    }

    type ConversionTableRow = {
      gpaPercent: number;
      act: number;
      safetyCutoff: number;
      reachCutoff: number;
    };

    // Define the conversion table
    const conversionTable: ConversionTableRow[] = [
      { gpaPercent: 36 / 36, act: 36, safetyCutoff: 0.5, reachCutoff: 0.1 },
      { gpaPercent: 35 / 36, act: 35, safetyCutoff: 0.5, reachCutoff: 0.15 },
      { gpaPercent: 34 / 36, act: 34, safetyCutoff: 0.55, reachCutoff: 0.15 },
      { gpaPercent: 33 / 36, act: 33, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 32 / 36, act: 32, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 31 / 36, act: 31, safetyCutoff: 0.55, reachCutoff: 0.25 },
      { gpaPercent: 30 / 36, act: 30, safetyCutoff: 0.6, reachCutoff: 0.25 },
      { gpaPercent: 29 / 36, act: 29, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 28 / 36, act: 28, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 27 / 36, act: 27, safetyCutoff: 0.6, reachCutoff: 0.35 },
      { gpaPercent: 26 / 36, act: 26, safetyCutoff: 0.65, reachCutoff: 0.35 },
      { gpaPercent: 25 / 36, act: 25, safetyCutoff: 0.65, reachCutoff: 0.4 },
      { gpaPercent: 24 / 36, act: 24, safetyCutoff: 0.65, reachCutoff: 0.45 },
      { gpaPercent: 23 / 36, act: 23, safetyCutoff: 0.65, reachCutoff: 0.5 },
      { gpaPercent: 22 / 36, act: 22, safetyCutoff: 0.7, reachCutoff: 0.55 },
      { gpaPercent: 21 / 36, act: 21, safetyCutoff: 0.7, reachCutoff: 0.6 },
      { gpaPercent: 20 / 36, act: 20, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 19 / 36, act: 19, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 18 / 36, act: 18, safetyCutoff: 0.8, reachCutoff: 0.7 },
      { gpaPercent: 17 / 36, act: 17, safetyCutoff: 0.8, reachCutoff: 0.75 },
      { gpaPercent: 16 / 36, act: 16, safetyCutoff: 0.85, reachCutoff: 0.8 },
      { gpaPercent: 15 / 36, act: 15, safetyCutoff: 0.85, reachCutoff: 0.85 },
      { gpaPercent: 14 / 36, act: 14, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 13 / 36, act: 13, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 12 / 36, act: 12, safetyCutoff: 0.95, reachCutoff: 0.9 },
      { gpaPercent: 11 / 36, act: 11, safetyCutoff: 0.95, reachCutoff: 0.9 },
    ];

    // Find the closest GPA percentage in the conversion table
    let closestGpaPercent: ConversionTableRow | null = null;
    if (gpaPercent && gpaPercent > 0 && gpaPercent < 1) {
      closestGpaPercent = conversionTable.reduce((prev, curr) =>
        Math.abs(curr.gpaPercent - gpaPercent) < Math.abs(prev.gpaPercent - gpaPercent)
          ? curr
          : prev
      );
    }

    if (actNumber && act25th && act75th) {
      console.log("Using ACT");
      if (actNumber < act25th) {
        return "Reach";
      } else if (actNumber >= act25th && actNumber <= act75th) {
        return "Target";
      } else if (actNumber > act75th) {
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if (closestGpaPercent && act25th && act75th) {
      console.log("Using GPA");
      console.log(closestGpaPercent.act, act25th, act75th);
      if (closestGpaPercent.act < act25th) {
        console.log("returning reach");
        return "Reach";
      } else if (closestGpaPercent.act >= act25th && closestGpaPercent.act <= act75th) {
        console.log("returning target");
        return "Target";
      } else if (closestGpaPercent.act > act75th) {
        console.log("returning safety");
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if ((!act25th || !act75th) && admissionPercent && closestGpaPercent) {
      console.log("Using Admission Percent with GPA");
      if (admissionPercent > closestGpaPercent.safetyCutoff) {
        return "Safety";
      } else if (
        admissionPercent <= closestGpaPercent.safetyCutoff &&
        admissionPercent >= closestGpaPercent.reachCutoff
      ) {
        return "Target";
      } else if (admissionPercent < closestGpaPercent.reachCutoff) {
        return "Reach";
      } else {
        return "Unknown";
      }
    }

    return "Unknown";
  };

  const formatCredentialType = (credLevel: number | null) => {
    if (credLevel === 1) {
      return "Certificate / Training";
    } else if (credLevel === 2) {
      return "Associate's Degree";
    } else if (credLevel === 3) {
      return "Bachelor's Degree";
    } else {
      return "Certificate / Training";
    }
  };

  const calculateStartingSalary = (program: ProviderProgramRecord) => {
    if (program && program.program1yrEarnings) {
      return formatCurrency({ amount: program.program1yrEarnings });
    } else if (program && program.provider1yrEarnings) {
      return formatCurrency({ amount: program.provider1yrEarnings });
    } else {
      return "Unknown";
    }
  };

  return (
    <>
      {program && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Custom boarder & messsage around programs that have auto-admit turned on */}
          {/* <AutoAdmit program={program}> */}
          <Paper
            sx={{
              backgroundColor: Color.WHITE,
              color: Color.CHALKBOARD,
              pt: 2,
              maxWidth: 400,
              borderRadius: 6,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Chip
                label={formatCredentialType(program.credLevel)}
                sx={{ fontSize: 11, ml: 1, backgroundColor: Color.SURFACE_GREEN }}
              />
            </Box>
            <Box sx={{ padding: 0, ml: 2, mr: 2 }}>
              <Typography
                component="h6"
                variant="cardProviderName"
              >{`${program.providerName}`}</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FeatherIcon icon="location" width="16px" />
                <Typography
                  component="div"
                  variant="cardLocation"
                >{`${program.city}, ${program.state}`}</Typography>
              </Box>
              <Typography variant="h4">{program.programName}</Typography>
              {program.programShortDescription && (
                <Typography variant="body1" sx={{ color: Color.CHALKBOARD, mt: 1 }}>
                  <ProgramDescription description={program.programShortDescription} />
                </Typography>
              )}
            </Box>
            <Box sx={{ pl: 2, pr: 2, pb: 1, ml: 2, mr: 2, pt: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {program.totalProgramCost === 0
                      ? "Free"
                      : program.totalProgramCost !== null
                      ? formatCurrency({ amount: program.totalProgramCost })
                      : "Unknown"}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Total Estimated Cost
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {calculateStartingSalary(program)}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Average starting salary
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {renderDuration({
                      months: program?.durationMonths ?? 0,
                      years: program?.durationYears ?? 0,
                    })}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: "-2px" }}>
                    Length of program
                  </Typography>
                </Grid>
                {student && (
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {generateAccessLevel({
                        gpaValue: student.gpaValue,
                        gpaMax: student.gpaMax,
                        act25th: program.act25th,
                        act75th: program.act75th,
                        openAdmin: program.openAdmin,
                        admissionPercent: program.admissionPercent,
                        act: student.act,
                      })}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1">Fit</Typography>
                      <img
                        src={infoIcon}
                        alt="info"
                        width="12px"
                        height="12px"
                        style={{ cursor: "pointer", marginLeft: "4px" }}
                        onClick={() => setWhatIsRTSSchoolOpen(true)}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <QualityMeasures program={program} setWhatIsROIOpen={setWhatIsROIOpen} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                py: 2,
              }}
            >
              <Button sx={{ px: 1, py: 0.5 }} variant="outlined" onClick={handleLearnMore}>
                Learn More
              </Button>
              <Button onClick={handleRecommendClick} sx={{ px: 1, py: 0.5 }} variant="outlined">
                Recommend To Students
              </Button>
            </Box>
          </Paper>
          {/* </AutoAdmit> */}
        </Box>
      )}

      <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
      <WhatIsRTSSchool open={whatIsRTSSchoolOpen} setOpen={setWhatIsRTSSchoolOpen} />
    </>
  );
};

export default StaffProgramCard;
