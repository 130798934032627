import { Formik, Form, Field } from "formik";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { stateAbbreviations as states } from "../../../shared/utils/addressUtils";
import { EFCDataRecord } from "../../../shared/types/types";
import { useEFCForm } from "../../hooks/efcDialog/useEFCForm";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";

type Props = {
  form: EFCDataRecord;
  handleSave: (formData: EFCDataRecord, efc: number) => void;
};

const EFCForm = ({ form, handleSave }: Props) => {
  const { initialValues, validationSchema, handleSubmit } = useEFCForm({ form, handleSave });

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        EFC Calculation Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ errors, touched, isSubmitting, isValid }) => (
          <>
            {isSubmitting ? (
              <LoadingBlock size={100} mt={8} />
            ) : (
              <Form>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel
                    htmlFor="state"
                    shrink
                    variant="standard"
                    sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-15px" }}
                  >
                    State
                  </InputLabel>
                  <Field
                    as={Select}
                    name="state"
                    id="state"
                    fullWidth
                    error={touched.state && Boolean(errors.state)}
                  >
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Field>
                  {touched.state && errors.state && (
                    <Typography color="error" variant="body2">
                      {errors.state}
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ fontSize: 10 }}>
                    The state you currently live in
                  </Typography>
                </FormControl>

                <FormControl component="fieldset" required>
                  <Field as={RadioGroup} name="maritalStatus" row>
                    <FormControlLabel value="single" control={<Radio />} label="Single" />
                    <FormControlLabel value="married" control={<Radio />} label="Married" />
                  </Field>
                  {touched.maritalStatus && errors.maritalStatus && (
                    <Typography color="error" variant="body2">
                      {errors.maritalStatus}
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                    Your parent's marital status
                  </Typography>
                </FormControl>

                <Field
                  as={TextField}
                  name="householdSize"
                  label="Household Size"
                  type="number"
                  fullWidth
                  error={touched.householdSize && Boolean(errors.householdSize)}
                  helperText={touched.householdSize && errors.householdSize}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The number of people living in your house, including yourself.
                </Typography>

                <Field
                  as={TextField}
                  name="childrenUnder18"
                  label="Children Under 18"
                  type="number"
                  fullWidth
                  error={touched.childrenUnder18 && Boolean(errors.childrenUnder18)}
                  helperText={touched.childrenUnder18 && errors.childrenUnder18}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The number of other children under the age of 18 living in your home.
                </Typography>

                <Field
                  as={TextField}
                  name="childrenInCollege"
                  label="Children In College"
                  type="number"
                  fullWidth
                  error={touched.childrenInCollege && Boolean(errors.childrenInCollege)}
                  helperText={touched.childrenInCollege && errors.childrenInCollege}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  You plus any additional siblings you have that are in college.
                </Typography>

                <Field
                  as={TextField}
                  name="parentIncome"
                  label="Parent Income"
                  type="number"
                  fullWidth
                  error={touched.parentIncome && Boolean(errors.parentIncome)}
                  helperText={touched.parentIncome && errors.parentIncome}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The total money your parent or parents make in a year.
                </Typography>

                <Field
                  as={TextField}
                  name="parentUntaxedIncome"
                  label="Parent Untaxed Income"
                  type="number"
                  fullWidth
                  error={touched.parentUntaxedIncome && Boolean(errors.parentUntaxedIncome)}
                  helperText={touched.parentUntaxedIncome && errors.parentUntaxedIncome}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  Any non-taxed income like child support, housing allowances, or military benefits.
                </Typography>

                <Field
                  as={TextField}
                  name="parentAssets"
                  label="Parent Assets"
                  type="number"
                  fullWidth
                  error={touched.parentAssets && Boolean(errors.parentAssets)}
                  helperText={touched.parentAssets && errors.parentAssets}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The total value of your parent or parents' assets like savings, investments, and real
                  estate.
                </Typography>

                <Field
                  as={TextField}
                  name="studentIncome"
                  label="Student Income"
                  type="number"
                  fullWidth
                  error={touched.studentIncome && Boolean(errors.studentIncome)}
                  helperText={touched.studentIncome && errors.studentIncome}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The amount of money you make in a year if you have a job.
                </Typography>

                <Field
                  as={TextField}
                  name="studentAssets"
                  label="Student Assets"
                  type="number"
                  fullWidth
                  error={touched.studentAssets && Boolean(errors.studentAssets)}
                  helperText={touched.studentAssets && errors.studentAssets}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The amount of money you have in savings.
                </Typography>

                <Field
                  as={TextField}
                  name="ageOfOldestParent"
                  label="Age of Oldest Parent"
                  type="number"
                  fullWidth
                  error={touched.ageOfOldestParent && Boolean(errors.ageOfOldestParent)}
                  helperText={touched.ageOfOldestParent && errors.ageOfOldestParent}
                />
                <Typography variant="body2" sx={{ fontSize: 10, mb: 3, mt: 0 }}>
                  The age of your oldest parent.
                </Typography>

                {!isValid && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    Please correct the errors before submitting.
                  </Alert>
                )}

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Formik>
    </Container>
  );
};

export default EFCForm;
