import { StudentRecord } from "../../../shared/types/types";
import QuestionWrapper from "./QuestionWrapper";
import usePreQuizQuestions from "../../hooks/quiz/usePreQuizQuestions";
import PreQuizQuestionContent from "./PreQuizQuestionContent";

type Props = {
  loggedInStudent: StudentRecord;
};

const PreQuizQuestions = ({ loggedInStudent }: Props) => {
  const {
    activeQuestion,
    myWhy,
    setMyWhy,
    whatDescribesMe,
    setWhatDescribesMe,
    onSubmit,
    onBack,
    isBackDisabled,
    isNextDisabled,
  } = usePreQuizQuestions(loggedInStudent);

  return (
    <QuestionWrapper
      loggedInStudent={loggedInStudent}
      question={activeQuestion}
      onSubmit={onSubmit}
      onBack={onBack}
      backDisabled={isBackDisabled}
      nextDisabled={isNextDisabled}
    >
      <PreQuizQuestionContent
        activeQuestion={activeQuestion}
        myWhy={myWhy}
        setMyWhy={setMyWhy}
        whatDescribesMe={whatDescribesMe}
        setWhatDescribesMe={setWhatDescribesMe}
      />
    </QuestionWrapper>
  );
};

export default PreQuizQuestions;
