import { useEffect, useState } from "react";
import { JobDetailsRecord } from "../../types/types";
import useGetFSDocs from "../db/useGetFSDocs";
import { Collection } from "../../types/enums";
import { parseJobsResponse } from "../../utils/parserUtils";

const useExploreCareeresRouter = () => {
  const [jobs, setJobs] = useState<JobDetailsRecord[]>([]);
  const { getFSDocs } = useGetFSDocs();
  useEffect(() => {
    const getJobs = async () => {
      const results = await getFSDocs<JobDetailsRecord>({
        col: Collection.JOBS,
      });
      if (results) setJobs(parseJobsResponse(results));
    };
    getJobs();
  }, [getFSDocs]);
  return {
    jobs,
    setJobs,
  };
};

export default useExploreCareeresRouter;
