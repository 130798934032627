import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import FeedContainer from "../components/feed/FeedContainer";

const StudentFeedPage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  return <>{loggedInStudent && <FeedContainer loggedInStudent={loggedInStudent} />}</>;
};

export default StudentFeedPage;
