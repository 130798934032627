import { Box, Chip, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { formatCurrency, numberToPercent } from "../../../shared/utils/formatUtils";
import { ProviderProgramRecord } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import infoIcon from "../../../shared/assets/icons/info.svg";
import WhatIsThat from "../../../shared/components/whatIsThat/WhatIsThat";

type Props = {
  setWhatIsROIOpen: React.Dispatch<React.SetStateAction<boolean>>;
  program: ProviderProgramRecord;
};

const formatROIColor = (roi: number | null) => {
  if (!roi) {
    return "#aaa";
  } else if (roi >= 100000) {
    return Color.MINT;
  } else if (roi >= 0) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatROILabel = (roi: number | null) => {
  if (!roi) {
    return "Unknown";
  } else if (roi >= 100000) {
    return "Good";
  } else if (roi >= 0) {
    return "Okay";
  } else {
    return "Poor";
  }
};

const formatPercentColor = (rate: number | null) => {
  if (!rate) {
    return "#aaa";
  } else if (rate > 0.8) {
    return Color.MINT;
  } else if (rate > 0.5) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatPercentLabel = (rate: number | null) => {
  if (!rate) {
    return "Unknown";
  } else if (rate > 0.8) {
    return "Good";
  } else if (rate > 0.5) {
    return "Okay";
  } else {
    return "Poor";
  }
};

const QualityMeasures = ({ program, setWhatIsROIOpen }: Props) => {

  const InstitutionTootip = useMemo(() => ({
    "title": "Return on Investment (ROI)",
    "description": `The ROI (Return on Investment) shown for this program is actually the ROI for all of ${program.providerName}, 
    not this specific program. This happens when there isn’t enough data available for us to calculate the 
    ROI for the program itself.`
  }), [program.providerName])

  const completionRate = program.completionRate ? program.completionRate : null;

  const roi = program.programROI
    ? program.programROI
    : program.providerROI
    ? program.providerROI
    : null;
  return (
    <>
      <Grid container sx={{ py: 1, backgroundColor: Color.SURFACE_GREEN }}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "left",
              alignContent: "space-between",
              height: "100%",
              pl: 2,
              pr: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: {
                  xs: "30px",
                  xl: "28px",
                },
                lineHeight: {
                  xs: "30px",
                  xl: "28px",
                },
                color: program.programROI ? "#171918" : Color.STANDARD_GRAY,
                p: 0,
                m: 0,
              }}
              variant="h6"
            >
              {roi ? formatCurrency({ amount: roi }) : "Not Known"}
            </Typography>
            {!program.programROI ? 
              <Box sx={{display: 'flex', alignItems: 'center', pt: 1}}>
                <Typography sx={{color: Color.STANDARD_GRAY}}>
                  (Institution)
                </Typography> 
                <WhatIsThat information={InstitutionTootip} />
              </Box> : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                pb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 12 }} variant="h5">
                10 Year ROI
              </Typography>
              <img
                src={infoIcon}
                alt="info"
                width="12px"
                height="12px"
                style={{ cursor: "pointer", marginLeft: "4px" }}
                onClick={() => setWhatIsROIOpen(true)}
              />
            </Box>

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{ backgroundColor: formatROIColor(roi), color: "#fff", p: 0 }}
                label={formatROILabel(roi)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "left",
              height: "100%",
              pl: 1,
              pr: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: {
                  xs: "30px",
                  xl: "28px",
                },
                lineHeight: {
                  xs: "30px",
                  xl: "28px",
                },
                color: "#171918",
                p: 0,
                m: 0,
              }}
              variant="h6"
            >
              {completionRate ? numberToPercent({ number: completionRate }) : "Not Known"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                pb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 12 }} variant="h5">
                Completion Rate
              </Typography>
            </Box>

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{ backgroundColor: formatPercentColor(completionRate), color: "#fff", p: 0 }}
                label={formatPercentLabel(completionRate)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QualityMeasures;
