export enum TourSelector {
  PROFILE = "reactour__profile",
  LOGOUT = "reactour__logout",
  HOME_NAV = "reactour_home_nav",
  CAREERS_NAV = "reactour_career_nav",
  PROGRAMS_NAV = "reactour_programs_nav",
  SCHOLARSHIPS_NAV = "reactour_scholarships_nav",
  ALMA_NAV = "reactour_alma_nav",
  BOOKMARKS_NAV = "reactour_bookmarks_nav",
}

export enum TourName {
  PROFILE = "profile",
  NAVIGATION = "navigation",
}
