import DemoLoginContainer from "../components/login/DemoLoginContainer";
import { Container } from "@mui/material";
import { Color } from "../types/enums";

const DemoLoginPage = () => {
  return (
    <Container maxWidth={false} sx={{ minHeight: "100VH", backgroundColor: Color.CHALKBOARD }}>
      <DemoLoginContainer />
    </Container>
  );
};

export default DemoLoginPage;
