import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import StudentAlmaDesktop from "./StudentAlmaDesktop";
import StudentAlmaMobile from "./StudentAlmaMobile";
import { useAlmaDataContext } from "../../contexts/studentAlmaDataContext";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { schoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { currentStudentAlmaChatAtom } from "../../../shared/recoil/almaAtoms";

const StudentAlmaChatContainer = () => {
  const { width } = useWindowDimensions();
  const { loading } = useAlmaDataContext();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const schools = useRecoilValue(schoolsAtom);
  const currentStudentAlmaChat = useRecoilValue(currentStudentAlmaChatAtom);

  if (!loggedInStudent) return null;

  return (
    <>
      {width > 900 ? (
        <>
          {!loading && (
            <StudentAlmaDesktop
              schools={schools}
              loggedInStudent={loggedInStudent}
              currentStudentAlmaChat={currentStudentAlmaChat}
            />
          )}
        </>
      ) : (
        <>
          {!loading && (
            <StudentAlmaMobile
              schools={schools}
              loggedInStudent={loggedInStudent}
              currentStudentAlmaChat={currentStudentAlmaChat}
            />
          )}
        </>
      )}
    </>
  );
};

export default StudentAlmaChatContainer;
