import { Box } from "@mui/material";
import UploadVideosContainer from "../components/uploadVideos/UploadVideosContainer";

const UploadVideosPage = () => {
  return (
    <Box sx={{ minHeight: "calc(100VH-64px)", p:2 }}>
      <UploadVideosContainer />
    </Box>
  );
};

export default UploadVideosPage;
