import { Field } from "../../types/types";
import { Box, Typography } from "@mui/material";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { Color } from "../../types/enums";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import { useSetRecoilState } from "recoil";
import { selectedFieldIdAtom } from "../../recoil/exploreCareersAtoms";

type Props = {
  field: Field;
};

const FieldCard = ({ field }: Props) => {
  const setSelectedFieldId = useSetRecoilState(selectedFieldIdAtom);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <Box
      sx={{
        backgroundColor: Color.WHITE,
        borderRadius: 4,
        px: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
        height: "100%",
        boxShadow: "0px 2px 8px -1px #10182814",
      }}
      onClick={() => setSelectedFieldId(field.id)}
    >
      <Box sx={{ py: mobile ? 1.5 : 5.5 }}>
        <Typography
          textAlign={"left"}
          variant="h6"
          sx={{ lineHeight: 1.2, color: Color.CHALKBOARD, fontSize: mobile ? 14 : 20 }}
        >
          {field.title}
        </Typography>
        <Typography sx={{ pt: 1 }}>{field.description}</Typography>
      </Box>
      <Box sx={{ flex: "none", display: mobile ? "none" : "flex" }}>
        <FeatherIcon icon="chevron-right" width="24px" height="24px" />
      </Box>
    </Box>
  );
};

export default FieldCard;
