import { Box, Typography } from "@mui/material";

// Custom tooltip for displaying student names
const PieChartTooltip = ({ active, payload, coordinate }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload as { name: string; value: number; students: string[] };
    const { x, y } = coordinate;

    return (
      <Box
        sx={{
          position: "absolute",
          left: x - 200, // Position tooltip to the left of the cursor
          top: y - 50, // Adjust the top position based on cursor
          transform: "translateY(-100%)", // Move tooltip above the cursor
          p: 2,
          bgcolor: "#ffffff",
          border: "1px solid #ccc",
          borderRadius: 2,
          boxShadow: 3,
          minWidth: 300,
          zIndex: 1000, // Ensure tooltip is above other elements
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {data.name}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {data.students.join(", ")}
        </Typography>
      </Box>
    );
  }
  return null;
};

export default PieChartTooltip;
