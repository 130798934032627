import { Box, Typography } from "@mui/material";
import { Color } from "../../types/enums";
import { ProviderRecord } from "../../types/types";
import DisplayBox from "../displayBox/DisplayBox";
import { numberToPercent, numberToRound } from "../../utils/formatUtils";

type Props = {
  provider: ProviderRecord;
};

const typeSX = {
  textAlign: "left",
  mb: 3,
  fontSize: 14,
  color: Color.CHARCOAL,
};

const AtAGlance = ({ provider }: Props) => {
  //data in religionAffiliation is sometimes "null" as a string
  return (
    <DisplayBox title="At a Glance">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          justifyContent: "space-between",
        }}
      >
        <Typography sx={typeSX}>
          <b>Admission Rate: </b>
          {provider.admissionPercent ? numberToPercent({ number: provider.admissionPercent }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Total Size: </b>
          {provider.undergradTotalEnrollment
            ? numberToRound({ number: provider.undergradTotalEnrollment })
            : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Median ACT: </b>
          {provider.actMid ? numberToRound({ number: provider.actMid }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Black: </b>
          {provider.percentBlack ? numberToPercent({ number: provider.percentBlack }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Latinx: </b>
          {provider.percentHispanic ? numberToPercent({ number: provider.percentHispanic }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Asian: </b>
          {provider.percentAsian ? numberToPercent({ number: provider.percentAsian }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent AIAN: </b>
          {provider.percentAIAN ? numberToPercent({ number: provider.percentAIAN }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent NHPI: </b>
          {provider.percentNHPI ? numberToPercent({ number: provider.percentNHPI }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Two or More: </b>
          {provider.percentTwoOrMore ? numberToPercent({ number: provider.percentTwoOrMore }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent White: </b>
          {provider.percentWhite ? numberToPercent({ number: provider.percentWhite }) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>HBCU: </b>
          {provider.hbcu ? "Yes" : "No"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Religious Affiliation: </b>
          {(provider.religiousAffiliation && provider.religiousAffiliation !== "null") ? provider.religiousAffiliation : "None"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Single Gender: </b>
          {provider.menOnly ? "Men Only" : provider.womenOnly ? "Women Only" : "No"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Type: </b>
          {provider.ownership}
        </Typography>
      </Box>
    </DisplayBox>
  );
};

export default AtAGlance;
