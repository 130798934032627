import { createContext, useContext } from "react";
import {
  CareerVideoRecord,
  JobDetailsRecord,
  ProviderProgramRecord,
  VideoInteractionRecord,
} from "../../shared/types/types";

type Props = {
  loading: boolean;
  likedVideos: CareerVideoRecord[];
  interactions: VideoInteractionRecord[];
  jobs: JobDetailsRecord[];
  programs: ProviderProgramRecord[];
};

export const MyListContext = createContext<Props>({
  loading: true,
  likedVideos: [],
  interactions: [],
  jobs: [],
  programs: [],
});

export const useMyListContext = () => {
  return useContext(MyListContext);
};
