import React, { ChangeEvent, useEffect, useState } from "react";
import { Message } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { updateMessages } from "../../../shared/utils/messageFunctionUtils";
import { getDatabase, ref, onValue } from "firebase/database";
import { BLANK_MESSAGE_FORM } from "../../../shared/utils/blankUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

export const useMessagePanel = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState({ ...BLANK_MESSAGE_FORM });
  const [_toggle, setToggle] = useState(false);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const studentPhoneJustNumbers = selectedStudent?.phone?.replace(/\D/g, "");

  //subscribe to all messages for this student
  useEffect(() => {
    if (!loggedInStaff || !selectedStudent) return;
    const db = getDatabase();
    const messagesRef = ref(db, `messages/${loggedInStaff.smsNumber}_${studentPhoneJustNumbers}`);

    let initialMessages: any = [];

    const unsubscribeIncomingMessages = onValue(messagesRef, (snapshot) => {
      const newData = snapshot.val();
      setMessages((_prevMessages) =>
        updateMessages({
          newMessages: newData,
          existingMessages: initialMessages,
          selectedStudent: selectedStudent,
        })
      );
    });

    return () => {
      unsubscribeIncomingMessages();
    };
  }, [loggedInStaff, selectedStudent, studentPhoneJustNumbers]);

  //the message path is always staff phone number, then student phone number
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!loggedInStaff || !selectedStudent) return;

    const updatedMessage = {
      ...message,
      message: `${message?.message}\nFrom ${loggedInStaff.firstName} ${loggedInStaff.lastName}`,
    };

    const to = studentPhoneJustNumbers;
    const body = updatedMessage.message;

    fetchData({
      functionName: FunctionName.SEND_SMS,
      payload: {
        to,
        body,
        staffId: loggedInStaff.id,
        studentId: selectedStudent?.id,
        studentPhoneNumber: studentPhoneJustNumbers,
        authorId: loggedInStaff.id,
        staffPhoneNumber: loggedInStaff.smsNumber,
      },
    });

    fetchData({
      functionName: FunctionName.PUT_MESSAGE,
      payload: {
        message: updatedMessage,
        staffId: loggedInStaff.id,
        studentId: selectedStudent?.id,
        studentPhoneNumber: studentPhoneJustNumbers,
        authorId: loggedInStaff.id,
        staffPhoneNumber: loggedInStaff.smsNumber,
      },
    });
    setMessage({ ...BLANK_MESSAGE_FORM });
    setToggle((pV) => !pV);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, message: event.target.value });
  };

  return { handleTextChange, handleSubmit, messages, message, loggedInStaff };
};
