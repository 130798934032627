import React, { Dispatch } from "react";
import { FilterProps } from "../../../shared/types/types";
import { FormControl, FormLabel, Slider } from "@mui/material";
import { sliderValueText } from "../../utils/programFilterUtils";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const CostFilter = ({ filters, setFilters }: Props) => {
  const handleCostChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    const newCost: [number, number] = [newValue[0], newValue[1] < 250000 ? newValue[1] : 999999999];
    setFilters((pV) => ({
      ...pV,
      cost: newCost,
    }));
  };
  return (
    <>
      <FormControl fullWidth>
        <FormLabel component="legend">Cost</FormLabel>
        <Slider
          value={filters.cost}
          onChange={handleCostChange}
          min={0}
          max={250000}
          valueLabelDisplay="auto"
          valueLabelFormat={sliderValueText}
        />
      </FormControl>
    </>
  );
};

export default CostFilter;
