import { Box } from "@mui/material";
import { ChatGPTEntry } from "../../../shared/types/types";
import Markdown from "react-markdown";
import { formatChatBackground } from "../../utils/almaStyles";

type MessageProps = {
  index: number;
  entry: ChatGPTEntry;
};

const Message = ({ index, entry }: MessageProps) => {
  return (
    <Box
      key={index}
      sx={{ p: 1, display: "flex", justifyContent: entry.role === "assistant" ? "end" : "start" }}
    >
      <Box sx={formatChatBackground(entry.role)}>
        <Markdown>{entry.content}</Markdown>
      </Box>
    </Box>
  );
};

export default Message;
