import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { FilterProps, ProviderProgramRecord, SearchedProvider } from "../../types/types";
import ProgramCard from "../../../students/components/programCard/ProgramCard";
import StaffProgramCard from "../../../staff/components/programCard/StaffProgramCard";
import { useState } from "react";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { UserType } from "../../types/enums";
import ProgramFilterAndSearchBar from "../../../students/components/explorePrograms/filters/ProgramFilterAndSearchBar";
import FiltersDialog from "./FiltersDialog";

type Props = {
  setPrograms: React.Dispatch<React.SetStateAction<ProviderProgramRecord[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleQuery: () => void;
  filters: FilterProps;
  isEmptyFilters: boolean;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  listRef: React.RefObject<HTMLDivElement> | null;
  programs: ProviderProgramRecord[];
  loading: boolean;
  handleFilterButtonClick: (searchedProviders?: SearchedProvider[], filters?: FilterProps) => void;
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<React.SetStateAction<SearchedProvider[]>>;
};

const ExploreProgramsDesktop = ({
  filters,
  isEmptyFilters,
  setFilters,
  listRef,
  programs,
  loading,
  handleFilterButtonClick,
  searchedProviders,
  setSearchedProviders,
}: Props) => {
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecord | null>(
    null
  );
  const userType = useRecoilValue(userTypeAtom);

  const oddPrograms = programs.filter((_, index) => index % 2 === 0);
  const evenPrograms = programs.filter((_, index) => index % 2 !== 0);

  const handleChangeFiltersButtonClick = () => {
    setShowFiltersDialog(true);
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        {isEmptyFilters && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "800px" }}>
              <ProgramFilterAndSearchBar
                isDialogMode={false}
                filters={filters}
                setFilters={setFilters}
                handleFilterButtonClick={handleFilterButtonClick}
                searchedProviders={searchedProviders}
                setSearchedProviders={setSearchedProviders}
              />
            </Box>
          </Box>
        )}
        {!isEmptyFilters && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ flex: "none", width: "10%" }}></Box>
            <Box
              ref={listRef}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "calc(100vh - 113px)",
                overflowY: "scroll",
              }}
            >
              {!loading && oddPrograms.length === 0 && evenPrograms.length === 0 ? (
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
                    No results found, please adjust your filter settings
                  </Typography>
                </Box>
              ) : userType === UserType.STUDENT ? (
                <Grid container spacing={5} sx={{ maxWidth: "1000px", px: 3, py: 5 }}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                      {oddPrograms.map((program) => (
                        <ProgramCard
                          key={program.id}
                          program={program}
                          setSelectedLearnMoreProgram={setSelectedProgramDetail}
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                      {evenPrograms.map((program) => (
                        <ProgramCard
                          key={program.id}
                          program={program}
                          setSelectedLearnMoreProgram={setSelectedProgramDetail}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} sx={{ maxWidth: "1000px", px: 3, py: 5 }}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                      {oddPrograms.map((program) => (
                        <StaffProgramCard
                          key={program.id}
                          program={program}
                          setSelectedLearnMoreProgram={setSelectedProgramDetail}
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                      {evenPrograms.map((program) => (
                        <StaffProgramCard
                          key={program.id}
                          program={program}
                          setSelectedLearnMoreProgram={setSelectedProgramDetail}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <CircularProgress size={100} />
                </Box>
              )}
            </Box>
            <Box sx={{ flex: "none", width: "10%", py: 5 }} textAlign="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleChangeFiltersButtonClick}
              >
                Change Filters
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <FiltersDialog
        open={showFiltersDialog}
        setOpen={setShowFiltersDialog}
        filters={filters}
        searchedProviders={searchedProviders}
        handleFilterButtonClick={handleFilterButtonClick}
      />
      {selectedProgramDetail && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgramDetail}
          setSelectedProgram={setSelectedProgramDetail}
        />
      )}
    </>
  );
};

export default ExploreProgramsDesktop;
