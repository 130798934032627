import * as React from "react";
import { Color } from "../../../shared/types/enums";
import { IconButton, ListItemButton, ListItem, List, Divider } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "../../../shared/components/navigation/Drawer";
import SidebarHeader from "../../../shared/components/navigation/SidebarHeader";
import StudentSideMenu from "./StudentSideMenu";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StudentSideDesktopNavbar = ({ open, setOpen }: Props) => {
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Drawer variant="permanent" open={open} color="primary" sx={{ zIndex: 999 }}>
        <SidebarHeader>
          <ListItem aria-label="Open/Close Menu" sx={{ display: "block" }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon
                  sx={{
                    justifyContent: "center",
                    color: Color.CHALK,
                    ml: -2,
                  }}
                  aria-label="Close Drawer"
                />
              </IconButton>
            ) : (
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  justifyContent: "center",
                  ml: -1.2,
                }}
                aria-label="Open Drawer"
              >
                <ChevronRightIcon />
              </ListItemButton>
            )}
          </ListItem>
        </SidebarHeader>
        <Divider />
        <List
          sx={{
            height: "100VH",
          }}
        >
          <StudentSideMenu open={open} />
        </List>
      </Drawer>
    </>
  );
};

export default StudentSideDesktopNavbar;
