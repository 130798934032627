import AllProgramsContainer from "../../../shared/components/explorePrograms/AllProgramsContainer";
import RecommendProgramDialog from "../programCard/RecommendProgramDialog";

const ExploreProgramsContainer = () => {
  return (
    <>
      <AllProgramsContainer />
      <RecommendProgramDialog />
    </>
  );
};

export default ExploreProgramsContainer;
