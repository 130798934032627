import { TourSelector } from "../../shared/types/tourEnums";

export const navigationSteps = [
  {
    selector: `[data-tut="${TourSelector.HOME_NAV}"]`,
    content: "This is the home page. You can view vidoes about different careers here.",
  },
  {
    selector: `[data-tut="${TourSelector.BOOKMARKS_NAV}"]`,
    content: "You can see your bookmarked videos, careers, and programs here.",
  },
  {
    selector: `[data-tut="${TourSelector.CAREERS_NAV}"]`,
    content: "You can explore and see recommended careers here.",
  },
  {
    selector: `[data-tut="${TourSelector.PROGRAMS_NAV}"]`,
    content: "You can explore and see recommended programs here.",
  },
  {
    selector: `[data-tut="${TourSelector.SCHOLARSHIPS_NAV}"]`,
    content: "You can explore and see recommended scholarships here.",
  },
  {
    selector: `[data-tut="${TourSelector.ALMA_NAV}"]`,
    content: "You can talk with Alma, our AI advisor here.",
  },
];
