import { useState, useEffect, useCallback } from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useGetFSDocs from "../../hooks/db/useGetFSDocs";
import { Collection } from "../../types/enums";
import { CareerVideoRecord } from "../../types/types";
import { db } from "../../../firebase";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";

const DuplicateVideoManager = () => {
  const [duplicateVideos, setDuplicateVideos] = useState<CareerVideoRecord[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const { getFSDocs } = useGetFSDocs();
  const [changingVideoId, setChangingVideoId] = useState<string | null>(null);

  const storage = getStorage();

  const findDuplicateVideos = useCallback(async () => {
    setLoading(true);
    try {
      const allVideos = await getFSDocs<CareerVideoRecord>({
        col: Collection.CAREER_VIDEOS,
      });

      // Sort videos by onet and then by createdAt
      allVideos.sort((a, b) => {
        if (a.onet < b.onet) return -1;
        if (a.onet > b.onet) return 1;

        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });

      // Group videos by fileName to detect duplicates
      const fileNameMap: any = {};
      allVideos.forEach((video) => {
        if (fileNameMap[video.fileName]) {
          fileNameMap[video.fileName].push(video);
        } else {
          fileNameMap[video.fileName] = [video];
        }
      });

      const duplicates = [];
      for (const key in fileNameMap) {
        if (fileNameMap[key].length > 1) {
          duplicates.push(...fileNameMap[key]);
        }
      }

      setDuplicateVideos(duplicates);
    } catch (error) {
      console.error("Error finding duplicates:", error);
      alert("Error finding duplicates. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [getFSDocs]);

  const handleDelete = useCallback(async (videoId: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this video?"
    );
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await deleteDoc(doc(db, Collection.CAREER_VIDEOS, videoId));

      setDuplicateVideos((prev) =>
        prev.filter((video) => video.id !== videoId)
      );

      alert("Video deleted successfully!");
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Error deleting video. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeVideo = useCallback(
    async (video: CareerVideoRecord, newFile: File) => {
      setChangingVideoId(video.id);

      try {
        const filename = `${crypto.randomUUID()}.mp4`;

        const storageRef = ref(storage, `/career-videos/${filename}`);
        await uploadBytes(storageRef, newFile);

        await updateDoc(doc(db, Collection.CAREER_VIDEOS, video.id), {
          fileName: filename,
        });

        alert(`Video updated successfully!`);
        findDuplicateVideos();
      } catch (error) {
        console.error("Error changing video:", error);
        alert("Error changing video. Please try again.");
      } finally {
        setChangingVideoId(null);
      }
    },
    [storage, findDuplicateVideos]
  );

  const handleFileChange = useCallback(
    (video: CareerVideoRecord, event: any) => {
      const file = event.target.files[0];
      if (file) {
        handleChangeVideo(video, file);
      }
    },
    [handleChangeVideo]
  );

  const handleOpenVideo = useCallback(
    async (video: CareerVideoRecord) => {
      try {
        const storageRef = ref(storage, `/career-videos/${video.fileName}`);
        const url = await getDownloadURL(storageRef);
        window.open(url, "_blank");
      } catch (error) {
        console.error("Error fetching video URL:", error);
        alert("Error fetching video URL. Please try again.");
      }
    },
    [storage]
  );

  useEffect(() => {
    findDuplicateVideos();
  }, [findDuplicateVideos]);

  const showVideoList = useCallback(
    (videos: CareerVideoRecord[]) => {
      let color: string = "#f5f5f5";
      let previousFileName: string = "";

      return videos.map((video) => {
        if (previousFileName !== video.fileName) {
          color = color === "#f5f5f5" ? "#ffffff" : "#f5f5f5";
          previousFileName = video.fileName;
        }
        return (
          <ListItem key={video.id} divider sx={{ backgroundColor: color }}>
            <Box width="100%" p={1}>
              <Typography variant="body1" gutterBottom>
                OnetCode: {video.onet}
              </Typography>
              <Typography variant="body1" gutterBottom>
                File Name: {video.fileName}
              </Typography>
              <Typography variant="body2">
                Created At: {new Date(video.createdAt).toLocaleString()}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleOpenVideo(video)}
              sx={{ ml: 1, width: 200 }}
            >
              Open Video
            </Button>
            <Button
              variant="contained"
              component="label"
              color="primary"
              sx={{ ml: 1, width: 200 }}
              disabled={changingVideoId === video.id}
            >
              {changingVideoId === video.id ? "Changing..." : "Change Video"}
              <input
                type="file"
                hidden
                accept="video/mp4"
                onChange={(event) => handleFileChange(video, event)}
              />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDelete(video.id)}
              sx={{ ml: 1 }}
            >
              Delete
            </Button>
          </ListItem>
        );
      });
    },
    [handleDelete, handleFileChange, handleOpenVideo, changingVideoId]
  );

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Duplicate Video Manager
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={findDuplicateVideos}
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading ? "Fetching..." : "Fetch Latest Data"}
      </Button>

      {loading && <CircularProgress />}

      {!loading && duplicateVideos.length === 0 && (
        <Typography>No duplicate videos found.</Typography>
      )}

      {!loading && duplicateVideos.length > 0 && (
        <Box>
          <Typography>
            Found {duplicateVideos.length} duplicate videos:
          </Typography>
          <List sx={{ height: "600px", overflowY: "auto" }}>
            {showVideoList(duplicateVideos)}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default DuplicateVideoManager;
