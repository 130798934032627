import { atom, selector } from "recoil";

export const siteLoadingAtom = atom({
  key: "siteLoadingAtom",
  default: true,
});

export const userLoadingAtom = atom({
  key: "userLoadingAtom",
  default: true,
});

export const authLoadingAtom = atom({
  key: "authLoadingAtom",
  default: true,
});

export const pageLoadingAtom = selector({
  key: "pageLoadingAtom",
  get: ({ get }) => {
    return get(siteLoadingAtom) || get(userLoadingAtom) || get(authLoadingAtom);
  },
});
