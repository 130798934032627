import { useEffect, useState } from "react";
import { Paper, Box, Typography, Button, Grid, Chip, Divider } from "@mui/material";
import {
  ScholarshipApplication,
  ScholarshipApplicationRecord,
  ScholarshipRecord,
} from "../../../shared/types/types";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { Collection, Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

const generateBlankScholarshipApplication = ({
  scholarshipId,
  studentId,
  schoolId,
  districtId,
}: {
  scholarshipId: string;
  studentId: string;
  schoolId: string;
  districtId: string;
}): ScholarshipApplication => {
  return {
    scholarshipId,
    studentId,
    status: "applied",
    appliedDate: null,
    awardAmount: null,
    awardDate: null,
    schoolId,
    districtId,
  };
};

type Props = {
  scholarship: ScholarshipRecord;
  scholarshipApplications: ScholarshipApplicationRecord[];
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
  setSelectedScholarshipApplication: React.Dispatch<
    React.SetStateAction<ScholarshipApplicationRecord | null>
  >;
  setSelectedScholarship: React.Dispatch<React.SetStateAction<ScholarshipRecord | null>>;
};

function ScholarshipCard({
  scholarship,
  scholarshipApplications,
  setScholarshipApplications,
  setSelectedScholarshipApplication,
  setSelectedScholarship,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const { addFSDoc } = useAddFSDoc();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [scholarshipApplication, setScholarshipApplication] =
    useState<ScholarshipApplicationRecord | null>(null);

  useEffect(() => {
    const application = scholarshipApplications.find((app) => app.scholarshipId === scholarship.id);
    setScholarshipApplication(application ? application : null);
  }, [scholarshipApplications, scholarship.id]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0, // No decimals
    }).format(amount);
  };

  const awardAmount =
    scholarship.minAwardAmount && scholarship.maxAwardAmount
      ? `Award Amount: ${formatCurrency(scholarship.minAwardAmount)} - ${formatCurrency(
          scholarship.maxAwardAmount
        )}`
      : scholarship.minAwardAmount
      ? `Award Amount: ${formatCurrency(scholarship.minAwardAmount)}`
      : scholarship.maxAwardAmount
      ? `Award Amount: ${formatCurrency(scholarship.maxAwardAmount)}`
      : null;

  const truncatedDescription =
    scholarship.description.length > 100
      ? `${scholarship.description.substring(0, 100)}...`
      : scholarship.description;

  const displayDescription = expanded ? scholarship.description : truncatedDescription;

  const handleUpdateStatus = async () => {
    if (!loggedInStudent || !loggedInStudent.schoolId || !loggedInStudent.districtId) return;
    if (!scholarshipApplication) {
      const blankApplication = generateBlankScholarshipApplication({
        scholarshipId: scholarship.id,
        studentId: loggedInStudent.id,
        schoolId: loggedInStudent.schoolId,
        districtId: loggedInStudent.districtId,
      });
      const results = await addFSDoc<ScholarshipApplicationRecord>({
        col: Collection.SCHOLARSHIP_APPLICATIONS,
        data: blankApplication,
      });
      if (!results) return;
      setScholarshipApplications((prev) => [...prev, results]);
    } else {
      setSelectedScholarshipApplication(scholarshipApplication);
    }
  };

  const CardChip = (props: { label: string }) => (
    <Chip label={props.label} color="lightGreen" sx={{ fontSize: 10, p: 0, height: 24 }} />
  );

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 1,
        boxShadow: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Content section */}
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {scholarship.sponsorName && (
                <Typography variant="h6" sx={{ fontSize: 14, color: Color.MINT }}>
                  {scholarship.sponsorName}
                </Typography>
              )}
              <Typography variant="h4">{scholarship.name}</Typography>
            </Box>
            {scholarshipApplication && scholarshipApplication.status !== "not applied" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FeatherIcon icon="circle-check" strokeColor={Color.MINT} />
                <Typography color={Color.MINT} variant="body2">
                  Applied
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 1 }}>
            {scholarship.tags.length > 0 &&
              scholarship.tags.map((tag) => <CardChip key={tag} label={tag} />)}
          </Box>
          <Box sx={{ mt: 2 }}>
            {scholarship.eligibilityGpa && (
              <Typography variant="body1">
                <b>Eligibility GPA:</b> {scholarship.eligibilityGpa}
              </Typography>
            )}
            <Typography variant="body1" sx={{ mt: 1 }}>
              {displayDescription}
              {scholarship.description.length > 100 && (
                <Typography
                  variant="body2"
                  onClick={handleExpandClick}
                  sx={{ cursor: "pointer", color: Color.MINT, display: "inline" }}
                >
                  {expanded ? " Show Less" : " Show More"}
                </Typography>
              )}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />

          {awardAmount && (
            <Typography variant="h6" sx={{ mt: 1 }}>
              {awardAmount}
            </Typography>
          )}
          {scholarship.deadlineDescription && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {scholarship.deadlineDescription}
            </Typography>
          )}
        </Box>

        {/* Buttons section */}
        <Box sx={{ mt: "auto" }}>
          <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
            {scholarship.startAt && (
              <Typography variant="body2">
                Open Date: {convertIsoToDate(scholarship.startAt)}
              </Typography>
            )}
            {scholarship.endAt && (
              <Typography variant="body2">
                Deadline: {convertIsoToDate(scholarship.endAt)}
              </Typography>
            )}
          </Grid>

          <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => setSelectedScholarship(scholarship)}>
              Details
            </Button>
            {scholarshipApplication && scholarshipApplication.status !== "not applied" ? (
              <Button variant="outlined" onClick={handleUpdateStatus}>
                Update Status
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleUpdateStatus}>
                Mark As Applied
              </Button>
            )}
            <Button
              variant="contained"
              href={scholarship.submissionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply
            </Button>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}

export default ScholarshipCard;

function convertIsoToDate(isoString: string) {
  const date = new Date(isoString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
