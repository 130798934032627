import { DatePicker } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import dayjs from "dayjs"; // Import dayjs for working with date formats

const FormikDatePicker = ({ field, form, ...other }: FieldProps & any) => {
  const currentError = form.errors[field.name];

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      // Format the date to MM/dd/yyyy using dayjs
      const formattedDate = date.format("MM/DD/YYYY");
      form.setFieldValue(field.name, formattedDate);
    } else {
      form.setFieldValue(field.name, null);
    }
  };

  return (
    <DatePicker
      {...other}
      value={field.value ? dayjs(field.value, "MM/DD/YYYY") : null}
      slotProps={{ textField: { fullWidth: true } }}
      onChange={handleDateChange}
      format="MM/DD/YYYY"
      renderInput={(params: TextFieldProps) => (
        <TextField {...params} error={Boolean(currentError)} helperText={currentError} fullWidth />
      )}
    />
  );
};

export default FormikDatePicker;
