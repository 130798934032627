import { JobDetailsRecord } from "../../../shared/types/types";
import { Box, IconButton, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { useSetRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";
import { ChevronRightOutlined } from "@mui/icons-material";

type Props = {
  job: JobDetailsRecord;
};

const StaffJobTitleCard = ({ job }: Props) => {
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);

  return (
    <>
      <Box
        sx={{
          px: 2.5,
          py: 1.5,
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid #eaeaea",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="body1" sx={{ color: Color.CHALKBOARD, fontSize: 14 }}>
            {job.title}
          </Typography>
          <IconButton onClick={() => setSelectedJobId && setSelectedJobId(job.id)}>
            <ChevronRightOutlined />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default StaffJobTitleCard;
