const stateTaxAllowance: { [key: string]: number } = {
  AL: 0.02,
  AK: 0.01,
  AS: 0.02, // American Samoa
  AZ: 0.03,
  AR: 0.03,
  CA: 0.07,
  CO: 0.03,
  CT: 0.08,
  DE: 0.04,
  DC: 0.06, // District of Columbia
  FM: 0.02, // Federated States of Micronesia
  FL: 0.02,
  GA: 0.04,
  GU: 0.02, // Guam
  HI: 0.04,
  ID: 0.04,
  IL: 0.04,
  IN: 0.03,
  IA: 0.04,
  KS: 0.03,
  KY: 0.04,
  LA: 0.02,
  ME: 0.05,
  MH: 0.02, // Marshall Islands
  MD: 0.07,
  MA: 0.06,
  MI: 0.03,
  MN: 0.05,
  MS: 0.02,
  MO: 0.04,
  MT: 0.04,
  NE: 0.04,
  NV: 0.01,
  NH: 0.03,
  NJ: 0.08,
  NM: 0.02,
  NY: 0.08,
  NC: 0.04,
  ND: 0.01,
  MP: 0.02, // Northern Mariana Islands
  OH: 0.04,
  OK: 0.02,
  OR: 0.06,
  PW: 0.02, // Palau
  PA: 0.04,
  PR: 0.02, // Puerto Rico
  RI: 0.05,
  SC: 0.03,
  SD: 0.01,
  TN: 0.01,
  TX: 0.02,
  UT: 0.04,
  VT: 0.05,
  VI: 0.02, // Virgin Islands
  VA: 0.05,
  WA: 0.02,
  WV: 0.02,
  WI: 0.05,
  WY: 0.01,
  Other: 0.02,
};

interface IPAValues {
  [householdSize: number]: {
    [collegeStudents: number]: number;
  };
}

const incomeProtectionAllowanceMatrix: IPAValues = {
  2: {
    1: 19080,
    2: 15810,
  },
  3: {
    1: 23760,
    2: 20510,
    3: 17250,
  },
  4: {
    1: 29340,
    2: 26080,
    3: 22830,
    4: 19570,
  },
  5: {
    1: 34620,
    2: 31350,
    3: 28110,
    4: 24840,
    5: 21600,
  },
  6: {
    1: 40490,
    2: 37230,
    3: 33980,
    4: 30720,
    5: 27470,
  },
};

type GetStateTaxAllowanceProps = {
  state: string;
  income: number;
}

export const getStateTaxAllowance = ({ state, income }: GetStateTaxAllowanceProps): number => {
  const rate = stateTaxAllowance[state] || stateTaxAllowance["Other"];
  return rate * income;
};

type GetIncomeProtectionAllowanceProps = {
  householdSize: number;
  childrenInCollege: number;
}

export const getIncomeProtectionAllowance = ({ householdSize, childrenInCollege }: GetIncomeProtectionAllowanceProps): number => {
  if (householdSize > 1 && householdSize < 7 && childrenInCollege > 0 && childrenInCollege < 6) {
    return incomeProtectionAllowanceMatrix[householdSize][childrenInCollege];
  } else {
    return 23760;
  }
};
