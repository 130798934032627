import React from "react";
import { Color } from "../../../shared/types/enums";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

type Props = {
  selected: string;
  handleSelected: (event: React.MouseEvent<HTMLElement>, value: string) => void;
};

const TopNavigationToggle = ({ selected, handleSelected }: Props) => {
  return (
    <div style={{ position: "sticky", top: 64, zIndex: 5, backgroundColor: "#fff" }}>
      <ToggleButtonGroup
        value={selected}
        exclusive
        fullWidth
        onChange={handleSelected}
        sx={{ backgroundColor: "#fff" }}
      >
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "all" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="all"
        >
          All Scholarships
        </ToggleButton>
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "recommended" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="recommended"
        >
          Recommended Scholarships
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default TopNavigationToggle;
