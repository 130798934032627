import { Box, CircularProgress } from "@mui/material";

type Props = {
  size?: number;
  mt?: number;
};

const LoadingBlock = ({ size = 50, mt = 2 }: Props) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: mt }}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default LoadingBlock;
