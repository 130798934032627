import React, { ChangeEvent, Dispatch } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const SizeFilter = ({ filters, setFilters }: Props) => {
  const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const sizes = [...filters.sizes];
    if (sizes.includes(name)) {
      sizes.splice(sizes.indexOf(name), 1);
    } else {
      sizes.push(name);
    }
    setFilters((pV) => ({ ...pV, sizes }));
  };
  return (
    <>
      <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Size</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.sizes.includes("small")}
                onChange={handleSizeChange}
                name="small"
              />
            }
            label="Small (< 2,000)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.sizes.includes("medium")}
                onChange={handleSizeChange}
                name="medium"
              />
            }
            label="Medium (2,000 - 10,000)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.sizes.includes("large")}
                onChange={handleSizeChange}
                name="large"
              />
            }
            label="Large (> 10,000)"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default SizeFilter;
