import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { ChatGPTEntry } from "../../../shared/types/types";
import Message from "./Message";

type Props = {
  chatHistory: ChatGPTEntry[];
};

const ChatDisplay = ({ chatHistory }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatForDisplay = chatHistory.filter((chat) => chat.role !== "system");
  //remove the initial context prompt
  // chatForDisplay.shift();

  // Scroll to the bottom of the chat on update
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <>
      <Box
        ref={scrollRef}
        sx={{
          p: 1,
          height: "80VH",
          overflow: "scroll",
        }}
      >
        {chatForDisplay.map((entry, index) => (
          <Box key={`message_${index}`}>
            <Message index={index} entry={entry} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ChatDisplay;
