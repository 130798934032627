import { Box, Button, Typography } from "@mui/material";
import AlmaIcon from "../../../shared/assets/icons/AlmaIcon";
import animatedAlma from "../../../shared/assets/icons/animatedAlma.json";
import { QuestionRecord, StudentRecord } from "../../../shared/types/types";
import { Player } from "@lottiefiles/react-lottie-player";

type Props = {
  loggedInStudent: StudentRecord;
  children: React.ReactNode;
  question: QuestionRecord;
  onSubmit: () => void;
  onBack: () => void;
  backDisabled: boolean;
  nextDisabled: boolean;
  isLastQuestion?: boolean;
};

const QuestionWrapper = ({
  children,
  question,
  onSubmit,
  onBack,
  backDisabled,
  nextDisabled,
  isLastQuestion,
}: Props) => {
  const handleNext = () => {
    onSubmit();
  };
  const handleBack = () => {
    onBack();
  };
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", p: 2, alignItems: "center" }}>
          <Box sx={{ pr: 2, pt: 1, flexShrink: 0 }}>
            {/* <AlmaIcon size={30} /> */}
            {process.env.REACT_APP_DISABLE_LOTTIE === "true" ? (
              <AlmaIcon size={30} />
            ) : (
              <Player autoplay loop src={animatedAlma} style={{ height: "30px" }} />
            )}
          </Box>
          <Box>
            <Typography variant="h6">{question.questionText}</Typography>
            <Typography variant="body2">{question.helperText}</Typography>
            {question.questionType === "ms" && (
              <Typography variant="body1" sx={{fontSize: 14}}>
                Select your <b>top</b> and <b>second top</b> option that best describes you
              </Typography>
            )}
          </Box>
        </Box>
        {children}
      </Box>
      <Box sx={{ display: "flex", p: 2, gap: 2 }}>
        <Button
          disabled={backDisabled}
          onClick={handleBack}
          variant="contained"
          color="gray"
          fullWidth
        >
          Back
        </Button>
        <Button
          disabled={nextDisabled}
          variant="contained"
          color="primary"
          onClick={handleNext}
          fullWidth
        >
          {isLastQuestion ? "Submit" : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default QuestionWrapper;
