import { useCallback, useState } from "react";
import { DocumentData, Query } from "firebase/firestore";
import { LogEventType } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { CareerVideoRecord, StudentRecord } from "../../../shared/types/types";
import { fetchVideos } from "../../utils/videoUtils";

type Props = {
  loggedInStudent: StudentRecord;
  setHasMoreVideos: (value: boolean) => void;
  selectedIndustryIds: number[];
};

type HandleQueryProps = {
  q: Query;
  firstLoad: boolean;
  accumulatedVideos: CareerVideoRecord[];
};

const useVideoQuery = ({ loggedInStudent, setHasMoreVideos, selectedIndustryIds }: Props) => {
  const [videos, setVideos] = useState<CareerVideoRecord[]>([]);
  const [lastVideo, setLastVideo] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState(false);
  const { submitLog } = useLogger();

  const handleQuery = useCallback(
    async ({ q, firstLoad, accumulatedVideos }: HandleQueryProps) => {
      setLoading(true);
      try {
        const { videosWithUrls, lastVideo } = await fetchVideos({
          q,
          studentId: loggedInStudent.id,
          selectedIndustryIds,
          accumulatedVideos,
          firstLoad,
        });

        if (videosWithUrls.length === 0) {
          setHasMoreVideos(false);
        }

        setVideos((prev) => {
          const newVideos = firstLoad ? videosWithUrls : [...prev, ...videosWithUrls];
          const uniqueVideos = Array.from(new Set(newVideos.map((v) => v.id))).map((id) =>
            newVideos.find((v) => v.id === id)
          );
          return uniqueVideos as CareerVideoRecord[];
        });

        setLastVideo(lastVideo);

        if (!lastVideo || videosWithUrls.length < 10) {
          setHasMoreVideos(false);
        }

        submitLog({ eventType: LogEventType.GOT_VIDEO_FILES, file: "useVideoQuery.ts" });
      } catch (error) {
        submitLog({
          error: error,
          file: "useVideoQuery.ts",
          snackbarMessage: "Error getting videos, please refresh and try again.",
          eventType: LogEventType.GOT_VIDEO_FILES_ERROR,
        });
      } finally {
        setLoading(false);
      }
      setLoading(false);
    },
    [loggedInStudent.id, selectedIndustryIds, setHasMoreVideos, submitLog]
  );

  return { handleQuery, videos, lastVideo, loading, setVideos, setLastVideo };
};

export default useVideoQuery;
