import { useState, useCallback } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

interface SendRequestProps {
  col: string;
  data: {};
}

const useAddFSDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const addFSDoc = useCallback(async <T>({ col, data }: SendRequestProps): Promise<T> => {
    setIsLoading(true);
    setError(null);
    const now = new Date().toISOString();
    const finalData = { ...data, createdAt: now, lastUpdatedAt: now, active: true };
    const colRef = collection(db, col);
    const addedDoc = await addDoc(colRef, finalData);
    return { ...finalData, id: addedDoc.id } as T;
  }, []);
  return { isLoading, error, addFSDoc };
};

export default useAddFSDoc;
