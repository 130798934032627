import React from "react";
import { Dialog, DialogContent, Typography, Grid, Button, Chip, Box, Divider } from "@mui/material";
import { ScholarshipRecord } from "../../../shared/types/types";
import dayjs from "dayjs";
import { Color } from "../../../shared/types/enums";

type Props = {
  selectedScholarship: ScholarshipRecord | null;
  setSelectedScholarship: React.Dispatch<React.SetStateAction<ScholarshipRecord | null>>;
};

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0, // No decimals
  }).format(amount);
};

const formatDate = (dateString: string | null) => {
  return dateString ? dayjs(dateString).format("MM/DD/YYYY") : "Not Yet Set";
};

const formatAward = (minAward: number | null, maxAward: number | null) => {
  const awardAmount =
    minAward && maxAward
      ? `${formatCurrency(minAward)} - ${formatCurrency(maxAward)}`
      : minAward
      ? `${formatCurrency(minAward)}`
      : maxAward
      ? `${formatCurrency(maxAward)}`
      : null;
  return awardAmount || "Not Yet Set";
};

const CardChip = (props: { label: string }) => (
  <Chip label={props.label} color="lightGreen" sx={{ fontSize: 10, p: 0, height: 24 }} />
);

const ScholarshipDetailsDialog: React.FC<Props> = ({
  selectedScholarship,
  setSelectedScholarship,
}) => {
  if (!selectedScholarship) return null;

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogContent>
        <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {selectedScholarship.sponsorName && (
            <Typography variant="h6" sx={{ fontSize: 14, color: Color.MINT }}>
              {selectedScholarship.sponsorName}
            </Typography>
          )}
          <Typography variant="h4">{selectedScholarship.name}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 2 }}>
            {selectedScholarship.tags.length > 0 &&
              selectedScholarship.tags.map((tag) => <CardChip key={tag} label={tag} />)}
          </Box>
          <Box>
            <Typography variant="h6">Overview</Typography>

            {/* Description */}
            <Typography variant="body1" sx={{ mt: 1 }}>
              {selectedScholarship.description || "No description available"}
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">Eligibility Details</Typography>
            <Typography sx={{ mt: 1 }}>
              <strong>Eligibility GPA:</strong> {selectedScholarship.eligibilityGpa || "N/A"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {selectedScholarship.eligibilityDescription || "No eligibility details available"}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />

          {/* Dates */}
          <Box>
            <Typography variant="h6">Key Dates</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Open Date:</strong> {formatDate(selectedScholarship.startAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Deadline:</strong> {formatDate(selectedScholarship.endAt)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Deadline Description */}

          {selectedScholarship.deadlineDescription && (
            <Typography sx={{ mt: 2 }}>
              <strong>Deadline Description:</strong> {selectedScholarship.deadlineDescription}
            </Typography>
          )}

          <Divider sx={{ my: 2 }} />

          {/* Award Information */}
          <Typography variant="h6">Award Information</Typography>
          <Typography>
            <strong>Award:</strong>{" "}
            {formatAward(selectedScholarship.minAwardAmount, selectedScholarship.maxAwardAmount)}
          </Typography>
          {selectedScholarship.awardDescription && (
            <Typography sx={{ mt: 2 }}>
              <strong>Award Description:</strong> {selectedScholarship.awardDescription}
            </Typography>
          )}

          {/* Questions */}
          {selectedScholarship.questions.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Application Questions
              </Typography>
              {selectedScholarship.questions.map((q, index) => (
                <Box key={index} sx={{ mt: 1 }}>
                  <Typography>
                    <strong>Question {index + 1}:</strong> {q.question || "N/A"}
                  </Typography>
                  {q.questionDetails && (
                    <Typography>
                      <strong>Details:</strong> {q.questionDetails}
                    </Typography>
                  )}
                  <Typography>
                    <strong>Required:</strong> {q.isRequired ? "Yes" : "No"}
                  </Typography>
                </Box>
              ))}
            </>
          )}

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2} sx={{ mt: 2 }}>
            <Button
              onClick={() => setSelectedScholarship(null)}
              color="close"
              variant="outlined"
            >
              Close
            </Button>
            <Button
              onClick={() => window.open(selectedScholarship.submissionUrl, "_blank")}
              color="primary"
              variant="contained"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ScholarshipDetailsDialog;
