import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import useStudentTable from "../../hooks/dashboard/useStudentTable";
import useStudentTableColumns from "../../hooks/dashboard/useStudentTableColumns";
import { Box, Stack } from "@mui/material";
import StudentDashboardDialog from "./StudentDashboardDialog";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

const StudentTable = () => {
  const { onRowClick, studentRows, studentDialogOpen, setStudentDialogOpen } = useStudentTable();
  const { staffColumns } = useStudentTableColumns();
  return (
    <>
      {studentRows && (
        <Box sx={{ height: 800 }} id="app-manager-table">
          <Box
            sx={{
              display: "flex",
              height: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1, maxWidth: "100VW", height: "100%" }}>
              <DataGridPro
                rows={studentRows}
                columns={staffColumns}
                className="counselor-table"
                disableColumnMenu
                disableColumnResize
                onRowClick={(data: GridRowParams) => onRowClick(data.row)}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
                }
                getRowId={(row) => row.id}
                sx={{ fontSize: 12, border: "none", height: "100%" }}
                getRowHeight={() => "auto"}
                slots={{
                  noRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      No result found
                    </Stack>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <StudentDashboardDialog open={studentDialogOpen} setOpen={setStudentDialogOpen} />
    </>
  );
};

export default StudentTable;
