import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./shared/providers/AuthProvider";
import { UserProvider } from "./shared/providers/userProvider";
import * as Sentry from "@sentry/react";
console.log("The App Environment is", process.env.REACT_APP_ENV);

Sentry.init({
  dsn: "https://93335e81fb3db2e9f901865470c3dc00@o4506384235495424.ingest.us.sentry.io/4508168009220096",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Update 'tracePropagationTargets' to include both QA and production URLs
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/qa\.willowed\.org\/api/,
    /^https:\/\/app\.willowed\.org\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // 100% sampling when errors occur
  environment: process.env.REACT_APP_ENV || "development",
});

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <UserProvider>
          <AppBootstrap />
        </UserProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
