import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { Color } from "../../../../shared/types/enums";
import { Dispatch } from "react";
import { FilterProps } from "../../../../shared/types/types";
import tags from "../../../../shared/assets/data/tags.json";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const dropDownIcon = <KeyboardArrowDownOutlinedIcon />;

type Tags = {
  [key: string]: string[];
};

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const CareerPathwaysFilter = ({ filters, setFilters }: Props) => {
  const tagsArray = Object.keys(tags);
  const cipTags: Tags = tags as Tags;

  const handleTagsChange = (_: any, value: string[]) => {
    const tempCIPs: string[] = [];
    value.forEach((tag) => {
      tempCIPs.push(...cipTags[tag]);
    });
    setFilters((pV) => ({ ...pV, tags: value, cipCodes: tempCIPs }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          Career Pathways
        </Typography>
        <Autocomplete
          multiple
          id="career-pathways-filter"
          options={tagsArray}
          value={filters.tags}
          onChange={handleTagsChange}
          filterSelectedOptions
          popupIcon={dropDownIcon}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Type to search..."
              sx={{
                "& .MuiInputBase-root": {
                  alignItems: "start",
                  alignContent: "start",
                  height: "200px",
                  borderRadius: "8px",
                },
                "& .MuiAutocomplete-endAdornment": { top: "13%" },
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default CareerPathwaysFilter;
