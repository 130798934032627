import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { SearchFilter } from "./SearchFilter";
import { FilterProps, SearchedProvider } from "../../../../shared/types/types";
import { Color } from "../../../../shared/types/enums";
import SizeFilter from "./SizeFilter";
import CostFilter from "./CostFilter";
import FocusFilter from "./FocusFilter";
import ProgramTypeFilter from "./ProgramTypeFilter";
import CareerPathwaysFilter from "./CareerPathwaysFilter";
import StateAndCityFilter from "./StateAndCityFilter";

type Props = {
  isDialogMode: boolean;
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  handleResetButtonClick?: () => void;
  handleFilterButtonClick: () => void;
  handleCloseButtonClick?: () => void;
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<React.SetStateAction<SearchedProvider[]>>;
};

const ProgramFilterAndSearchBar = ({
  isDialogMode,
  filters,
  setFilters,
  handleResetButtonClick,
  handleFilterButtonClick,
  handleCloseButtonClick,
  searchedProviders,
  setSearchedProviders
}: Props) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderRadius: 2,
            background: Color.WHITE,
            px: 2,
            py: 1.5,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "19px",
              color: Color.DARK_GRAY,
            }}
          >
            Search within a specific college or organization
          </Typography>
          <SearchFilter
            searchedProviders={searchedProviders}
            setSearchedProviders={setSearchedProviders}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderRadius: 2,
            background: Color.WHITE,
            px: 2,
            py: 1.5,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <SizeFilter filters={filters} setFilters={setFilters} />
                <Divider sx={{ color: Color.BLACK, opacity: 12 }} />
                <StateAndCityFilter filters={filters} setFilters={setFilters} />
                <Divider sx={{ color: Color.BLACK, opacity: 12 }} />
                <CostFilter filters={filters} setFilters={setFilters} />
                <Divider sx={{ color: Color.BLACK, opacity: 12 }} />
                {/* <CollegeFilter filters={filters} setFilters={setFilters} /> */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <FocusFilter filters={filters} setFilters={setFilters} />
                <Divider sx={{ color: Color.BLACK, opacity: 12 }} />
                <ProgramTypeFilter filters={filters} setFilters={setFilters} />
                <Divider sx={{ color: Color.BLACK, opacity: 12 }} />
                <CareerPathwaysFilter filters={filters} setFilters={setFilters} />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 2, mt: 2 }}
          >
            {isDialogMode && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleResetButtonClick}
                sx={{ maxWidth: "100px" }}
              >
                Reset
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ flexGrow: 3 }}
              onClick={() => handleFilterButtonClick()}
            >
              Search
            </Button>
            {isDialogMode && (
              <Button
                variant="outlined"
                color="error"
                fullWidth
                sx={{ maxWidth: "100px" }}
                onClick={handleCloseButtonClick}
              >
                Close
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProgramFilterAndSearchBar;
