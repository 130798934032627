import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React, { ChangeEvent, Dispatch } from "react";
import { FilterProps } from "../../../../shared/types/types";
import { Color } from "../../../../shared/types/enums";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const ProgramTypeFilter = ({ filters, setFilters }: Props) => {
  const handleProgramTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const programTypeNumber = name === "certifications" ? 1 : name === "associates" ? 2 : 3;
    const programTypes = [...filters.programTypes];
    if (programTypes.includes(programTypeNumber)) {
      programTypes.splice(programTypes.indexOf(programTypeNumber), 1);
    } else {
      programTypes.push(programTypeNumber);
    }
    setFilters((pV) => ({ ...pV, programTypes }));
  };
  return (
    <>
      <FormControl
        sx={{ px: 2, py: 1 }}
        component="fieldset"
        variant="standard"
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          Program Type
        </Typography>
        <FormGroup sx={{ mt: 1, gap: 1.5 }}>
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.programTypes.includes(1)}
                onChange={handleProgramTypeChange}
                name="certifications"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="Certifications and Trainings (Usually < 1 year)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.programTypes.includes(2)}
                onChange={handleProgramTypeChange}
                name="associates"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="Associate's Degrees (usually 2 years)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.programTypes.includes(3)}
                onChange={handleProgramTypeChange}
                name="bachelors"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="Bachelor's Degrees"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ProgramTypeFilter;
