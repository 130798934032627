import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { getProfileImage } from "../../utils/imageUtils";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

interface Section {
  title: string;
  description: string;
}

const Introduction = ({ mobile, personalityType, onActionButtonClick }: Props) => {
  const [superpowerSections, setSuperpowerSections] = useState<Section[]>([]);
  const [areasToImproveSections, setAreasToImproveSections] = useState<Section[]>([]);

  useEffect(() => {
    const superpowerResult = parseText(personalityType.superpowers);
    setSuperpowerSections(superpowerResult);

    const areasToImproveResult = parseText(personalityType.areasToImprove);
    setAreasToImproveSections(areasToImproveResult);
  }, [personalityType.areasToImprove, personalityType.superpowers]);

  const parseText = (text: string) => {
    const matches = text.match(/[*-]\s*([^:]+):\s*([^*-]+)/g);

    if (matches) {
      const parsedTitleDescriptions = matches.map((match) => {
        const [, title, description] = match.match(/[*-]\s*([^:]+):\s*(.+)/) || [];
        return {
          title: title?.trim() || "",
          description: description?.trim() || "",
        };
      });

      return parsedTitleDescriptions;
    }

    return [];
  };

  const formatDescription = (text: string) => {
    const sentences = text.split("! ");
    if (sentences.length > 1) {
      const firstSentence = sentences.shift();
      return (
        <>
          <span style={{ display: "block", marginBottom: "16px" }}>{firstSentence}!</span>
          <span>{sentences.join("! ")}</span>
        </>
      );
    }
    return <span>{text}</span>;
  };

  const getArticle = (title: string) => {
    const vowels = ["a", "e", "i", "o", "u"];
    return vowels.includes(title[0].toLowerCase()) ? "an" : "a";
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Default to side by side
          justifyContent: "space-between",
          alignItems: "flex-start",
          "@media (max-width: 768px)": {
            flexDirection: "column", // Stack the items vertically when the screen is narrow
            alignItems: "center", // Center items horizontally when in column mode
          },
        }}
      >
        <Box
          id="intro-description"
          sx={{
            order: 1, // Default order
            "@media (max-width: 768px)": {
              order: 2, // Move below the image on smaller screens
            },
          }}
        >
          <Typography sx={{ mt: 2 }} variant="h3" color={Color.ORANGE_700}>
            {personalityType.title}
          </Typography>
          <Typography sx={{ mt: 2, fontWeight: 600 }} variant="h4" color={Color.ORANGE_700}>
            {QuizResultsOption.INTRODUCTION}
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
            {formatDescription(personalityType.shortDescription)}
          </Typography>
        </Box>
        <Box
          id="intro-image"
          sx={{
            textAlign: "center",
            mt: 2,
            order: 2, // Default order
            "@media (max-width: 768px)": {
              order: 1, // Move above the text on smaller screens
            },
          }}
        >
          <img
            style={{ minWidth: "350px", margin: "0 auto" }}
            src={getProfileImage(personalityType.title)}
            alt={personalityType.title}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
        <div style={{ marginTop: 1 }}>
          <FeatherIcon
            width="24px"
            height="24px"
            icon="target"
            strokeColor="none"
            fillColor={Color.ORANGE_700}
          />
        </div>
        <Box>
          <Typography variant="h5" color={Color.CHALKBOARD}>
            {`What Does It Mean to Be ${getArticle(personalityType.title)} ${
              personalityType.title
            }?`}
          </Typography>
          <Typography
            sx={{ mt: 1 }}
            variant="body1"
            fontSize={14}
            component="div"
            color={Color.CHALKBOARD}
          >
            <Markdown>{personalityType.whatItMeans}</Markdown>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
        <div style={{ marginTop: 1 }}>
          <FeatherIcon
            width="24px"
            height="24px"
            icon="star"
            strokeColor="none"
            fillColor={Color.ORANGE_700}
          />
        </div>
        <Box>
          <Typography variant="h5" color={Color.CHALKBOARD}>
            Your Superpowers
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
            {superpowerSections.map((superpowerSection, index) => (
              <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography
                      variant="h6"
                      fontSize={14}
                      component="span"
                      color={Color.CHALKBOARD}
                    >
                      {`${superpowerSection.title}: `}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body1"
                      fontSize={14}
                      component="span"
                      color={Color.CHALKBOARD}
                    >
                      {superpowerSection.description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
        <div style={{ marginTop: 1 }}>
          <FeatherIcon
            width="24px"
            height="24px"
            icon="trending-up"
            strokeColor={Color.ORANGE_700}
            fillColor="none"
          />
        </div>
        <Box>
          <Typography variant="h5" color={Color.CHALKBOARD}>
            Areas You Can Improve
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
            {areasToImproveSections.map((areasToImproveSection, index) => (
              <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography
                      variant="h6"
                      fontSize={14}
                      component="span"
                      color={Color.CHALKBOARD}
                    >
                      {`${areasToImproveSection.title}: `}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body1"
                      fontSize={14}
                      component="span"
                      color={Color.CHALKBOARD}
                    >
                      {areasToImproveSection.description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.INTRODUCTION}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default Introduction;
