import { Box, Paper, Typography } from "@mui/material";
import AlmaIcon from "../../../shared/assets/icons/AlmaIcon";
import { Player } from "@lottiefiles/react-lottie-player";
import animatedAlma from "../../../shared/assets/icons/animatedAlma.json";

const InfoBox = ({ message }: { message: string }) => {
  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ pr: 2, pt: 1, flexShrink: 0 }}>
          {process.env.REACT_APP_DISABLE_LOTTIE === "true" ? (
            <AlmaIcon size={30} />
          ) : (
            <Player autoplay loop src={animatedAlma} style={{ height: "30px" }} />
          )}
        </Box>
        <Box>
          <Typography variant="body1">{message}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default InfoBox;
