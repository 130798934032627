import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
// import { useRecoilValue } from "recoil";
// import {
//   loggedInStaffAtom,
//   loggedInProviderAtom,
//   loggedInStudentAtom,
//   userTypeAtom,
//   loggedInMentorAtom,
// } from "../../recoil/userAtoms";
import { useAuth } from "../../contexts/AuthContext";
// import { PageRoute } from "../../types/enums";

const useLoginContainer = () => {
  // const navigate = useNavigate();
  const { currentAuthUser } = useAuth();
  const { width } = useWindowDimensions();
  // const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  // const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  // const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  // const loggedInMentor = useRecoilValue(loggedInMentorAtom);
  // const userType = useRecoilValue(userTypeAtom);

  // useEffect(() => {
  //   if (!currentAuthUser || userLoading) return;
  //   if (!userType) {
  //     navigate(PageRoute.Student_Onboarding);
  //     return;
  //   }
  //   if (loggedInStaff) {
  //     navigate(PageRoute.Staff_Dashboard);
  //     return;
  //   }
  //   if (loggedInStudent) {
  //     navigate(PageRoute.Student_Dashboard);
  //     return;
  //   }
  //   if (loggedInProvider) {
  //     navigate(PageRoute.Provider_Dashboard);
  //     return;
  //   }
  //   if (loggedInMentor) {
  //     navigate(PageRoute.Mentor_Dashboard);
  //     return;
  //   }
  //   !loggedInStaff &&
  //     !loggedInStudent &&
  //     !loggedInProvider &&
  //     !loggedInMentor &&
  //     currentAuthUser &&
  //     navigate(PageRoute.Signup);
  // }, [currentAuthUser, userLoading]);
  return { mobile: width < 900, currentAuthUser };
};

export default useLoginContainer;
