import React, { Dispatch } from "react";
import { FilterProps } from "../../../../shared/types/types";
import { Box, FormControl, FormLabel, Slider, styled } from "@mui/material";
import { Color } from "../../../../shared/types/enums";

const CostSlider = styled(Slider)(({ theme }) => ({
  color: Color.MINT,
  height: 6,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: Color.MINT,
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: "none",
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -3,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: Color.GRAY_800,
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 6,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: Color.GREEN_700,
  },
}));

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const mapSliderValueToCost = (value: number) => {
  if (value <= 25) {
    return value * 400; // $0 - $10,000
  } else if (value <= 50) {
    return 10000 + (value - 25) * 1600; // $10,000 - $50,000
  } else if (value <= 75) {
    return 50000 + (value - 50) * 2000; // $50,000 - $100,000
  } else {
    return 100000 + (value - 75) * 6000; // $100,000 - $250,000
  }
};

const mapCostToSliderValue = (cost: number) => {
  if (cost <= 10000) {
    return cost / 400;
  } else if (cost <= 50000) {
    return 25 + (cost - 10000) / 1600;
  } else if (cost <= 100000) {
    return 50 + (cost - 50000) / 2000;
  } else {
    return 75 + (cost - 100000) / 6000;
  }
};

const CostFilter = ({ filters, setFilters }: Props) => {
  const handleCostChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;

    const newCost: [number, number] = [
      mapSliderValueToCost(newValue[0]),
      mapSliderValueToCost(newValue[1]) < 250000 ? mapSliderValueToCost(newValue[1]) : 999999999,
    ];

    setFilters((pV) => ({
      ...pV,
      cost: newCost,
    }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <FormControl fullWidth>
          <FormLabel
            component="legend"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: Color.DARK_GRAY,
            }}
          >
            Cost
          </FormLabel>
          <Box sx={{ mt: 4, px: 1.5 }}>
            <CostSlider
              value={[mapCostToSliderValue(filters.cost[0]), mapCostToSliderValue(filters.cost[1])]}
              onChange={handleCostChange}
              min={0}
              max={100}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `$${mapSliderValueToCost(value).toLocaleString()}`}
            />
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default CostFilter;
