import { ChangeEvent, useState } from "react";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { validatePassword } from "../../../shared/utils/validationUtils";

const PASSWORD_ERROR =
  "Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long.";
const CONFIRM_PASSWORD_ERROR = "Passwords do not match";

const useCreateAuthAccount = ({ email }: { email: string }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
  const { createEmailUser } = useAuth();
  const [userExistsError, setUserExistsError] = useState<string | null>(null);
  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
    setTouched((pV) => ({ ...pV, password: true }));
    const valid = validatePassword({ password: event.target.value.trim() });
    setPasswordError(valid ? null : PASSWORD_ERROR);
  };

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedPassword = event.target.value.trim();
    setConfirmPassword(trimmedPassword);
    setTouched((pV) => ({ ...pV, confirmPassword: true }));
    setConfirmPasswordError(trimmedPassword === password ? null : CONFIRM_PASSWORD_ERROR);
  };

  const handleCreateAuthUser = async () => {
    const result = (await createEmailUser({ email, password })) as any;
    setUserExistsError(result ? result : null);
  };
  return {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    userExistsError,
    touched,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleCreateAuthUser,
  };
};

export default useCreateAuthAccount;
