import { BottomNavigationAction, Paper, BottomNavigation } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { Color, PageRoute } from "../../../shared/types/enums";
import { TourSelector } from "../../../shared/types/tourEnums";

const StudentBottomMobileNavbar = () => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        sx={{ backgroundColor: Color.CHALKBOARD }}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          value={PageRoute.FEED}
          onClick={() => navigate(PageRoute.FEED)}
          data-tut={TourSelector.HOME_NAV}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="home"
              strokeColor={location.pathname === PageRoute.FEED ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.FEED}
          onClick={() => navigate(PageRoute.STUDENT_MY_LIST)}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="bookmark"
              strokeWidth="2"
              strokeColor={location.pathname === PageRoute.STUDENT_MY_LIST ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.EXPLORE_CAREERS}
          onClick={() => navigate(PageRoute.EXPLORE_CAREERS)}
          data-tut={TourSelector.CAREERS_NAV}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="careers"
              strokeColor={location.pathname === PageRoute.EXPLORE_CAREERS ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.EXPLORE_PROGRAMS}
          onClick={() => navigate(PageRoute.EXPLORE_PROGRAMS)}
          data-tut={TourSelector.PROGRAMS_NAV}
          icon={
            <FeatherIcon
              width="40px"
              icon="programs"
              strokeColor={location.pathname === PageRoute.EXPLORE_PROGRAMS ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.STUDENT_CREATE_PROFILE}
          onClick={() => navigate(PageRoute.STUDENT_ALMA)}
          data-tut={TourSelector.ALMA_NAV}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="ai"
              strokeColor={location.pathname === PageRoute.STUDENT_ALMA ? Color.MINT : Color.CHALK}
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default StudentBottomMobileNavbar;
