import { Theme, CSSObject } from "@mui/material/styles";
import { Color } from "../types/enums";

export const sidebarWidth = 221;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarWidth,
  backgroundColor: Color.CHALKBOARD,
  color: Color.CHALK,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: Color.CHALKBOARD,
  color: Color.CHALK,
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 0px)`,
  },
});
