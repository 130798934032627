import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import React, { ChangeEvent, Dispatch } from "react";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const ProgramTypeFilter = ({ filters, setFilters }: Props) => {
  const handleProgramTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const programTypeNumber = name === "certifications" ? 1 : name === "associates" ? 2 : 3;
    const programTypes = [...filters.programTypes];
    if (programTypes.includes(programTypeNumber)) {
      programTypes.splice(programTypes.indexOf(programTypeNumber), 1);
    } else {
      programTypes.push(programTypeNumber);
    }
    setFilters((pV) => ({ ...pV, programTypes }));
  };
  return (
    <>
      <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Program Type</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.programTypes.includes(1)}
                onChange={handleProgramTypeChange}
                name="certifications"
              />
            }
            label="Certifications and Trainings (Usually < 1 year)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.programTypes.includes(2)}
                onChange={handleProgramTypeChange}
                name="associates"
              />
            }
            label="Associate's Degrees (usually 2 years)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.programTypes.includes(3)}
                onChange={handleProgramTypeChange}
                name="bachelors"
              />
            }
            label="Bachelor's Degrees"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ProgramTypeFilter;
