import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import ResetPasswordDialog from "../../../shared/components/login/ResetPasswordDialog";
import CreateAuthAccount from "./CreateAuthAccount";
import useSignupContainer from "../../hooks/signup/useSignupContainer";

const SignupContainer = () => {
  const {
    email,
    backToLogin,
    loading,
    userExists,
    emailChecked,
    resetOpen,
    checkIfUserExists,
    setResetOpen,
    emailValid,
    setCleanedEmail,
  } = useSignupContainer();
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Typography textAlign={"center"} sx={{ color: Color.MINT }} variant="h2">
          Hello and Welcome to Willow 👋
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ color: Color.MINT, fontWeight: 300, mt: 2 }}
          variant="h4"
        >
          We're so excited to get you onboarded!
        </Typography>
        <Typography textAlign={"center"} sx={{ mt: 3 }} variant="h5">
          First, let's make sure you don't already have an account with us. What's your email?
        </Typography>
        <TextField
          fullWidth
          label="Email"
          sx={{ mt: 4 }}
          variant="outlined"
          type="email"
          value={email}
          id="email-textfield"
          onChange={setCleanedEmail}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {!emailChecked ? (
                <Button
                  disabled={!emailValid}
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={checkIfUserExists}
                >
                  Continue
                </Button>
              ) : (
                <>
                  {userExists ? (
                    <>
                      <Box>
                        <Typography textAlign={"center"} sx={{ mt: 0 }} variant="h5">
                          It looks like you already have an account with us. Click below to log in.
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={backToLogin}
                          >
                            Back to the Login Page
                          </Button>
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Typography textAlign={"center"} sx={{ mt: 0 }} variant="h5">
                          Wonderful, now let's select a password that you'll remember.
                        </Typography>
                        <CreateAuthAccount email={email} />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <ResetPasswordDialog open={resetOpen} setOpen={setResetOpen} />
    </>
  );
};

export default SignupContainer;
