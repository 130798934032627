import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import CareerUnlockSurveyForm from "./CareerUnlockSurveyForm";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: "unlock" | "update";
};

const CareerUnlockSurveyDialog = ({ open, setOpen, mode }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 1000 }}
      >
        <DialogTitle>
          {mode === "unlock"
            ? "Unlock Your Career and Program Matches"
            : "Update Your Recommendation Preferences"}
        </DialogTitle>
        <DialogContent>
          {loggedInStudent && (
            <CareerUnlockSurveyForm
              student={loggedInStudent}
              setDialogOpen={setOpen}
              mode={mode}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CareerUnlockSurveyDialog;
