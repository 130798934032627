import { useEffect, useState } from "react";
import { JobDetailsRecord } from "../../types/types";
import useGetFSDocs from "../db/useGetFSDocs";
import { Collection } from "../../types/enums";
import { parseJobsResponse } from "../../utils/parserUtils";
import useWindowDimensions from "../responsiveLayout/useWindowDimensions";
import { Color } from "../../../shared/types/enums";

const useProgramDetailsJobs = (cipCode: string | null) => {
  const [relatedJobs, setRelatedJobs] = useState<JobDetailsRecord[]>([]);
  const { getFSDocs } = useGetFSDocs();
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const relatedJobTableStyles = {
    container: {
      mt: mobile ? 1 : 1.5,
      borderRadius: "12px",
      border: "1px solid",
      borderColor: Color.GRAY_200,
      boxShadow: "none",
    },
    tableCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: mobile ? 12 : 14,
      fontWeight: 400,
      color: Color.DARK_TEAL,
      px: 1,
      py: 1.5,
    },
  };

  useEffect(() => {
    if (cipCode) {
      const getJobs = async () => {
        const results = await getFSDocs<JobDetailsRecord>({
          col: Collection.JOBS,
        });
        if (results) {
          const filteredResults = results.filter(job =>
            job.cip_codes.some(code => {
              const firstFourDigits = code.match(/\d/g)?.slice(0, 4).join('');
              return firstFourDigits === cipCode;
            })
          );
          setRelatedJobs(parseJobsResponse(filteredResults));
        }
      };
      getJobs();
    }
  }, [getFSDocs, cipCode]);

  return {
    relatedJobs,
    setRelatedJobs,
    relatedJobTableStyles
  };
};

export default useProgramDetailsJobs;