import { DataGridPro, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Avatar, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { StaffRecord } from "../../../shared/types/types";
import { Dispatch } from "react";
import { staffUsersAtom } from "../../../shared/recoil/userAtoms";
import { selectedSchoolIdAtom } from "../../recoil/selectedSchoolAtom";
// import { staffColumns } from "../../libraries/columns";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

type Props = {
  setManageStaffOpen: Dispatch<React.SetStateAction<boolean>>;
  setStaffForm: Dispatch<React.SetStateAction<StaffRecord>>;
};

const StaffTable = ({ setStaffForm, setManageStaffOpen }: Props) => {
  const staff = useRecoilValue(staffUsersAtom);
  const selectedSchoolId = useRecoilValue(selectedSchoolIdAtom);
  const filteredStaff = staff.filter(
    (staff) => staff.enrolled === true && staff.schoolId === selectedSchoolId
  );

  const staffColumns = [
    {
      field: "avatarURL",
      headerName: "",
      width: 80,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      renderCell: (params: GridRenderCellParams<StaffRecord>) => (
        <Avatar
          alt={params.row.firstName + " " + params.row.lastName}
          src={params.row.avatarURL ? params.row.avatarURL : ""}
          sx={{ width: 50, height: 50, mt: 1, mb: 1 }}
        />
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
  ];

  const onRowClick = (row: StaffRecord) => {
    setStaffForm(row);
    setManageStaffOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          height: filteredStaff.length > 5 ? 500 : filteredStaff.length * 100 + 165,
          width: "100%",
          mt: 1,
        }}
        id="app-manager-table"
      >
        <div
          style={{
            display: "flex",
            height: "calc(100% - 62px)",
          }}
        >
          <div style={{ flexGrow: 1, maxWidth: "100%" }}>
            <DataGridPro
              rows={filteredStaff}
              columns={staffColumns}
              rowHeight={60}
              onRowClick={(data: GridRowParams) => onRowClick(data.row)}
              getRowId={(row) => row.id}
              disableColumnMenu
              disableColumnResize
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
              }
              sx={{ fontSize: 12, border: "none", height: "100%" }}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default StaffTable;
