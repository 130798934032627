import { Dispatch, SetStateAction, useMemo, useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Link as MUILink,
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { Color } from "../../types/enums";
import ProgramStats from "./ProgramStats";
import Reviews from "./Reviews";
import { JobDetailsRecord, ProviderProgramRecord } from "../../types/types";
import { formatCredentialCard, formatCurrency } from "../../utils/formatUtils";
import WhatIsROIDialog from "../whatIsROI/WhatIsROIDialog";
import ProviderDetailsDialog from "../providerDetails/ProviderDetailsDialog";
import useProgramDetailsDialog from "../../hooks/programDetails/useProgramDetailsDialog";
import useProgramDetailsJobs from "../../hooks/programDetails/useProgramDetailsJobs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../recoil/exploreCareersAtoms";
import JobDetailsDialog from "../jobDetailsDialog/JobDetailsDialog";
import WhatIsThat from "../whatIsThat/WhatIsThat";
import { selectedProgramForRecommendationsAtom } from "../../../staff/recoil/programAtoms";
import { userTypeAtom } from "../../recoil/userAtoms";

type Props = {
  isEditable?: boolean;
  selectedProgram: ProviderProgramRecord;
  setSelectedProgram: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
};

const ProgramDetailsDialog = ({
  isEditable = true,
  selectedProgram,
  setSelectedProgram,
}: Props) => {
  const [providerDetailsOpen, setProviderDetailsOpen] = useState(false);

  const {
    openWhatIsROI,
    setWhatIsROIOpen,
    whatIsROIOpen,
    applyLink,
    reviews,
    programImage,
    loading,
  } = useProgramDetailsDialog({
    programId: selectedProgram.id,
  });

  const userType = useRecoilValue(userTypeAtom);

  const { relatedJobs, relatedJobTableStyles } = useProgramDetailsJobs(selectedProgram.cipCode);
  const setSelectedProgramForRecommendations = useSetRecoilState(
    selectedProgramForRecommendationsAtom
  );
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);

  const handleJobClick = (job: JobDetailsRecord) => {
    setSelectedJobId(job.id);
  };

  const InstitutionTootip = useMemo(
    () => ({
      title: "Return on Investment (ROI)",
      description: `The ROI (Return on Investment) shown for this program is actually the ROI for all of ${selectedProgram.providerName}, 
    not this specific program. This happens when there isn’t enough data available for us to calculate the 
    ROI for the program itself.`,
    }),
    [selectedProgram.providerName]
  );

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedProgramForRecommendations(selectedProgram);
    },
    [selectedProgram, setSelectedProgramForRecommendations]
  );

  return (
    <>
      {selectedProgram && (
        <>
          <>
            <Dialog open={true} fullWidth maxWidth="md">
              {loading ? ( // Show loading spinner if data is still loading
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 400,
                  }}
                >
                  <CircularProgress size={80} />
                </Box>
              ) : (
                <>
                  <DialogTitle>
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                      <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                        {selectedProgram.providerName}
                      </Typography>
                      <Box sx={{ textAlign: "center" }}>
                        <Button
                          onClick={() => setProviderDetailsOpen(true)}
                          sx={{ color: "#1DBC9D", textTransform: "none" }}
                        >
                          Learn more about&nbsp;
                          <span style={{ textDecoration: "underline" }}>
                            {selectedProgram.providerName}
                          </span>
                        </Button>
                      </Box>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <Container maxWidth="md">
                      <Typography sx={{ mt: 2 }} variant="h4">
                        {selectedProgram.programName}
                      </Typography>
                      <Chip
                        color={formatCredentialCard({
                          credential: selectedProgram.credentialLevel,
                        })}
                        label={selectedProgram.credentialLevel}
                        sx={{ fontSize: 11, ml: 1 }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {programImage ? (
                          <img
                            alt={selectedProgram?.programName ?? ""}
                            src={programImage}
                            width="50%"
                          />
                        ) : (
                          <Box sx={{ height: 400 }}></Box>
                        )}
                      </Box>
                      <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                        Overview
                      </Typography>
                      <Typography sx={{ mt: 2 }} variant="body1">
                        {selectedProgram.programShortDescription}
                      </Typography>
                      <Typography sx={{ mt: 2 }} variant="body1">
                        {selectedProgram.programDescription}
                      </Typography>
                      <ProgramStats currentProgram={selectedProgram} />

                      <Divider sx={{ mt: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#1DBC9D", mt: 2, fontSize: 30 }} variant="h5">
                          Return On Investment (ROI)
                        </Typography>
                        <MUILink
                          sx={{
                            color: Color.MINT,
                            textDecoration: "none",
                            fontStyle: "italic",
                            fontSize: 12,
                          }}
                          component="button"
                          onClick={openWhatIsROI}
                        >
                          Click To Learn More About ROI
                        </MUILink>
                        <Typography
                          variant="h3"
                          sx={{
                            mt: 3,
                            color: selectedProgram.programROI ? "inherit" : Color.STANDARD_GRAY,
                          }}
                        >
                          {selectedProgram.programROI
                            ? formatCurrency({ amount: selectedProgram.programROI })
                            : selectedProgram.providerROI
                            ? formatCurrency({ amount: selectedProgram.providerROI })
                            : "Not Assigned"}
                        </Typography>

                        {!selectedProgram.programROI ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pt: 1,
                            }}
                          >
                            <Typography sx={{ color: Color.STANDARD_GRAY }}>
                              (Institution)
                            </Typography>
                            <WhatIsThat information={InstitutionTootip} />
                          </Box>
                        ) : null}
                        <Typography textAlign="center" sx={{ mt: 2 }}>
                          This is how much more money you will make over 10 years by completing this
                          program than you would have made if you didn't complete it.
                        </Typography>
                      </Box>
                    </Container>
                    {reviews && (
                      <Box sx={{ mt: 2, backgroundColor: "rgb(0, 54, 46)", padding: 4 }}>
                        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: 30, color: Color.WHITE }}
                            variant="h6"
                          >
                            Hear From Students
                          </Typography>
                          {reviews.rating && reviews.rating > 0 && (
                            <>
                              <Rating
                                sx={{ borderColor: Color.CHALK }}
                                name="half-rating"
                                defaultValue={reviews.rating}
                                value={reviews.rating}
                                precision={0.5}
                                readOnly
                              />
                              <Typography sx={{ fontSize: 16, color: Color.CHALK }}>
                                {reviews.rating} out of 5 from{" "}
                                {reviews.source.length > 1 ? reviews.source : "Google"}
                              </Typography>
                            </>
                          )}
                          <Reviews reviews={reviews} />
                        </Container>
                      </Box>
                    )}
                    <Container maxWidth="sm" sx={{ textAlign: "center", pb: 4 }}>
                      <Typography sx={{ color: "#1DBC9D", fontSize: 30, mt: 4 }} variant="h5">
                        About The Provider
                      </Typography>
                      <Typography sx={{ mt: 2 }}>{selectedProgram.providerDescription}</Typography>
                    </Container>

                    {relatedJobs.length !== 0 && (
                      <Container maxWidth="md" sx={{ textAlign: "center", pb: 4 }}>
                        <Box sx={{ mt: 5 }}>
                          <Typography sx={{ mt: 2 }} variant="h4">
                            Aligned Future Careers
                          </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={relatedJobTableStyles.container}>
                          <Table>
                            <TableBody>
                              {relatedJobs.map((job) => (
                                <TableRow key={job.id}>
                                  <TableCell sx={relatedJobTableStyles.tableCell}>
                                    {job.title}
                                    <IconButton onClick={() => handleJobClick(job)}>
                                      <ArrowForwardIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Container>
                    )}
                  </DialogContent>
                  <DialogActions>
                    {userType !== "student" && (
                      <Button onClick={handleRecommendClick} variant="outlined">
                        Recommend To Students
                      </Button>
                    )}
                    {applyLink && isEditable && (
                      <Button component={Link} to={applyLink} variant="contained" target="_blank">
                        Apply to this program
                      </Button>
                    )}
                    <Button
                      color="error"
                      onClick={() => setSelectedProgram(null)}
                      variant="outlined"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
            <JobDetailsDialog />
            <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
            <ProviderDetailsDialog
              providerId={selectedProgram.providerId}
              open={providerDetailsOpen}
              setOpen={setProviderDetailsOpen}
            />
          </>
        </>
      )}
    </>
  );
};

export default ProgramDetailsDialog;
