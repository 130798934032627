import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { Color } from "../../../../shared/types/enums";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Dispatch, useState } from "react";
import { FilterProps } from "../../../../shared/types/types";
import cities from "../../../../shared/assets/data/cities.json";
import { states } from "../../../../shared/assets/data/states";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const StateAndCityFilter = ({ filters, setFilters }: Props) => {
  const [cityList, setCityList] = useState<string[]>(
    filters.states.map((state) => cities[state as keyof typeof cities]).flat()
  );

  const handleStatesChange = (_: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, states: value }));
    const citiesArray: string[] = [];
    value.forEach((state) => {
      citiesArray.push(...cities[state as keyof typeof cities]);
    });
    setCityList(citiesArray);
  };

  const handleCitiesChange = (_: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, cities: value }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          State & City
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Autocomplete
            multiple
            id="states-filter"
            options={states}
            value={filters.states}
            onChange={handleStatesChange}
            disableCloseOnSelect
            popupIcon={false}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} key={option}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search state"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            id="cities-filter"
            options={cityList}
            value={filters.cities}
            onChange={handleCitiesChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            disabled={cityList.length === 0}
            popupIcon={false}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} key={option}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search city"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default StateAndCityFilter;
