import { StudentRecord } from "../types/types";

type ProcessMessagesProps = {
  messages: any;
  selectedStudent: StudentRecord;
};

const processMessages = ({ messages, selectedStudent }: ProcessMessagesProps) => {
  if (!messages) return [];
  const studentPhoneJustNumbers = selectedStudent?.phone?.replace(/\D/g, "");
  return Object.keys(messages)
    .map((key) => ({
      ...messages[key],
      id: key,
    }))
    .filter(
      (message) =>
        message?.studentPhoneNumber && message.studentPhoneNumber.endsWith(studentPhoneJustNumbers)
    )
    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
};

type UpdateMessagesProps = {
  newMessages: any;
  existingMessages: any;
  selectedStudent: StudentRecord;
};

export const updateMessages = ({
  newMessages,
  existingMessages,
  selectedStudent,
}: UpdateMessagesProps) => {
  if (!newMessages) return existingMessages;
  const updatedMessages = processMessages({
    messages: newMessages,
    selectedStudent: selectedStudent,
  });
  const combinedMessages = [...existingMessages, ...updatedMessages];

  return combinedMessages.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );
};
