import { BrowserRouter as Router } from "react-router-dom";
import { SiteRoutes } from "./shared/routes/Routes";
import ErrorSnackbar from "./shared/components/errorHandling/ErrorSnackbar";
import { useRecoilValue } from "recoil";
import { userLoadingAtom } from "./shared/recoil/loadingAtoms";
import SiteLoadingPage from "./shared/pages/SiteLoadingPage";
import { Box } from "@mui/material";
import WillowTourProvider from "./shared/providers/WillowTourProvider";
import { stepsAtom, tourNameAtom } from "./shared/recoil/tourAtoms";
import SignupNavbar from "./students/components/navigation/SignupNavbar";
import { useAuth } from "./shared/contexts/AuthContext";

const NoUserBootstrap = () => {
  const steps = useRecoilValue(stepsAtom);
  const tourName = useRecoilValue(tourNameAtom);
  const userLoading = useRecoilValue(userLoadingAtom);
  const { currentAuthUser } = useAuth();

  return (
    <>
      <WillowTourProvider steps={steps} tour={tourName}>
        <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
          <>
            {!userLoading ? (
              <Router>
                <Box component="main" sx={{ flexGrow: 1 }}>
                  {currentAuthUser && <SignupNavbar />}
                  <SiteRoutes />
                </Box>
              </Router>
            ) : (
              <Box component="main" sx={{ flexGrow: 1 }}>
                <SiteLoadingPage />
              </Box>
            )}
            <ErrorSnackbar />
          </>
        </Box>
      </WillowTourProvider>
    </>
  );
};

export default NoUserBootstrap;
