import { useCallback, useEffect } from "react";
import { siteLoadingAtom, userLoadingAtom } from "../../shared/recoil/loadingAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, staffUsersAtom } from "../../shared/recoil/userAtoms";
import {
  ProviderProgramRecord,
  SchoolRecord,
  StaffRecord,
  StudentRecord,
  VideoInteractionRecord,
} from "../../shared/types/types";
import { Collection, FunctionName } from "../../shared/types/enums";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import {
  parseSchoolResponse,
  parseStaffResponse,
  parseStudentResponse,
  parseVideoInteractionResponse,
} from "../../shared/utils/parserUtils";
import { useAuth } from "../../shared/contexts/AuthContext";
import { allSchoolStudentsAtom } from "../recoil/studentsAtoms";
import { StaffDataContext } from "../contexts/StaffDataContext";
import { allSchoolsAtom } from "../../shared/recoil/schoolAndDistrictAtoms";
import { videoInteractionsAtom } from "../recoil/videoAtoms";
import { fetchData } from "../../shared/utils/fetchUtils";
import { bookmarkedProgramsAtom, recommendedProgramsAtom } from "../recoil/programAtoms";
import { selectedSchoolIdAtom } from "../recoil/selectedSchoolAtom";

type Props = {
  children: JSX.Element;
};

export const StaffDBProvider = ({ children }: Props) => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedSchoolId = useRecoilValue(selectedSchoolIdAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  const { getFSDocs } = useGetFSDocs();
  const userLoading = useRecoilValue(userLoadingAtom);
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom);
  const setAllSchools = useSetRecoilState(allSchoolsAtom);
  const setStaffUsers = useSetRecoilState(staffUsersAtom);
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);
  const setBookmarkedPrograms = useSetRecoilState(bookmarkedProgramsAtom);
  const currentAuthUser = useAuth();

  type DataLoaderPromises = [
    Promise<StudentRecord[]>,
    Promise<SchoolRecord[]>,
    Promise<StaffRecord[]>,
    Promise<VideoInteractionRecord[]>
  ];

  const getDataPromises = useCallback(() => {
    if (!selectedSchoolId) return [];
    const dataLoaderPromises: DataLoaderPromises = [
      getFSDocs<StudentRecord>({
        col: Collection.STUDENTS,
        config: { where: ["schoolId", "==", selectedSchoolId] },
      }),
      getFSDocs<SchoolRecord>({ col: Collection.SCHOOLS }),
      getFSDocs<StaffRecord>({ col: Collection.STAFF }),
      getFSDocs<VideoInteractionRecord>({
        col: Collection.VIDEO_INTERACTIONS,
        config: { where: ["schoolId", "==", selectedSchoolId] },
      }),
    ];

    return dataLoaderPromises;
  }, [getFSDocs, selectedSchoolId]);

  type StudentDataLoaderPromises = [Promise<Response>, Promise<Response>];

  const getStudentDataPromises = useCallback((students: StudentRecord[]) => {
    const recommendedProgramIds = Array.from(
      new Set(
        students
          .flatMap((student) => student.removedProgramIds)
          .filter((id): id is string => id != null)
      )
    );

    const bookmarkedProgramIds = Array.from(
      new Set(
        students
          .flatMap((student) => student.favoriteProgramIds)
          .filter((id): id is string => id != null)
      )
    );

    const studentDataLoaderPromises: StudentDataLoaderPromises = [
      fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: { documentIds: recommendedProgramIds },
      }),
      fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: { documentIds: bookmarkedProgramIds },
      }),
    ];
    return studentDataLoaderPromises;
  }, []);

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      if (userLoading) return;
      setSiteLoading(true);
      if (!currentAuthUser) {
        setSiteLoading(false);
      }
      if (!loggedInStaff) return;
      const dataLoaderPromises = getDataPromises();
      const [students, schools, staffUsers, videoInteractions] = await Promise.all(
        dataLoaderPromises
      );
      const studentDataLoaderPromises = getStudentDataPromises(students);
      const [recommendedPrograms, bookmarkedPrograms] = await Promise.all(
        studentDataLoaderPromises
      );
      setAllSchoolStudents(parseStudentResponse(students));
      setAllSchools(parseSchoolResponse(schools));
      setStaffUsers(parseStaffResponse(staffUsers));
      setVideoInteractions(parseVideoInteractionResponse(videoInteractions));
      const recommendedProgramsData = await recommendedPrograms.json();
      const bookmarkedProgramsData = await bookmarkedPrograms.json();
      setRecommendedPrograms(recommendedProgramsData.programs as ProviderProgramRecord[]);
      setBookmarkedPrograms(bookmarkedProgramsData.programs as ProviderProgramRecord[]);

      setSiteLoading(false);
    };
    loadData();
  }, [
    currentAuthUser,
    getDataPromises,
    loggedInStaff,
    setAllSchoolStudents,
    setAllSchools,
    setStaffUsers,
    setSiteLoading,
    userLoading,
    getStudentDataPromises,
    setVideoInteractions,
    setRecommendedPrograms,
    setBookmarkedPrograms,
  ]);

  return <StaffDataContext.Provider value={null}>{children}</StaffDataContext.Provider>;
};
