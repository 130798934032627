import { MouseEvent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import {
  ScholarshipApplicationRecord,
  ScholarshipRecord,
  StudentRecord,
} from "../../../shared/types/types";
import TopNavigationToggle from "./TopNavigationToggle";
import ExploreScholarshipsSidebar from "./ExploreScholarshipsSidebar";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection } from "../../../shared/types/enums";
import AllScholarships from "./AllScholarships";
import RecommendedScholarships from "./RecommendedScholarships";
import ScholarshipApplicationDialog from "./ScholarshipApplicationDialog";
import ScholarshipDetailsDialog from "./ScholarshipDetailsDialog";

type Props = {
  loggedInStudent: StudentRecord;
};

const ExploreScholarshipsContainer = ({ loggedInStudent }: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<"all" | "recommended">("all");
  const [allScholarships, setAllScholarships] = useState<ScholarshipRecord[]>([]);
  const [selectedScholarship, setSelectedScholarship] = useState<ScholarshipRecord | null>(null);
  const [recommendedScholarships, setRecommendedScholarships] = useState<ScholarshipRecord[]>([]);
  const [selectedScholarshipApplication, setSelectedScholarshipApplication] =
    useState<ScholarshipApplicationRecord | null>(null);
  const [scholarshipApplications, setScholarshipApplications] = useState<
    ScholarshipApplicationRecord[]
  >([]);
  const { width } = useWindowDimensions();
  const { getFSDocs } = useGetFSDocs();

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value && value !== selectedMenuItem) {
      setSelectedMenuItem(value as "all" | "recommended");
    }
  };

  useEffect(() => {
    const getScholarships = async () => {
      const scholarships = await getFSDocs<ScholarshipRecord>({ col: Collection.SCHOLARSHIPS });

      if (loggedInStudent.districtId) {
        const filteredScholarships = scholarships.filter((scholarship) =>
          scholarship.districtIds.includes(loggedInStudent.districtId!)
        );
        setRecommendedScholarships(filteredScholarships);
      } else {
        setRecommendedScholarships(scholarships);
      }
      setAllScholarships(scholarships);
    };

    getScholarships();
  }, [getFSDocs, loggedInStudent.districtId]);

  useEffect(() => {
    const getScholarshipApplications = async () => {
      const applications = await getFSDocs<ScholarshipApplicationRecord>({
        col: Collection.SCHOLARSHIP_APPLICATIONS,
        config: { where: ["studentId", "==", loggedInStudent.id] },
      });
      setScholarshipApplications(applications);
    };

    getScholarshipApplications();
  }, [getFSDocs, loggedInStudent.id]);

  const renderScholarships = () => {
    return selectedMenuItem === "all" ? (
      <AllScholarships
        scholarships={allScholarships}
        scholarshipApplications={scholarshipApplications}
        setScholarshipApplications={setScholarshipApplications}
        setSelectedScholarshipApplication={setSelectedScholarshipApplication}
        setSelectedScholarship={setSelectedScholarship}
      />
    ) : (
      <RecommendedScholarships
        scholarships={recommendedScholarships}
        scholarshipApplications={scholarshipApplications}
        setScholarshipApplications={setScholarshipApplications}
        setSelectedScholarshipApplication={setSelectedScholarshipApplication}
        setSelectedScholarship={setSelectedScholarship}
      />
    );
  };

  return (
    <>
      {width < 900 ? (
        <>
          <TopNavigationToggle selected={selectedMenuItem} handleSelected={handleSelected} />
          {renderScholarships()}
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          {/* Sidebar stays fixed */}
          <Box
            sx={{
              flex: "0 0 240px", // Width of the sidebar
              position: "sticky",
              top: 0,
              height: "100vh",
              overflowY: "auto", // Allow sidebar to scroll if content exceeds view height
              p: 2,
            }}
          >
            <ExploreScholarshipsSidebar
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
            />
          </Box>

          {/* Main content scrolls */}
          <Box
            sx={{
              flex: 1,
              height: "100vh",
              overflowY: "auto",
              p: 2,
            }}
          >
            {renderScholarships()}
          </Box>
        </Box>
      )}

      {/* Modals */}
      {selectedScholarshipApplication && (
        <ScholarshipApplicationDialog
          scholarship={allScholarships.find(
            (item) => item.id === selectedScholarshipApplication?.scholarshipId
          )}
          scholarshipApplication={selectedScholarshipApplication}
          setSelectedScholarshipApplication={setSelectedScholarshipApplication}
          setScholarshipApplications={setScholarshipApplications}
        />
      )}

      {selectedScholarship && (
        <ScholarshipDetailsDialog
          setSelectedScholarship={setSelectedScholarship}
          selectedScholarship={selectedScholarship}
        />
      )}
    </>
  );
};

export default ExploreScholarshipsContainer;
