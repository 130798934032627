import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
} from "@mui/material";
import ProgramFilters from "../../../students/components/explorePrograms/ProgramFilters";
import { FilterProps, ProviderProgramRecord, SearchedProvider } from "../../types/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgramCard from "../../../students/components/programCard/ProgramCard";

type Props = {
  setPrograms: React.Dispatch<React.SetStateAction<ProviderProgramRecord[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleQuery: () => void;
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  listRef: React.RefObject<HTMLDivElement> | null;
  programs: ProviderProgramRecord[];
  loading: boolean;
  handleFilterButtonClick: () => void;
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<React.SetStateAction<SearchedProvider[]>>;
};

const ExploreProgramsMobile = ({
  setPrograms,
  setLoading,
  handleQuery,
  filters,
  setFilters,
  programs,
  loading,
  handleFilterButtonClick,
  searchedProviders,
  setSearchedProviders,
}: Props) => {
  return (
    <>
      <Accordion sx={{ m: 0, p: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="starter questions"
          id="starter-questions"
        >
          Filters
        </AccordionSummary>
        <AccordionDetails sx={{ m: 0, p: 0 }}>
          <ProgramFilters
            setPrograms={setPrograms}
            setLoading={setLoading}
            handleQuery={handleQuery}
            filters={filters}
            setFilters={setFilters}
            handleFilterButtonClick={handleFilterButtonClick}
            searchedProviders={searchedProviders}
            setSearchedProviders={setSearchedProviders}
          />
        </AccordionDetails>
      </Accordion>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress size={100} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, height: "calc(100VH - 175px)", overflowY: "scroll", p: 1 }}>
          {programs.map((program) => (
            <ProgramCard key={program.id} program={program} />
          ))}
        </Box>
      )}
    </>
  );
};

export default ExploreProgramsMobile;
