import { Dispatch } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useEditMyWhyDialog from "../../hooks/profile/useEditMyWhyDialog";
import { Field, Form, Formik } from "formik";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const EditMyWhyDialog = ({ open, setOpen }: Props) => {
  const { loading, handleSave, handleClose, initialValues, validationSchema } = useEditMyWhyDialog({
    setOpen,
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h3"
        sx={{ textAlign: "center", backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}
      >
        Why is pursuing more education important to you?
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({ handleChange, handleBlur, isValid, errors, touched }) => (
          <Form>
            <DialogContent sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}>
              <Typography variant="body1">
                (e.g. find a great career, love of learning, make your family proud, etc.)
              </Typography>
              <Field
                as={TextField}
                name="myWhy"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.myWhy && Boolean(errors.myWhy)}
                helperText={touched.myWhy && errors.myWhy}
              />
            </DialogContent>
            <DialogActions sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}>
              <Button
                variant="contained"
                disabled={!isValid || loading}
                type="submit"
                startIcon={<FeatherIcon icon="thumbs-up" width="16px" />}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<FeatherIcon icon="x" width="16px" />}
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditMyWhyDialog;
