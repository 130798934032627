import { Container } from "@mui/material";
import SignupNavbar from "../components/navigation/SignupNavbar";
import SignUpContainer from "../components/signup/SignupContainer";

const StudentSignupPage = () => {
  return (
    <>
      <SignupNavbar />
      <Container maxWidth="sm" sx={{ pt: 8 }}>
        <SignUpContainer />
      </Container>
    </>
  );
};

export default StudentSignupPage;
