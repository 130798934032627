import { Grid } from "@mui/material";
import ROIOverview from "./ROIOverview";
import CompletionOverview from "./CompletionOverview";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import StageSummary from "./StageSummary";

const Overview = () => {
  return (
    <>
      <Grid sx={{ mt: 0 }} container spacing={1}>
        <Grid item xs={12} sm={12} md={5}>
          <DisplayBox title="Stages" height="200px">
            <StageSummary />
          </DisplayBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DisplayBox title="Average ROI" height="200px">
            <ROIOverview />
          </DisplayBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DisplayBox title="Avg Completion Rate" height="200px">
            <CompletionOverview />
          </DisplayBox>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
