import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { CareerVideoRecord, VideoInteractionRecord } from "../../../shared/types/types";
import VideoInteractions from "./VideoInteractions";

interface VideoPlayerProps {
  mobile: boolean;
  url: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  currentlyPlayingVideo: string | null;
  setCurrentlyPlayingVideo: Dispatch<SetStateAction<string | null>>;
  pauseOtherVideos: (currentSrc: string | null) => void;
  observe: (element: HTMLVideoElement) => void;
  unobserve: (element: HTMLVideoElement) => void;
  globalMute: boolean;
  setGlobalMute: Dispatch<SetStateAction<boolean>>;
  handleLearnMoreClick?: (video: CareerVideoRecord) => void;
  studentId?: string;
  showInteractionBar?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  mobile,
  url,
  studentId,
  video,
  interaction,
  observe,
  unobserve,
  globalMute,
  setGlobalMute,
  handleLearnMoreClick,
  showInteractionBar = true,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      observe(videoElement);
      videoElement.muted = globalMute;

      const handleVolumeChange = () => {
        if (!videoElement.muted) {
          setGlobalMute(false);
        }
      };

      videoElement.addEventListener("volumechange", handleVolumeChange);

      return () => {
        videoElement.removeEventListener("volumechange", handleVolumeChange);
        unobserve(videoElement);
      };
    }
  }, [globalMute, observe, setGlobalMute, unobserve]);

  return (
    <Box
      sx={{
        position: "relative",
        width: mobile ? "100%" : "450px",
        margin: "0 auto",
        pb: 3,
        borderBottom: 1,
        borderColor: Color.LIGHT_GRAY,
      }}
    >
      <video
        ref={videoRef}
        className="video-js"
        preload="auto"
        controls
        width={mobile ? "100%" : 450}
        height={mobile ? "100%" : 800}
        style={{ borderRadius: "10px" }}
      >
        <source src={url} type="video/mp4" />
      </video>
      {showInteractionBar && studentId && handleLearnMoreClick && (
        <VideoInteractions
          url={url}
          studentId={studentId}
          video={video}
          interaction={interaction}
          videoRef={videoRef}
          handleLearnMoreClick={handleLearnMoreClick}
        />
      )}
    </Box>
  );
};

export default VideoPlayer;
