import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AnswerRecord, QuestionRecord } from "../../shared/types/types";
import { Collection } from "../../shared/types/enums";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import { useAuth } from "../../shared/contexts/AuthContext";
import { answersAtom, questionsAtom } from "../recoil/quizAtoms";
import { parseAnswersResponse, parseQuestionsResponse } from "../../shared/utils/parserUtils";
import { QuizContext } from "../contexts/quizContext";

type Props = {
  children: JSX.Element;
};

export const QuizProvider = ({ children }: Props) => {
  const { getFSDocs } = useGetFSDocs();
  const { currentAuthUser } = useAuth();
  const setQuestions = useSetRecoilState(questionsAtom);
  const setAnswers = useSetRecoilState(answersAtom);
  const [loading, setLoading] = useState(true);

  const getDataPromises = useCallback(() => {
    if (!currentAuthUser) return [];
    const dataLoaderPromises: [Promise<QuestionRecord[]>, Promise<AnswerRecord[]>] = [
      getFSDocs<QuestionRecord>({
        col: Collection.QUESTIONS,
        config: { orderBy: ["order", "asc"] },
      }),
      getFSDocs<AnswerRecord>({
        col: Collection.ANSWERS,
        config: { where: ["studentId", "==", currentAuthUser.uid] },
      }),
    ];
    return dataLoaderPromises;
  }, [getFSDocs, currentAuthUser]);

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      const dataLoaderPromises = getDataPromises();
      const [questions, answers] = await Promise.all(dataLoaderPromises);
      setQuestions(parseQuestionsResponse(questions));
      setAnswers(parseAnswersResponse(answers));
      setLoading(false);
    };

    loadData();
  }, [getDataPromises, setAnswers, setQuestions]);

  return <QuizContext.Provider value={{ loading }}>{children}</QuizContext.Provider>;
};
