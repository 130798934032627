import { Box } from "@mui/material";
import AlmaContainer from "../components/alma/AlmaContainer";

const StaffAlmaPage = () => {
  return (
    <Box>
      <AlmaContainer />
    </Box>
  );
};

export default StaffAlmaPage;
