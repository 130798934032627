import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { ChatEntry } from "../../../shared/types/types";
import Message from "./Message";

type Props = {
  chatHistory: ChatEntry[];
};

const AlmaDisplay = ({ chatHistory }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatForDisplay = [...chatHistory];
  //remove the initial context prompt
  chatForDisplay.shift();

  // Scroll to the bottom of the chat on update
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatHistory]);


  return (
    <>
      <Box
        ref={scrollRef}
        sx={{
          p: 1,
          height: "80VH",
          overflow: "scroll",
        }}
      >
        {chatForDisplay.map((entry, index) => (
          <Message
            key={entry.timestamp}
            index={index}
            entry={entry}
          />
        ))}
      </Box>
    </>
  );
};

export default AlmaDisplay;
