import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "./shared/recoil/userAtoms";
import { UserType } from "./shared/types/enums";
import StudentBootstrap from "./StudentBootstrap";
import StaffBootstrap from "./StaffBootstrap";
import NoUserBootstrap from "./NoUserBootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AppBootstrap = () => {
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          {userType === UserType.STUDENT && <StudentBootstrap />}
          {userType === UserType.TEACHER && <StaffBootstrap />}
          {userType === null && <NoUserBootstrap />}
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default AppBootstrap;
