import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ProviderProgramRecord, StudentRecord } from "../../../shared/types/types";
import { Dispatch, SetStateAction, useCallback } from "react";
import useProgramList from "../../hooks/programList/useProgramList";
import { useSetRecoilState } from "recoil";
import { selectedProgramForRecommendationsAtom } from "../../recoil/programAtoms";
import { setMessageOpenAtom, setprogramMessageAtom } from "../../recoil/messagesAtoms";

type Props = {
  programId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: ProviderProgramRecord[];
  setSelectedProgramDetail: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  allEnrolledDistrictStudents: StudentRecord[];
};

const ProgramList = ({
  programId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
  setSelectedProgramDetail,
  allEnrolledDistrictStudents,
}: Props) => {
  const setSelectedProgramForRecommendations = useSetRecoilState(
    selectedProgramForRecommendationsAtom
  );
  const setMessageOpen = useSetRecoilState(setMessageOpenAtom);
  const setProgramMessage = useSetRecoilState(setprogramMessageAtom);

  const { currentProvider, studentsList, cleanedProgramName } = useProgramList({
    programId,
    favoriteOnlyToggle,
    fromAllSchoolsToggle,
    matchedPrograms,
    allEnrolledDistrictStudents,
  });

  const handleProgramDetails = useCallback(() => {
    if (currentProvider) {
      setSelectedProgramDetail(currentProvider);
    }
  }, [currentProvider, setSelectedProgramDetail]);

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const program = matchedPrograms.find((p) => p.id.toString() === programId.toString());
      setSelectedProgramForRecommendations(program as ProviderProgramRecord);
    },
    [programId, matchedPrograms, setSelectedProgramForRecommendations]
  );

  const handleMessageOpen = useCallback(() => {
    if (studentsList) {
      setProgramMessage(studentsList);
      setMessageOpen(true);
    }
  }, [studentsList, setMessageOpen, setProgramMessage]);

  return (
    <>
      {currentProvider && studentsList.length > 0 && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography sx={{ mt: 1 }} variant="h3">
                {cleanedProgramName}
              </Typography>
              <Typography variant="body1">{currentProvider.providerName}</Typography>
            </Grid>
            <Grid container item xs={5} spacing={2} justifyContent="right">
              <Grid item>
                <Button onClick={handleMessageOpen} variant="outlined">
                  Send Message
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleRecommendClick} variant="outlined">
                  Recommend To Students
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleProgramDetails} variant="contained">
                  Program Details
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1 }} />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {studentsList.map((student) => (
              <Grid item key={`${student.id}_${programId}`} xs={12} sm={6} md={4} lg={3}>
                <Box sx={{ p: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1">{`${student.firstName} ${student.lastName}`}</Typography>
                    {student.favoriteProgramIds.includes(programId) && (
                      <FavoriteIcon color="secondary" sx={{ fontSize: 12, ml: 1 }} />
                    )}
                  </Box>
                  <Typography sx={{ fontSize: 12 }} variant="body2">
                    {student.schoolEmail}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ProgramList;
