import React, { Dispatch } from "react";
import { FilterProps } from "../../../shared/types/types";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import tags from "../../../shared/assets/data/tags.json";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Tags = {
  [key: string]: string[];
};

type Props = {
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const TagsFilter = ({ setFilters }: Props) => {
  const tagsArray = Object.keys(tags);
  const cipTags: Tags = tags as Tags;
  const handleTagsChange = (_: any, value: string[]) => {
    const tempCIPs: string[] = [];
    value.forEach((tag) => {
      tempCIPs.push(...cipTags[tag]);
    });
    setFilters((pV) => ({ ...pV, cipCodes: tempCIPs }));
  };
  return (
    <>
      <Autocomplete
        multiple
        id="tags-filter"
        options={tagsArray}
        onChange={handleTagsChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li {...optionProps} key={option}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
      />
    </>
  );
};

export default TagsFilter;
