import Industries from "./Industries";
import Fields from "./Fields";
import Jobs from "./Jobs";
import Breadcrumb from "./Breadcrumb";
import { Box } from "@mui/material";
import useExploreCareeresRouter from "../../hooks/exploreCareers/useExploreCareeresRouter";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";

import { selectedFieldIdAtom, selectedIndustryIdAtom } from "../../recoil/exploreCareersAtoms";

const ExploreCareersRouter = () => {
  const { jobs } = useExploreCareeresRouter();
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const selectedIndustryId = useRecoilValue(selectedIndustryIdAtom);
  const selectedFieldId = useRecoilValue(selectedFieldIdAtom);

  return (
    <Box
      sx={{ height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 110px)", overflowY: "scroll" }}
    >
      <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
        {!selectedIndustryId ? (
          <Box>
            <Industries />
          </Box>
        ) : (
          <>
            {!selectedFieldId ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                <Breadcrumb jobs={jobs} />
                <Fields />
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                  <Breadcrumb jobs={jobs} />
                  <Jobs jobs={jobs} />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ExploreCareersRouter;
