import { Dispatch, SetStateAction } from "react";
import { QuestionRecord } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";

type Props = {
  question: QuestionRecord;
  picks: { topPick: string; secondPick: string };
  setPicks: Dispatch<SetStateAction<{ topPick: string; secondPick: string }>>;
};

const SelectBetweenQuestion = ({ question, picks, setPicks }: Props) => {
  const handleClick = (id: string) => {
    setPicks((prevPicks) => {
      if (prevPicks.topPick === id) {
        return { ...prevPicks, topPick: "" };
      } else {
        return { ...prevPicks, topPick: id };
      }
    });
  };
  return (
    <Box sx={{ px: 2 }}>
      {question.options.map((option) => (
        <Box
          key={option.optionText}
          onClick={() => handleClick(option.optionId)}
          sx={{
            borderRadius: 2,
            border: "1px solid #aaa",
            p: 2,
            mt: 2,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: option.optionId === picks.topPick ? Color.GREEN_500 : "white",
          }}
        >
          <Typography variant="body1">{option.optionText}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SelectBetweenQuestion;
