import { RefObject } from "react";
import { Box, Grid } from "@mui/material";
import QuizResultsContainer from "./QuizResultsContainer";
import QuizResultsSideNavigation from "./QuizResultsSideNavigation";
import useQuizResultsContainer from "../../hooks/quizResults/useQuizResultsContainer";

type Props = {
  dialogContentRef: RefObject<HTMLDivElement>;
};

const QuizResultsDesktop = ({ dialogContentRef }: Props) => {
  const { personalityType, selectedOption, setSelectedOption } = useQuizResultsContainer({ dialogContentRef });
  if (!personalityType) return null;
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <QuizResultsSideNavigation
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box sx={{ height: "70VH", overflow: "scroll" }}>
              <QuizResultsContainer
                personalityType={personalityType}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default QuizResultsDesktop;
