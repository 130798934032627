import { StudentRecord } from "../../../shared/types/types";
import { Avatar, Badge, Box, Button, Tooltip, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { useStudentPanelRow } from "../../hooks/messages/useStudentPanelRow";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatMessageTime } from "../../../shared/utils/formatUtils";

type Props = {
  student: StudentRecord;
};

const StudentPanelRow = ({ student }: Props) => {
  const { handleSelect, selectedStudent, notifications, hasPhone, lastMessageTimestamp } =
    useStudentPanelRow({
      student,
    });

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
        <Button
          onClick={handleSelect}
          variant="text"
          fullWidth
          disabled={!hasPhone}
          sx={{
            height: "59px",
            textTransform: "none",
            borderRadius: "8px",
            backgroundColor: selectedStudent?.id === student.id ? Color.LIGHT_GRAY : "#ffffff",
          }}
        >
          <Box
            sx={{
              p: 1,
              cursor: "pointer",
              display: "flex",
              gap: "6px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Badge badgeContent={notifications} color="secondary" sx={{ ml: 1 }}>
              <Avatar
                alt={student.firstName + " " + student.lastName}
                src={student.avatarURL}
                sx={{ width: 40, height: 40 }}
              />
            </Badge>
            <Typography
              sx={{
                width: "100%",
                textAlign: "left",
                fontSize: 12,
                fontWeight: selectedStudent?.id === student.id ? 700 : 500,
                color: "#263238",
              }}
              variant="body1"
            >
              {`${student.firstName} ${student.lastName}`}
            </Typography>
            {!hasPhone && (
              <Tooltip title="Missing phone number">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FeatherIcon width="16px" icon="phone-off" className="missing-phone" />
                </Box>
              </Tooltip>
            )}
            {hasPhone && (
              <Typography
                sx={{ fontSize: 12, fontWeight: 500, color: "#656767", whiteSpace: "nowrap" }}
                variant="body1"
              >
                {lastMessageTimestamp ? formatMessageTime({ timestamp: lastMessageTimestamp, shortTime: true }) : ""}
              </Typography>
            )}
          </Box>
        </Button>
      </Box>
    </>
  );
};

export default StudentPanelRow;
