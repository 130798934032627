import React, { useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Box, Chip } from "@mui/material";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import {
  ScholarshipApplicationRecord,
  ScholarshipRecord,
  ScholarshipStudentTableRow,
  StudentRecord,
} from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import { AvatarCell } from "../../components/scholarships/AvatarCell";

const useStudentTable = () => {
  const [rows, setRows] = useState<ScholarshipStudentTableRow[]>([]);
  const allStudents = useRecoilValue(allSchoolStudentsAtom);
  const { getFSDocs } = useGetFSDocs();

  const buildRow = ({
    scholarships,
    applications,
    students,
  }: {
    scholarships: ScholarshipRecord[];
    applications: ScholarshipApplicationRecord[];
    students: StudentRecord[];
  }) => {
    const rows: ScholarshipStudentTableRow[] = [];
    students.forEach((student) => {
      const studentApplications = applications.filter((app) => app.studentId === student.id);
      studentApplications.forEach((application) => {
        const scholarship = scholarships.find(
          (scholarship) => scholarship.id === application.scholarshipId
        );
        if (!scholarship) return;
        rows.push({
          id: application.id,
          scholarshipName: `${scholarship.name} - ${scholarship.sponsorName}`,
          studentId: student.id,
          studentName: `${student.firstName} ${student.lastName}`,
          submittedOn: application?.appliedDate || "",
          status: application.status,
          awardAmount: Number(application.awardAmount) || 0,
          hasApplications: true,
          applicationCount: 1, // Count of applications
          avatar: student.avatar ? student.avatar : "",
        });
      });
    });
    return rows;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tempscholarshipApplications, tempscholarships] = await Promise.all([
          getFSDocs<ScholarshipApplicationRecord>({
            col: Collection.SCHOLARSHIP_APPLICATIONS,
          }),
          getFSDocs<ScholarshipRecord>({
            col: Collection.SCHOLARSHIPS,
          }),
        ]);
        const tempRows = buildRow({
          scholarships: tempscholarships,
          applications: tempscholarshipApplications,
          students: allStudents,
        });

        setRows(tempRows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [allStudents, getFSDocs]);

  const columns: GridColDef[] = [
    {
      field: "studentId",
      headerName: "Student Name",
      width: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        // Only show avatars for student rows
        if (params.value) {
          const row = rows.find((row) => row.studentId === params.value);
          if (!row || !row.studentName) return null;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvatarCell avatar={row.avatar} studentName={row.studentName} />
              <span>{row.studentName}</span>
            </div>
          );
        }
        return params.row.studentName; // No avatar for scholarship rows
      },
      sortComparator: (v1, v2, param1, param2) => {
        const getName = (params: any) => {
          if (params.value) {
            const row = rows.find((row) => row.studentId === params.value);
            return row?.studentName || "";
          }
          return params.row.studentName || "";
        };

        const name1 = getName(param1).toLowerCase();
        const name2 = getName(param2).toLowerCase();

        return name1.localeCompare(name2);
      },
    },
    {
      field: "scholarshipName",
      headerName: "Scholarship Name",
      width: 200,
      renderCell: (params) => {
        // Check if params.value is defined, since it's used for row grouping
        if (params.value) {
          const [scholarshipName, sponsorName] = params.value.split(" - ");

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div style={{ lineHeight: 1.3 }}>{scholarshipName}</div>
              {sponsorName && (
                <div style={{ fontSize: "0.55rem", color: "#888", lineHeight: 1.3 }}>
                  {sponsorName}
                </div>
              )}
            </Box>
          );
        }
        return null; // Handle cases where there is no value
      },
    },
    {
      field: "applicationCount",
      headerName: "Total",
      minWidth: 40,
      type: "number",
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return params.value;
        } else {
          return null;
        }
      },
    },
    {
      field: "awardAmount",
      headerName: "Award Amount",
      minWidth: 80,
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return `$${params.formattedValue}`;
      },
    },
    {
      field: "submittedOn",
      headerName: "Submitted On",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Chip
            sx={{ fontSize: 10 }}
            label={params.value}
            color={
              params.value === "applied"
                ? "lightGreen"
                : params.value === "awarded"
                ? "primary"
                : params.value === "rejected"
                ? "lightPink"
                : "default"
            }
          />
        ) : (
          ""
        );
      },
    },
  ];
  return { rows, columns };
};

export default useStudentTable;
