import { Box } from "@mui/material";
import MyListContainer from "../components/myList/MyListContainer";
import { MyListProvider } from "../providers/MyListProvider";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";

const StudentListPage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>
      {loggedInStudent && (
        <MyListProvider loggedInStudent={loggedInStudent}>
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <MyListContainer loggedInStudent={loggedInStudent} />
          </Box>
        </MyListProvider>
      )}
    </>
  );
};

export default StudentListPage;
