import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

type Props = {
  fileName: string;
};

const useAvatarCell = ({ fileName }: Props) => {
  const [url, setUrl] = useState<string>();
  const storage = getStorage();
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);

  useEffect(() => {
    if (!fileName || fileName.length === 0) return;
    const getUrl = async () => {
      try {
        if (!fileName) return;
        const url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
        setSelectedStudent((pV) => (pV === null ? null : { ...pV, avatarURL: url }));
        setUrl(url);
      } catch (error) {
        console.error(`Error getting URL for filename ${fileName}`, error);
      }
    };
    getUrl();
  }, [fileName, setSelectedStudent, storage]);
  return { url };
};

export default useAvatarCell;