import graphic from "../../../shared/assets/icons/blank-list-image.svg";
import { Box, Typography } from "@mui/material";

const Empty = () => {
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={graphic} alt="empty list" />
      <Typography variant="h3" sx={{ mt: 2 }} align="center">
        You haven’t saved anything, yet
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Anything you liked in the “Programs”, “Careers”, or "Feed" tab will show up here.
      </Typography>
    </Box>
  );
};

export default Empty;
