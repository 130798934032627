export const convertToCSV = (arr: any[]) => {
    let csv = "";
    for (let row = 0; row < arr.length; row++) {
      let keysAmount = Object.keys(arr[row]).length;
      let keysCounter = 0;
  
      // If this is the first row, generate the headings
      if (row === 0) {
        // Loop each property of the object
        for (let key in arr[row]) {
          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");
          keysCounter++;
        }
      } else {
        for (let key in arr[row]) {
          csv += arr[row][key] + (keysCounter + 1 < keysAmount ? "," : "\r\n");
          keysCounter++;
        }
      }
  
      keysCounter = 0;
    }
    return csv;
  };