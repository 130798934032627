import { Box, MenuItem, MenuList, Typography } from "@mui/material";
import bookmarksGraphic from "../../../shared/assets/static/bookmarks-graphic.svg";
import { Color } from "../../../shared/types/enums";
type Props = {
  selectedMenuItem: "videos" | "careers" | "programs";
  setSelectedMenuItem: (selectedMenuItem: "videos" | "careers" | "programs") => void;
};

const MyListSidebar = ({ selectedMenuItem, setSelectedMenuItem }: Props) => {
  return (
    <>
      <Box sx={{ pt: 1 }}>
        <Typography variant="h4">Bookmarked Items</Typography>
        <Box
          sx={{
            pl: 2,
            height: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MenuList>
            <MenuItem
              selected={selectedMenuItem === "videos"}
              onClick={() => setSelectedMenuItem("videos")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "videos" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "videos" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              Bookmarked Videos
            </MenuItem>
            <MenuItem
              selected={selectedMenuItem === "programs"}
              onClick={() => setSelectedMenuItem("programs")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "programs" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "programs" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              Bookmarked Programs
            </MenuItem>
            <MenuItem
              selected={selectedMenuItem === "careers"}
              onClick={() => setSelectedMenuItem("careers")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "careers" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "careers" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              Bookmarked Careers
            </MenuItem>
          </MenuList>
          <Box>
            <img src={bookmarksGraphic} alt="bookmarks" width="100%" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyListSidebar;
