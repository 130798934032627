import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Color } from "../../types/enums";
import { ReviewRecord } from "../../types/types";

type Props = {
  reviews: ReviewRecord;
};

export default function Reviews({ reviews }: Props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<string[]>([]);
  const [maxSteps, setMaxSteps] = React.useState(0);

  React.useEffect(() => {
    if (!reviews) return;
    const tempSteps: string[] = [];
    reviews.reviewOne.length > 5 &&
      tempSteps.push(reviews.reviewOne.replace("%%", "--").replace("&&", "--"));
    reviews.reviewTwo.length > 5 &&
      tempSteps.push(reviews.reviewTwo.replace("%%", "--").replace("&&", "--"));
    reviews.reviewThree.length > 5 &&
      tempSteps.push(reviews.reviewThree.replace("%%", "--").replace("&&", "--"));
    reviews.reviewFour.length > 5 &&
      tempSteps.push(reviews.reviewFour.replace("%%", "--").replace("&&", "--"));

    setMaxSteps(tempSteps.length);
    setSteps(tempSteps);
  }, [reviews]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {steps.length > 0 && (
        <Box sx={{ flexGrow: 1, margin: "0 auto" }}>
          <Box sx={{ width: "100%", p: 2, minHeight: 120 }}>
            <Typography variant="body1" sx={{ fontSize: 14, color: Color.WHITE }}>
              {" "}
              {steps[activeStep]}
            </Typography>
          </Box>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              backgroundColor: "transparent",
            }}
            nextButton={
              <>
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                  sx={{ color: "#F4F0DC" }}
                >
                  {activeStep !== steps.length - 1 && (
                    <>
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft sx={{ color: "#F4F0DC" }} />
                      ) : (
                        <KeyboardArrowRight sx={{ color: "#F4F0DC" }} />
                      )}
                    </>
                  )}
                </Button>
              </>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ color: "#F4F0DC" }}
              >
                {activeStep !== 0 && (
                  <>
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight sx={{ color: "#F4F0DC" }} />
                    ) : (
                      <KeyboardArrowLeft sx={{ color: "#F4F0DC" }} />
                    )}
                    Back
                  </>
                )}
              </Button>
            }
          />
        </Box>
      )}
    </>
  );
}
