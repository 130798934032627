import { Grid } from "@mui/material";
import { ScholarshipApplicationRecord, ScholarshipRecord } from "../../../shared/types/types";
import ScholarshipCard from "./ScholarshipCard";

type Props = {
  scholarships: ScholarshipRecord[];
  scholarshipApplications: ScholarshipApplicationRecord[];
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
  setSelectedScholarshipApplication: React.Dispatch<
    React.SetStateAction<ScholarshipApplicationRecord | null>
  >;
  setSelectedScholarship: React.Dispatch<React.SetStateAction<ScholarshipRecord | null>>;
};
const RecommendedScholarships = ({
  scholarships,
  setScholarshipApplications,
  scholarshipApplications,
  setSelectedScholarshipApplication,
  setSelectedScholarship,
}: Props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ px: 2 }}>
        {scholarships.map((scholarship) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            key={scholarship.id}
            sx={{ display: "flex", flexDirection: "column" }} // Make grid items flex containers
          >
            <ScholarshipCard
              scholarship={scholarship}
              scholarshipApplications={scholarshipApplications}
              setScholarshipApplications={setScholarshipApplications}
              setSelectedScholarshipApplication={setSelectedScholarshipApplication}
              setSelectedScholarship={setSelectedScholarship}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default RecommendedScholarships;
