import { selectedSchoolIdAtom } from "../../recoil/selectedSchoolAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MenuItem, Select } from "@mui/material";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";

const StaffSchoolSelect = () => {
  const [selectedSchoolId, setSelectedSchoolId] = useRecoilState(selectedSchoolIdAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const allSchools = useRecoilValue(allSchoolsAtom);
  const filteredSchools =
    loggedInStaff?.email.split("@")[1] === "willowed.org" &&
    loggedInStaff.email !== "demo-staff@willowed.org"
      ? allSchools
      : allSchools.filter((school) => school.districtId === loggedInStaff?.districtId);
  return (
    <Select
      value={selectedSchoolId}
      onChange={(e) => setSelectedSchoolId(e.target.value as string)}
    >
      {filteredSchools.map((school) => (
        <MenuItem key={school.id} value={school.id}>
          {school.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StaffSchoolSelect;
