import { useState } from "react";
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";
import UploadScholarshipDialog from "./UploadScholarshipsDialog";
import useStudentTable from "../../hooks/scholarships/useStudentTable";
import StudentPieCharts from "./StudentPieCharts";

const StudentTable = () => {
  const apiRef = useGridApiRef();
  const [uploadOpen, setUploadOpen] = useState(false);
  const { rows, columns } = useStudentTable();

  return (
    <>
      <Box sx={{ height: 600, width: "100%" }}>
        <style>{`
          .MuiDataGrid-aggregationColumnHeaderLabel {
            display: none !important;
          }
        `}</style>
        <DataGridPremium
          columns={columns}
          rows={rows}
          rowHeight={60}
          apiRef={apiRef}
          isRowSelectable={() => false}
          initialState={useKeepGroupedColumnsHidden({
            apiRef,
            initialState: {
              rowGrouping: {
                model: ["studentId"],
              },
              aggregation: {
                model: {
                  awardAmount: "sum",
                  applicationCount: "sum",
                },
              },
            },
          })}
          groupingColDef={{
            hideDescendantCount: true,
          }}
          getRowId={(row) => row.id}
        />
      </Box>
      <StudentPieCharts rows={rows} />

      <UploadScholarshipDialog open={uploadOpen} setOpen={setUploadOpen} />
    </>
  );
};

export default StudentTable;
