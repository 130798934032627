import React from "react";
import useVideoInteractions from "../../hooks/video/useVideoInteractions";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { Color, REACTION_COLOR } from "../../../shared/types/enums";
import onetMap from "../../../shared/assets/data/onetTitlesMap.json";
import { CareerVideoRecord, VideoInteractionRecord } from "../../types/types";

type Props = {
  url: string;
  studentId: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleLearnMoreClick: (video: CareerVideoRecord) => void;
};

const VideoInteractions = ({
  url,
  studentId,
  video,
  interaction,
  videoRef,
  handleLearnMoreClick,
}: Props) => {
  const { videoInteraction, handleLikeClick, handleDislikeClick } = useVideoInteractions({
    url,
    studentId,
    video,
    interaction,
    videoRef,
  });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="h5" fontSize={14} color={Color.CHARCOAL}>
            {onetMap[video.onet as keyof typeof onetMap]}
          </Typography>
          <Box sx={{ display: "flex", gap: 0 }}>
            {videoInteraction?.interaction !== "liked" && (
              <IconButton
                sx={{ p: 0, pt: 1, width: 40, height: 40 }}
                size="medium"
                onClick={handleDislikeClick}
                aria-label="dislike button"
              >
                <FeatherIcon
                  icon="thumb-down"
                  width="24px"
                  height="24px"
                  strokeColor={REACTION_COLOR.STROKE}
                  fillColor={
                    videoInteraction?.interaction === "disliked"
                      ? REACTION_COLOR.DISLIKE
                      : "transparent"
                  }
                />
              </IconButton>
            )}
            {videoInteraction?.interaction !== "disliked" && (
              <IconButton
                sx={{ p: 0, width: 40, height: 40 }}
                size="medium"
                onClick={handleLikeClick}
                aria-label="heart button"
              >
                <FeatherIcon
                  icon="bookmark"
                  width="24px"
                  height="24px"
                  strokeColor={REACTION_COLOR.STROKE}
                  fillColor={
                    videoInteraction?.interaction === "liked"
                      ? REACTION_COLOR.BOOKMARK
                      : "transparent"
                  }
                />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            aria-label="Learn More About This Career"
            sx={{
              fontSize: 12,
              color: "#fff",
              backgroundColor: Color.MINT,
              boxShadow: "0px 2px 8px -1px #10182814",
              ":hover": {
                boxShadow: "0px 2px 8px -1px #10182814",
              },
            }}
            onClick={() => handleLearnMoreClick(video)}
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default VideoInteractions;
