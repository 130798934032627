import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { useState } from "react";
import { ProviderProgramRecord } from "../../../shared/types/types";
import ProgramList from "./ProgramList";
import ProviderList from "./ProviderList";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import useProgramListContainer from "../../hooks/programList/useProgramListContainer";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import RecommendProgramDialog from "../programCard/RecommendProgramDialog";
import MessageStudentsDialog from "../messages/MessageStudentsDialog";
import { useRecoilState, useRecoilValue } from "recoil";
import { setMessageOpenAtom, setprogramMessageAtom } from "../../recoil/messagesAtoms";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";

enum SortOption {
  Program = "program",
  Provider = "provider",
}

const ProgramListContainer = () => {
  const {
    loading,
    favoriteOnlyToggle,
    availableFromAllSchoolsToggle,
    fromAllSchoolsToggle,
    setFromAllSchoolsToggle,
    studentsAllForExport,
    sortBy,
    setSortBy,
    uniqueMatchedIds,
    uniqueProviderIds,
    matchedPrograms,
    allEnrolledDistrictStudents,
  } = useProgramListContainer();
  const { width } = useWindowDimensions();
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecord | null>(
    null
  );
  const [messageOpen, setMessageOpen] = useRecoilState(setMessageOpenAtom);
  const programMessage = useRecoilValue(setprogramMessageAtom)

  return (
    <>
      <Grid container spacing={0} sx={{ mt: 3, px: 2 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              Sort Students By:
            </Typography>
            <FormControl>
              <RadioGroup
                defaultValue={SortOption.Program}
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
              >
                <FormControlLabel value={SortOption.Program} control={<Radio />} label="Program" />
                <FormControlLabel
                  value={SortOption.Provider}
                  control={<Radio />}
                  label="Provider"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          {availableFromAllSchoolsToggle && (
            <FormControlLabel
              control={
                <Switch
                  checked={fromAllSchoolsToggle}
                  onChange={() => setFromAllSchoolsToggle((pV) => !pV)}
                />
              }
              label="Students from all schools"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ display: "flex", justifyContent: width < 900 ? "left" : "right" }}>
            <Button variant="contained" sx={{ p: 1 }}>
              <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(studentsAllForExport)}`}
                download={`student-program-matches.csv`}
                style={{ textDecoration: "none", color: Color.CHALK }}
              >
                Export all Data
              </a>
            </Button>
          </Box>
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ p: 2 }}>
          <LoadingBlock />
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          {sortBy === SortOption.Program &&
            uniqueMatchedIds.map((id) => (
              <ProgramList
                key={id}
                programId={id}
                favoriteOnlyToggle={favoriteOnlyToggle}
                fromAllSchoolsToggle={fromAllSchoolsToggle}
                matchedPrograms={matchedPrograms}
                setSelectedProgramDetail={setSelectedProgramDetail}
                allEnrolledDistrictStudents={allEnrolledDistrictStudents}
              />
            ))}
          {sortBy === SortOption.Provider &&
            uniqueProviderIds.map((id) => (
              <ProviderList
                key={id}
                providerId={id}
                favoriteOnlyToggle={favoriteOnlyToggle}
                fromAllSchoolsToggle={fromAllSchoolsToggle}
                matchedPrograms={matchedPrograms}
                allEnrolledDistrictStudents={allEnrolledDistrictStudents}
              />
            ))}
        </Box>
      )}
      {selectedProgramDetail && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgramDetail}
          setSelectedProgram={setSelectedProgramDetail}
        />
      )}
      <RecommendProgramDialog />
      <MessageStudentsDialog open={messageOpen} setOpen={setMessageOpen} students={programMessage} />
    </>
  );
};

export default ProgramListContainer;
