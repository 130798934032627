import { Typography } from "@mui/material";
import { useState } from "react";
import { Color } from "../../../shared/types/enums";

function truncateAndExpand(
  text: string,
  maxLength: number = 95
): { truncatedText: string; isTruncated: boolean } {
  if (text.length <= maxLength) {
    return { truncatedText: text, isTruncated: false };
  }

  const truncatedText = text.substring(0, maxLength) + "...";
  return { truncatedText, isTruncated: true };
}

type Props = {
  description: string;
};

const ProgramDescription = ({ description }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { truncatedText, isTruncated } = truncateAndExpand(description);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {/* Conditionally show either truncated or full text */}
      {isExpanded ? description : truncatedText}

      {/* Show "Show More" only if the text was truncated */}
      {isTruncated && (
        <Typography
          sx={{ fontWeight: 600, color: Color.MINT, cursor: "pointer" }}
          component="span"
          onClick={toggleExpand}
        >
          {isExpanded ? " Show Less" : " Show More"}
        </Typography>
      )}
    </>
  );
};

export default ProgramDescription;
