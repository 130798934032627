export function sliderValueText(value: number) {
  if (value < 250000) {
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0, // Remove decimal places
    }).format(value);
    return formatted;
  } else {
    return "$250k+";
  }
}
