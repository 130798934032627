import { atom } from "recoil";
import { StudentRecord } from "../../shared/types/types";

export const setMessageOpenAtom = atom<boolean>({
  key: "setMessageOpenAtom",
  default: false,
});

export const setprogramMessageAtom = atom<StudentRecord[]>({
  key: "setprogramMessageAtom",
  default: [],
});


