import ExploreCareersContainer from "../components/exploreCareers/ExploreCareersContainer";

const StaffExploreCareersPage = () => {
  return (
    <>
      <ExploreCareersContainer />
    </>
  );
};

export default StaffExploreCareersPage;
