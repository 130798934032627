import { MouseEvent, useState } from "react";
import { Box, Grid } from "@mui/material";
import ExploreCareersSidebar from "./ExploreCareersSidebar";
import ExploreCareersRouter from "../../../shared/components/exploreCareers/ExploreCareersRouter";
import RecommendedCareers from "./RecommendedCareers";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import TopNavigationToggle from "./TopNavigationToggle";
import JobDetailsDialog from "../jobDetailsDialog/JobDetailsDialog";

const ExploreCareersContainer = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<"all" | "recommended">("all");
  const { width } = useWindowDimensions();

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === selectedMenuItem || value === null) return;
    setSelectedMenuItem(value as "all" | "recommended");
  };
  return (
    <>
      {width < 900 ? (
        <>
          <TopNavigationToggle selected={selectedMenuItem} handleSelected={handleSelected} />
          {selectedMenuItem === "all" ? <ExploreCareersRouter /> : <RecommendedCareers />}
        </>
      ) : (
        <Box sx={{ p: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <ExploreCareersSidebar
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              {selectedMenuItem === "all" ? <ExploreCareersRouter /> : <RecommendedCareers />}
            </Grid>
          </Grid>
        </Box>
      )}
      <JobDetailsDialog />
    </>
  );
};

export default ExploreCareersContainer;
