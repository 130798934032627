import { atom } from "recoil";

export const selectedIndustryIdAtom = atom<number | null>({
  key: "selectedIndustryIdAtom",
  default: null,
});

export const selectedFieldIdAtom = atom<number | null>({
  key: "selectedFieldIdAtom",
  default: null,
});

export const selectedJobIdAtom = atom<string | null>({
  key: "selectedJobIdAtom",
  default: null,
});
