import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { MyListContext } from "../contexts/myListContext";
import {
  CareerVideoRecord,
  JobDetailsRecord,
  ProviderProgramRecord,
  StudentRecord,
} from "../../shared/types/types";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import { Collection, FunctionName } from "../../shared/types/enums";
import { fetchData } from "../../shared/utils/fetchUtils";
import { videoInteractionsAtom } from "../recoil/videoInteractionAtoms";

type Props = {
  children: JSX.Element;
  loggedInStudent: StudentRecord;
};

export const MyListProvider = ({ children, loggedInStudent }: Props) => {
  const [loading, setLoading] = useState(true);
  const [jobLoading, setJobLoading] = useState(true);
  const [programLoading, setProgramLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [likedVideos, setLikedVideos] = useState<CareerVideoRecord[]>([]);
  const { getFSDocs } = useGetFSDocs();
  const db = getFirestore();
  const [jobs, setJobs] = useState<JobDetailsRecord[]>([]);
  const [programs, setPrograms] = useState<ProviderProgramRecord[]>([]);
  const interactions = useRecoilValue(videoInteractionsAtom);

  useEffect(() => {
    if (!jobLoading && !programLoading && !videoLoading) {
      setLoading(false);
    }
  }, [jobLoading, programLoading, videoLoading]);

  useEffect(() => {
    const favoriteProgramIds = loggedInStudent.favoriteProgramIds;
    const getProgramData = async () => {
      const results = await fetchData({
        functionName: "getProgramsAndProvidersById",
        payload: { documentIds: favoriteProgramIds },
      });
      const programs = await results.json();
      setPrograms(programs.programs);
      setProgramLoading(false);
    };
    getProgramData();
  }, [db, loggedInStudent]);

  useEffect(() => {
    const favoriteJobs = loggedInStudent.favoriteJobIds;
    const getJobData = async () => {
      const batchSize = 10;
      const jobBatches = [];

      for (let i = 0; i < favoriteJobs.length; i += batchSize) {
        const batch = favoriteJobs.slice(i, i + batchSize);
        const q = query(collection(db, Collection.JOBS), where("__name__", "in", batch));
        const querySnapshot = await getDocs(q);
        const jobSnapshots = querySnapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as JobDetailsRecord)
        );
        jobBatches.push(...jobSnapshots);
      }
      setJobs(jobBatches);
      setJobLoading(false);
    };
    getJobData();
  }, [db, loggedInStudent]);

  useEffect(() => {
    const getVideos = async () => {
      const likedVideoIds = interactions
        .filter((interaction) => interaction.interaction === "liked")
        .map((interaction) => interaction.videoId);

      // Fetch liked videos in batches
      const batchSize = 10;
      const videosBatches = [];

      for (let i = 0; i < likedVideoIds.length; i += batchSize) {
        const batch = likedVideoIds.slice(i, i + batchSize);
        const q = query(collection(db, Collection.CAREER_VIDEOS), where("__name__", "in", batch));
        const querySnapshot = await getDocs(q);
        const videos = querySnapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as CareerVideoRecord)
        );
        videosBatches.push(...videos);
      }

      const fileNames = videosBatches.map((doc) => doc.fileName);
      // Fetch the URLs for the video files
      const results = await fetchData({
        functionName: FunctionName.LIST_FILES,
        payload: { fileNames },
      });

      // Extract the URLs from the response
      const { urls } = await results.json();
      // Update the video URLs state
      const videosWithUrls = videosBatches.map((video, i) => {
        return { ...video, url: urls[i] };
      });
      setLikedVideos(videosWithUrls);

      setVideoLoading(false);
    };

    getVideos();
  }, [getFSDocs, loggedInStudent, db, interactions]);
  return (
    <MyListContext.Provider value={{ loading, likedVideos, interactions, jobs, programs }}>
      {children}
    </MyListContext.Provider>
  );
};
