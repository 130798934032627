import { useRef } from "react";
import { Box, Grid } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import useAlmaContainer from "../../hooks/alma/useAlmaContainer";
import AlmaSidebar from "./AlmaSidebar";
import AlmaDisplay from "./AlmaDisplay";
import AlmaInput from "./AlmaInput";

const AlmaContainer = () => {
  const lottieRef = useRef<any>(null);

  const { handleSubmit, chatHistory, isLoading, error, resetChat, message, setMessage } =
    useAlmaContainer();

  return (
    <>
      <Grid container spacing={0} columns={12}>
        <Grid item sm={4} order={{ xs: 2, sm: 2, md: 1 }}>
          <AlmaSidebar handleSubmit={handleSubmit} resetChat={resetChat} />
        </Grid>
        <Grid item sm={8} order={{ xs: 1, sm: 1, md: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "90VH",
              justifyContent: "space-between",
            }}
          >
            <AlmaDisplay chatHistory={chatHistory} />
            <Box>
              <Box sx={{ display: "flex", justifyContent: "end", mr: 2 }}>
                <Box
                  style={{
                    display: isLoading ? "block" : "none",
                    width: "60px",
                  }}
                >
                  <Player
                    ref={lottieRef}
                    loop={true}
                    autoplay={true}
                    src="https://lottie.host/ae406bb9-3048-4976-a031-9675b58e0149/rVjOgw3dRk.json"
                  ></Player>
                </Box>
              </Box>
              <AlmaInput onSubmit={handleSubmit} message={message} setMessage={setMessage} />
              {error && <div className="error">{error}</div>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AlmaContainer;
