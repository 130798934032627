import { Box } from "@mui/material";
import AutoAdmitMessage from "./AutoAdmitMessage";
import { ProviderProgramRecord } from "../../../shared/types/types";
import useAutoAdmit from "../../hooks/programCard/useAutoAdmit";

type Props = {
  children: JSX.Element | JSX.Element[];
  program: ProviderProgramRecord;
};

const AutoAdmit = ({ children, program }: Props) => {
  const { availableShowMessage } = useAutoAdmit();

  return (
    <>
      <Box
        sx={{
          backgroundColor: availableShowMessage(program) ? "#D2F5D6" : "rgba(0,0,0,0)",
          p: availableShowMessage(program) ? 2 : 0,
          maxWidth: 600,
          borderRadius: 4,
        }}
      >
        {children}
        {availableShowMessage(program) && <AutoAdmitMessage provider={program} />}
      </Box>
    </>
  );
};

export default AutoAdmit;
