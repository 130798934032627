import { Box, Toolbar, Typography, Avatar, IconButton } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Color, PageRoute } from "../../../shared/types/enums";
import TopBar from "../../../shared/components/navigation/TopBar";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useLogout from "../../../shared/hooks/auth/useLogout";
import { TourSelector } from "../../../shared/types/tourEnums";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../shared/assets/branding/chalkboard-icon.svg";

type Props = {
  open?: boolean;
};

const StudentTopMobileNavbar = ({ open }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { logout } = useLogout();
  const navigate = useNavigate();
  const location = useLocation();

  const formatTitle = (pathName: string) => {
    switch (pathName) {
      case PageRoute.STUDENT_PROFILE:
        return "Profile";
      case PageRoute.FEED:
        return "Feed";
      case PageRoute.EXPLORE_CAREERS:
        return "Explore Careers";
      case PageRoute.EXPLORE_PROGRAMS:
        return "Explore Programs";
      case PageRoute.STUDENT_ALMA:
        return "Alma";
      case PageRoute.STUDENT_MY_LIST:
        return "Bookmarks";
    }
  };

  return (
    <>
      {loggedInStudent && (
        <TopBar position="sticky" sx={{ width: "100%", ml: "1px", zIndex: 10 }} open={open}>
          <Toolbar sx={{ backgroundColor: "#fff", justifyContent: "space-between", height: 64 }}>
            <Box sx={{ pt: "2px", pb: "2px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                }}
              >
                <img src={logo} alt="Willow" style={{ width: "32px", height: "34px" }} />
                <Typography variant="h4" sx={{ color: Color.CHARCOAL }}>
                  {formatTitle(location.pathname)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box data-tut={TourSelector.PROFILE}>
                <Avatar
                  alt={loggedInStudent.avatar}
                  src={loggedInStudent.avatarURL}
                  sx={{ backgroundColor: "#F4F0DC", color: "#00362E" }}
                  onClick={() => navigate(PageRoute.STUDENT_PROFILE)}
                />
              </Box>

              <Box data-tut={TourSelector.LOGOUT}>
                <IconButton onClick={logout}>
                  <FeatherIcon icon="exit" width="24" />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </TopBar>
      )}
    </>
  );
};

export default StudentTopMobileNavbar;
