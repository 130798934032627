import { getFunctionURL } from "./cloudFunctionUtils";

type FetchDataProps = {
  functionName: string;
  payload: any;
};

export const fetchData = async ({ functionName, payload }: FetchDataProps) => {
  const url = getFunctionURL(functionName);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response;
};
