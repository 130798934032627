import { Avatar, Badge, Box, CircularProgress, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import useCustomAvatar from "../../hooks/avatar/useCustomAvatar";
import { Collection } from "../../types/enums";

type Props = {
  fileName: string;
  collection: Collection;
  documentId: string;
  size: number;
  url?: string;
  edit?: boolean;
};

const CustomAvatar = ({ fileName, collection, documentId, size, url, edit }: Props) => {
  const { avatarURL, handleSelectImage, loading } = useCustomAvatar({
    fileName,
    collection,
    documentId,
    url,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          edit && (
            <label htmlFor={`id-${documentId}-${fileName}`}>
              <input
                hidden
                accept="image/*"
                id={`id-${documentId}-${fileName}`}
                type="file"
                onChange={handleSelectImage}
              />
              <IconButton aria-label="upload picture" component="span">
                <EditIcon sx={{ width: 15, height: 15 }} />
              </IconButton>
            </label>
          )
        }
      >
        {loading ? (
          <CircularProgress size={size} />
        ) : (
          <Avatar alt={fileName} src={avatarURL ?? ""} sx={{ width: size, height: size }} />
        )}
      </Badge>
    </Box>
  );
};

export default CustomAvatar;
