import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React, { Dispatch } from "react";
import { states } from "../../../shared/assets/data/states";
import { FilterProps } from "../../../shared/types/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cities from "../../../shared/assets/data/cities.json";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
  setListOfCities: Dispatch<React.SetStateAction<string[]>>;
};

const StateFilter = ({ setFilters, setListOfCities }: Props) => {
  const handleStatesChange = (_: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, states: value }));
    const citiesArray: string[] = [];
    value.forEach((state) => {
      citiesArray.push(...cities[state as keyof typeof cities]);
    });
    setListOfCities(citiesArray);
  };
  return (
    <>
      <Autocomplete
        multiple
        id="states-filter"
        options={states}
        onChange={handleStatesChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li {...optionProps} key={option}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="States" placeholder="States" />}
      />
    </>
  );
};

export default StateFilter;
