import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import industries from "../../assets/data/industries.json";
import fields from "../../assets/data/fields.json";
import React, { useCallback, useMemo } from "react";
import { JobDetailsRecord } from "../../types/types";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { Color } from "../../types/enums";
import { useRecoilState } from "recoil";
import {
  selectedFieldIdAtom,
  selectedIndustryIdAtom,
  selectedJobIdAtom,
} from "../../recoil/exploreCareersAtoms";

type Props = {
  jobs: JobDetailsRecord[];
};

const Breadcrumb = ({ jobs }: Props) => {
  const [selectedIndustryId, setSelectedIndustryId] = useRecoilState(selectedIndustryIdAtom);
  const [selectedFieldId, setSelectedFieldId] = useRecoilState(selectedFieldIdAtom);
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  const industry = useMemo(
    () => industries.data.find((industry) => industry.id === selectedIndustryId),
    [selectedIndustryId]
  );
  const field = useMemo(
    () => fields.data.find((field) => field.id === selectedFieldId),
    [selectedFieldId]
  );
  const job = useMemo(() => jobs.find((job) => job.id === selectedJobId), [jobs, selectedJobId]);
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const maxLength = mobile ? 10 : 25;

  const handleIndustryClick = useCallback(() => {
    setSelectedFieldId(null);
    setSelectedJobId(null);
  }, [setSelectedFieldId, setSelectedJobId]);

  const handleFieldClick = useCallback(() => {
    setSelectedJobId(null);
  }, [setSelectedJobId]);

  const handleAllIndustriesClick = useCallback(() => {
    setSelectedIndustryId(null);
    setSelectedFieldId(null);
    setSelectedJobId(null);
  }, [setSelectedFieldId, setSelectedIndustryId, setSelectedJobId]);

  return (
    <div role="presentation">
      <Breadcrumbs
        separator={
          <FeatherIcon width="15px" height="18px" icon="chevron-right" strokeColor={Color.TEAL} />
        }
        aria-label="breadcrumb"
      >
        <Typography variant="body2" onClick={handleAllIndustriesClick} sx={{ cursor: "pointer" }}>
          {job?.title && mobile ? "..." : "All Industries"}
        </Typography>
        {industry && (
          <Typography
            variant="body2"
            onClick={handleIndustryClick}
            color="text.primary"
            sx={{ cursor: "pointer" }}
          >
            {industry.title.length > maxLength
              ? `${industry.title.slice(0, maxLength)}...`
              : industry.title}
          </Typography>
        )}
        {field && (
          <Typography
            variant="body2"
            onClick={handleFieldClick}
            color="text.primary"
            sx={{ cursor: "pointer" }}
          >
            {field.title.length > maxLength ? `${field.title.slice(0, maxLength)}...` : field.title}
          </Typography>
        )}
        {job && (
          <Typography variant="body2" color="text.primary">
            {job.title.length > maxLength ? `${job.title.slice(0, maxLength)}...` : job.title}
          </Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};

export default React.memo(Breadcrumb);
