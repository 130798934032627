import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";
import { useEffect, useState } from "react";
import { getMentorImage } from "../../utils/imageUtils";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const InspirationalQuotesFromFamousMentors = ({
  mobile,
  personalityType,
  onActionButtonClick,
}: Props) => {
  type MentorAvatarProps = {
    name: string;
  };

  const MentorAvatar = ({ name }: MentorAvatarProps) => {
    const [mentorImage, setMentorImage] = useState<string | undefined>(undefined);

    useEffect(() => {
      const loadImage = async () => {
        try {
          const imageModule = await getMentorImage({ name: name });
          setMentorImage(imageModule.default);
        } catch (error) {
          console.error("Error loading image:", error);
          setMentorImage(undefined);
        }
      };

      loadImage();
    }, [name]);

    return (
      <Avatar
        alt={name}
        src={mentorImage}
        sx={{ width: 96, height: 96 }}
      />
    );
  };

  return (
    <Box>
      <Typography
        sx={{ mt: 2, fontWeight: 600 }}
        variant="h6"
        fontSize={16}
        color={Color.ORANGE_700}
      >
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        Inspirational Quotes from Famous Mentors
      </Typography>
      <Box>
        {personalityType.inspirationalQuotes.map((quote, index) => (
          <Box
            key={index}
            sx={{
              py: 4,
              borderBottom: 3,
              borderColor: Color.DARK_MINT,
            }}
          >
            <Typography
              variant="h5"
              fontSize={20}
              fontWeight={400}
              textAlign={mobile ? "center" : "start"}
              color={Color.CHALKBOARD}
            >
              “{quote.quote}”
            </Typography>
            <Box sx={{
              mt: 4,
              display: "flex",
              flexDirection: mobile ? "column" : "row",
              alignItems: "center",
              gap: 4
            }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <MentorAvatar name={quote.name} />
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  fontSize={20}
                  textAlign={mobile ? "center" : "start"}
                  color={Color.CHALKBOARD}
                >
                  {quote.name}
                </Typography>
                <Typography
                  sx={{ mt: 0.5 }}
                  variant="body1"
                  fontSize={14}
                  textAlign={mobile ? "center" : "start"}
                  color={Color.CHALKBOARD}
                >
                  {quote.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Card
        sx={{
          mt: 8,
          borderLeft: 3,
          borderColor: Color.BLACK,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Typography
            sx={{ mt: 1 }}
            variant="body1"
            fontSize={14}
            color={Color.CHALKBOARD}
          >
            {personalityType.closing}
          </Typography>
        </CardContent>
      </Card>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.INSPIRATIONAL_QUOTES_FROM_FAMOUS_MENTORS}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default InspirationalQuotesFromFamousMentors;
