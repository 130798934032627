import { FormControl, FormControlLabel, Switch } from "@mui/material";
import React, { Dispatch } from "react";
import { FilterProps } from "../../../shared/types/types";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const FilterSwitches = ({ filters, setFilters }: Props) => {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    setFilters((pV) => ({ ...pV, [name]: checked }));
  };
  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch value={filters.hbcuStatus} onChange={handleSwitchChange} name="hbcuStatus" />
          }
          label="HBCU Only"
        />
        <FormControlLabel
          control={<Switch value={filters.menOnly} onChange={handleSwitchChange} name="menOnly" />}
          label="Men Only"
        />
        <FormControlLabel
          control={
            <Switch value={filters.womenOnly} onChange={handleSwitchChange} name="womenOnly" />
          }
          label="Women Only"
        />
      </FormControl>
    </>
  );
};

export default FilterSwitches;
