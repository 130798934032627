import { atom } from "recoil";
import { ProviderProgramRecord } from "../../shared/types/types";

export const recommendedProgramsAtom = atom<ProviderProgramRecord[]>({
  key: "recommendedPrograms",
  default: [],
});

export const bookmarkedProgramsAtom = atom<ProviderProgramRecord[]>({
  key: "bookmarkedPrograms",
  default: [],
});

export const selectedProgramForRecommendationsAtom = atom<ProviderProgramRecord | null>({
  key: "selectedProgramForRecommendationsAtom",
  default: null,
});
