import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import useRecommendJobDialog from "../../hooks/exploreCareers/useRecommendJobDialog";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RecommendJobDialog = () => {
  const {
    loading,
    students,
    selectedStudents,
    handleStudentsChange,
    selectedJobForRecommendations,
    setSelectedJobForRecommendations,
    handleSave,
  } = useRecommendJobDialog();

  return (
    <>
      <Dialog
        open={Boolean(selectedJobForRecommendations)}
        onClose={() => {
          setSelectedJobForRecommendations(null);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle variant="h5">
          {`Recommend ${selectedJobForRecommendations?.title}`}
        </DialogTitle>
        {loading ? (
          <LoadingBlock size={100} mt={8} />
        ) : (
          <DialogContent>
            <Box sx={{ mt: 4 }}>
              <Autocomplete
                multiple
                id="students-career-recommendation"
                options={students}
                value={selectedStudents}
                onChange={handleStudentsChange}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.firstName + " " + option.lastName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Students"
                    placeholder="Students"
                  />
                )}
              />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={loading} onClick={handleSave}>
            Save
          </Button>
          <Button
            disabled={loading}
            onClick={() => setSelectedJobForRecommendations(null)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecommendJobDialog;
