import { useEffect, useState } from "react";
import {
  CareerVideoRecord,
  EducationAttainmentRecord,
  Field,
  Industry,
  JobDetailsRecord,
  VideoInteractionRecord,
  WageDataRecord,
} from "../../../shared/types/types";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import industries from "../../../shared/assets/data/industries.json";
import fields from "../../../shared/assets/data/fields.json";
import { Collection, Color, FunctionName } from "../../../shared/types/enums";
import {
  parseJobsResponse,
  parseSingeEducationAttainmentResponse,
  parseSingeWageDataResponse,
  parseSingleJobResponse,
} from "../../../shared/utils/parserUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";

type Props = {
  jobId: string | null;
};

const useJobDetails = ({ jobId }: Props) => {
  const [jobData, setJobData] = useState<JobDetailsRecord | null>(null);
  const [wageData, setWageData] = useState<WageDataRecord | null>(null);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [videos, setVideos] = useState<CareerVideoRecord[]>([]);
  const [interactions, setInteractions] = useState<VideoInteractionRecord[]>([]);
  const [educationAttainmentData, setEducationAttainmentData] =
    useState<EducationAttainmentRecord | null>(null);
  const [industryGroupData, setIndustryGroupData] = useState<JobDetailsRecord[] | null>(null);
  const industryData: Industry[] = industries.data as Industry[];
  const fieldData: Field[] = fields.data as Field[];
  const { getFSDoc } = useGetFSDoc();
  const { getFSDocs } = useGetFSDocs();
  const idToUse = jobId;
  const truncatedId = idToUse?.slice(0, 7);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const tableStyles = {
    container: {
      mt: mobile ? 1 : 1.5,
      borderRadius: "12px",
      border: "1px solid",
      borderColor: Color.GRAY_200,
      boxShadow: "none",
    },
    header: {
      fontSize: mobile ? 12 : 14,
      fontWeight: 700,
      color: Color.DARK_TEAL,
      px: 1,
      py: 1.5,
    },
    tableHeader: {
      fontSize: mobile ? 10 : 12,
      fontWeight: 700,
      color: Color.DARK_TEAL,
      px: 1,
      py: 1.5,
    },
    typeCell: {
      fontSize: mobile ? 12 : 14,
      fontWeight: 400,
      color: Color.DARK_TEAL,
      px: 1,
      py: 1.5,
    },
  };

  const progreeBarstyles = {
    container: {
      mt: mobile ? 1 : 1.5,
      px: 1,
      py: 1.5,
      display: "flex",
      flexDirection: "column",
      gap: 1.5,
      borderRadius: "12px",
      border: "1px solid",
      borderColor: Color.GRAY_200,
    },
    progressLabel: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    progressTitle: {
      fontSize: mobile ? 14 : 16,
      fontWeight: 700,
      color: Color.DARK_TEAL,
    },
    progressPercent: {
      fontSize: 12,
      fontWeight: 400,
      color: Color.DARK_TEAL,
    },
    progressBar: {
      mt: 0.5,
      height: "20px",
      borderRadius: "6px",
      backgroundColor: Color.SURFACE_GREEN,
      "& .MuiLinearProgress-bar": {
        background: "linear-gradient(90deg, #1DBC9D 0%, #137C67 173.31%)",
        borderRadius: "6px",
      },
    },
  };

  const similarJobsStyles = {
    container: {
      mt: mobile ? 1 : 1.5,
      px: 1,
      py: 2,
      display: "flex",
      flexDirection: "column",
      gap: 1,
      borderRadius: "12px",
      border: "1px solid",
      borderColor: Color.GRAY_200,
      overflow: 'auto',
      maxHeight: '352px',
      scrollbarWidth: "none",
      
    },
    similarJobButton: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 1,
      py: 1,
      borderBottom: "1px solid",
      borderBottomColor: Color.GRAY_200,
      fontSize: mobile ? 12 : 14,
      fontWeight: 400,
      color: Color.DARK_TEAL,
    },
  }

  useEffect(() => {
    if (!idToUse || !truncatedId) return;
    const getJobData = async () => {
      const promises: [
        Promise<JobDetailsRecord | null>,
        Promise<EducationAttainmentRecord[] | null>
      ] = [
        getFSDoc<JobDetailsRecord>({ col: "jobs", id: idToUse }),
        getFSDocs<EducationAttainmentRecord>({
          col: "educationAttainment",
          config: { where: ["OCC", "==", truncatedId] },
        }),
      ];
      const [jobResults, educationAttainmentResults] = await Promise.all(promises);

      if (jobResults) setJobData(parseSingleJobResponse(jobResults));

      if (jobResults?.industry_sub_id) {
        const getIndustryData = await getFSDocs<JobDetailsRecord>({
          col: Collection.JOBS,
          config: { where: ["industry_sub_id", "==", jobResults.industry_sub_id] },
        });
        if (getIndustryData && getIndustryData.length > 0) setIndustryGroupData(parseJobsResponse(getIndustryData));
      };

      if (educationAttainmentResults && educationAttainmentResults.length > 0) {
        setEducationAttainmentData(
          parseSingeEducationAttainmentResponse(educationAttainmentResults[0])
        );
      }
    };
    const getWageData = async () => {
      if (!idToUse) return;
      const response = await fetchData({
        functionName: FunctionName.GET_WAGE_DATA,
        payload: {
          socCode: idToUse,
        },
      });
      const jsonResponse = await response.json();
      const wageDataResponse = jsonResponse.wageData as WageDataRecord[];
      if (wageDataResponse.length > 0) {
        setWageData(parseSingeWageDataResponse(wageDataResponse[0]));
      }
    };

    getJobData();
    getWageData();
  }, [getFSDoc, getFSDocs, idToUse, truncatedId]);

  useEffect(() => {
    if (!idToUse) return;
    const fetchFileNames = async () => {
      const careerVideos = await getFSDocs<CareerVideoRecord>({
        col: Collection.CAREER_VIDEOS,
        config: { where: ["onet", "==", idToUse] },
      });
      const fileNames = careerVideos.map((doc) => doc.fileName);
      const results = await fetchData({
        functionName: FunctionName.LIST_FILES,
        payload: {
          fileNames,
        },
      });
      const { urls } = await results.json();
      const videosWithUrls = careerVideos.map((video, i) => {
        return { ...video, url: urls[i] };
      });
      setVideos(videosWithUrls);

      if (loggedInStudent) {
        const videoInteractions = await getFSDocs<VideoInteractionRecord>({
          col: Collection.VIDEO_INTERACTIONS,
          config: { where: ["studentId", "==", loggedInStudent.id] },
        });
        setInteractions(videoInteractions);
      }
    };
    fetchFileNames();
  }, [getFSDocs, loggedInStudent, idToUse]);

  return {
    mobile,
    jobData,
    industryData,
    industryGroupData,
    fieldData,
    tableStyles,
    progreeBarstyles,
    similarJobsStyles,
    wageData,
    educationAttainmentData,
    videos,
    interactions,
  };
};

export default useJobDetails;
