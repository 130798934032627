import { Box, Grid, Typography } from "@mui/material";
import useFeedContainer from "../../hooks/feed/useFeedContainer";
import VideoListPlayer from "../videoList/VideoListPlayer";
import { Industry, StudentRecord } from "../../../shared/types/types";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import JobDetailsDialog from "../jobDetailsDialog/JobDetailsDialog";
import InfoBox from "../../../shared/components/infoBox/InfoBox";
import IndustryButton from "./IndustryButton";
import industries from "../../../shared/assets/data/industries.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useRef } from "react";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

type Props = {
  loggedInStudent: StudentRecord;
};

const FeedContainer = ({ loggedInStudent }: Props) => {
  const lottieRef = useRef<any>(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const industriesArray = industries.data as Industry[];
  const { selectedIndustryIds, setSelectedIndustryIds, listRef, videos, interactions, loading } =
    useFeedContainer({
      loggedInStudent,
    });

  return (
    <>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} sm={4} md={4}>
          {!isMobile && (
            <Typography sx={{ p: 1, mt: 1 }} variant="h4">
              Feed
            </Typography>
          )}
          <Box sx={{ p: 1, mt: 1, height: "calc(100VH - 81px)", overflowY: "scroll" }}>
            <Typography variant="h6">Filter By Industry</Typography>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {industriesArray.map((industry) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={industry.id}>
                  <IndustryButton
                    industry={industry}
                    selectedIndustryIds={selectedIndustryIds}
                    setSelectedIndustryIds={setSelectedIndustryIds}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Box
            component="main"
            sx={{ flexGrow: 1, height: "calc(100VH - 81px)", overflowY: "scroll" }}
            ref={listRef}
          >
            <Box sx={{ m: isMobile ? 1 : 2 }}>
              <InfoBox message="Watch the videos below to explore different careers. If you're interested in one, click the bookmark icon to save it. You can view your saved videos on your bookmarks page, and we'll suggest jobs based on what you like." />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, px: "20px" }}>
              <VideoListPlayer videos={videos} interactions={interactions} />
              {!loading && videos.length === 0 && !loading && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="h4">
                    There are no videos for your selected industries.
                  </Typography>
                  {!disableLottie && (
                    <Player
                      ref={lottieRef}
                      loop={true}
                      autoplay={true}
                      src="https://lottie.host/a3a8bd74-2165-4d1c-9192-63d7942aca8f/pWKQST98wh.json"
                    ></Player>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <JobDetailsDialog />
    </>
  );
};

export default FeedContainer;
