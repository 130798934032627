import { Box, Link, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { Color } from "../../../shared/types/enums";
import { useEffect, useState } from "react";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import WhatIsROIDialog from "./WhatIsROIDialog";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import { bookmarkedProgramsAtom } from "../../recoil/programAtoms";

const NATIONAL_AVERAGE_ROI = 129000;

const ROIOverview = () => {
  const students = useRecoilValue(allSchoolStudentsAtom);
  //   const providers = useRecoilValue(allMatchedProgramsAtom);
  const [averageROI, setAverageROI] = useState(0);
  const [percentChange, setPercentChange] = useState(0);
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const bookmarkedPrograms = useRecoilValue(bookmarkedProgramsAtom);

  useEffect(() => {
    let totalROI = 0;
    let count = 0;
    for (const program of bookmarkedPrograms) {
      if (!program.willowROI || isNaN(program.willowROI)) continue;
      totalROI += Number(program.willowROI);
      count++;
    }
    setAverageROI(count > 0 ? totalROI / count : 0);
    setPercentChange((averageROI / NATIONAL_AVERAGE_ROI - 1) * 100);
  }, [averageROI, bookmarkedPrograms, students]);

  return (
    <>
      {averageROI > 0 && (
        <>
          <Box sx={{ padding: 1, textAlign: "left" }}>
            <Typography variant="h5" sx={{ color: Color.MINT }}>
              {formatCurrency({ amount: averageROI })}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              The national average return on investment for college programs is{" "}
              {formatCurrency({ amount: NATIONAL_AVERAGE_ROI })}.{" "}
              <a
                href="https://freopp.org/is-college-worth-it-a-comprehensive-return-on-investment-analysis-1b2ad17f84c8"
                target="_blank"
                rel="noreferrer noopener"
                className="source"
              >
                Source
              </a>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              This means your students on average will earn {Math.round(percentChange)}%{" "}
              {percentChange > 0 ? "more " : "less "} than other students in the US by using Willow.{" "}
              <Link
                sx={{
                  color: Color.MINT,
                  textDecoration: "none",
                  fontStyle: "italic",
                  fontSize: 10,
                }}
                component="button"
                onClick={() => setWhatIsROIOpen(true)}
              >
                Learn More About ROI
              </Link>
            </Typography>
          </Box>
          <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
        </>
      )}
    </>
  );
};

export default ROIOverview;
