import { useEffect, useState, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, PreQuiz } from "../../../shared/types/enums";
import { StudentRecord } from "../../../shared/types/types";
import { generateQuizQuestion } from "../../utils/quizUtils";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

const usePreQuizQuestions = (loggedInStudent: StudentRecord) => {
  const [activeQuestion, setActiveQuestion] = useState(() =>
    generateQuizQuestion({ id: PreQuiz.MY_WHY, name: loggedInStudent.firstName })
  );
  const [myWhy, setMyWhy] = useState("");
  const [whatDescribesMe, setWhatDescribesMe] = useState("");
  const [isBackDisabled, setIsBackDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { updateFSDoc } = useUpdateFSDoc();
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { submitLog } = useLogger();

  useEffect(() => {
    setIsBackDisabled(activeQuestion.id === PreQuiz.MY_WHY);
    setIsNextDisabled(
      (activeQuestion.id === PreQuiz.MY_WHY && !myWhy) ||
        (activeQuestion.id === PreQuiz.WHAT_DESCRIBES_ME && !whatDescribesMe)
    );
    if (activeQuestion.id === PreQuiz.WHAT_DESCRIBES_ME_FEEDBACK) {
    } else if (activeQuestion.id === PreQuiz.WHAT_DESCRIBES_ME) {
    } else if (activeQuestion.id === PreQuiz.MY_WHY) {
    }
  }, [activeQuestion.id, myWhy, whatDescribesMe]);

  const generateQuestion = useCallback(
    (id: PreQuiz) => generateQuizQuestion({ id, name: loggedInStudent.firstName }),
    [loggedInStudent.firstName]
  );

  const submitMyWhyAnswer = useCallback(async () => {
    try {
      await updateFSDoc({ col: Collection.STUDENTS, id: loggedInStudent.id, data: { myWhy } });
      setLoggedInStudent((prev) => (prev ? { ...prev, myWhy } : null));
      setActiveQuestion(generateQuestion(PreQuiz.WHAT_DESCRIBES_ME));
      submitLog({
        eventType: LogEventType.SUBMIT_MY_WHY_ANSWER_IN_QUIZ,
        file: "usePreQuizQuestions.ts",
      });
    } catch (error: any) {
      submitLog({
        error,
        snackbarMessage:
          "An error occurred while submitting your answer. Please refresh and try again.",
        eventType: LogEventType.SUBMIT_MY_WHY_ANSWER_IN_QUIZ_ERROR,
        file: "usePreQuizQuestions.ts",
      });
    }
  }, [generateQuestion, loggedInStudent.id, myWhy, setLoggedInStudent, submitLog, updateFSDoc]);

  const submitBestDescribesMeAnswer = useCallback(async () => {
    try {
      await updateFSDoc({
        col: Collection.STUDENTS,
        id: loggedInStudent.id,
        data: { whatDescribesMe, preQuizComplete: true },
      });
      setLoggedInStudent((prev) =>
        prev ? { ...prev, whatDescribesMe, preQuizComplete: true } : null
      );
      submitLog({
        eventType: LogEventType.SUBMIT_BEST_DESCRIBES_ME_ANSWER_IN_QUIZ,
        file: "usePreQuizQuestions.ts",
      });
    } catch (error: any) {
      submitLog({
        error,
        snackbarMessage:
          "An error occurred while submitting your answer. Please refresh and try again.",
        eventType: LogEventType.SUBMIT_BEST_DESCRIBES_ME_ANSWER_IN_QUIZ_ERROR,
        file: "usePreQuizQuestions.ts",
      });
    }
  }, [loggedInStudent.id, setLoggedInStudent, submitLog, updateFSDoc, whatDescribesMe]);

  const onSubmit = useCallback(async () => {
    switch (activeQuestion.id) {
      case PreQuiz.MY_WHY:
        submitMyWhyAnswer();
        break;
      case PreQuiz.WHAT_DESCRIBES_ME:
        setActiveQuestion(generateQuestion(PreQuiz.WHAT_DESCRIBES_ME_FEEDBACK));
        break;
      default:
        submitBestDescribesMeAnswer();
    }
  }, [activeQuestion.id, generateQuestion, submitBestDescribesMeAnswer, submitMyWhyAnswer]);

  const onBack = useCallback(() => {
    switch (activeQuestion.id) {
      case PreQuiz.WHAT_DESCRIBES_ME:
        setActiveQuestion(generateQuestion(PreQuiz.MY_WHY));
        break;
      case PreQuiz.WHAT_DESCRIBES_ME_FEEDBACK:
        setActiveQuestion(generateQuestion(PreQuiz.WHAT_DESCRIBES_ME));
        break;
    }
  }, [activeQuestion.id, generateQuestion]);

  return {
    activeQuestion,
    myWhy,
    setMyWhy,
    whatDescribesMe,
    setWhatDescribesMe,
    onSubmit,
    onBack,
    isBackDisabled,
    isNextDisabled,
  };
};

export default usePreQuizQuestions;
