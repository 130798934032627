import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React, { Dispatch } from "react";
import { FilterProps } from "../../../shared/types/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
  listOfCities: string[];
};

const CityFilter = ({ setFilters, listOfCities }: Props) => {
  const handleCitiesChange = (_: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, cities: value }));
  };
  return (
    <>
      <Autocomplete
        multiple
        id="cities-filter"
        options={listOfCities}
        onChange={handleCitiesChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li {...optionProps} key={option}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Cities" placeholder="Cities" />}
      />
    </>
  );
};

export default CityFilter;
