import { QuizResultsOption } from "../types/enums";

export const personalityTypeMenuItems = [
  QuizResultsOption.INTRODUCTION,
  QuizResultsOption.RECOMMENDED_CAREERS_TO_EXPLORE,
  QuizResultsOption.COLLEGE_MAJORS_YOU_MIGHT_ENJOY,
  QuizResultsOption.PROFESSIONAL_PROGRAMS_TO_CONSIDER,
  QuizResultsOption.HOW_YOU_COMMUNICATE,
  QuizResultsOption.YOUR_WORK_STYLE,
  QuizResultsOption.RELATIONSHIP_TIPS,
  QuizResultsOption.PRIORITIZED_PERSONAL_DEVELOPMENT_GOALS,
  QuizResultsOption.STUDY_TIPS,
  QuizResultsOption.INSPIRATIONAL_QUOTES_FROM_FAMOUS_MENTORS,
];