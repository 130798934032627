import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ScholarshipApplicationRecord, ScholarshipRecord } from "../../../shared/types/types";
import FormikDatePicker from "../../../shared/components/formikDatePicker/FormikDatePicker";
import AwardAmountField from "./AwardAmountField";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import dayjs from "dayjs";

type Props = {
  scholarship: ScholarshipRecord | undefined;
  scholarshipApplication: ScholarshipApplicationRecord;
  setSelectedScholarshipApplication: React.Dispatch<
    React.SetStateAction<ScholarshipApplicationRecord | null>
  >;
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
};

const statusOptions = [
  { value: "not applied", label: "Not Applied For" },
  { value: "applied", label: "Applied For" },
  { value: "rejected", label: "Turned Down" },
  { value: "awarded", label: "Awarded" },
];

const ScholarshipApplicationDialog: React.FC<Props> = ({
  scholarship,
  scholarshipApplication,
  setSelectedScholarshipApplication,
  setScholarshipApplications,
}) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const handleClose = () => setSelectedScholarshipApplication(null);
  const today = dayjs().startOf('day');

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    appliedDate: Yup.date()
      .nullable()
      .max(today.toDate(), "Applied Date cannot be before today"),
    awardAmount: Yup.number()
      .min(
        scholarship?.minAwardAmount ?? 0,
        `Award Amount must be at least ${scholarship?.minAwardAmount}`
      )
      .max(
        scholarship?.maxAwardAmount ?? Number.MAX_VALUE,
        `Award Amount cannot exceed ${scholarship?.maxAwardAmount}`
      )
      .nullable(),
    awardDate: Yup.date()
      .nullable()
      .max(today.toDate(), "Award Date cannot be before today")
      .when("appliedDate", (appliedDate, schema) =>
        appliedDate
          ? schema.min(
              appliedDate,
              "Award Date cannot be before the Applied Date"
            )
          : schema
      ),
  });

  const handleSave = async (values: ScholarshipApplicationRecord) => {
    await updateFSDoc({
      col: Collection.SCHOLARSHIP_APPLICATIONS,
      id: scholarshipApplication.id,
      data: values,
    });
    setScholarshipApplications((prev) => {
      const index = prev.findIndex((app) => app.id === scholarshipApplication.id);
      const updatedApps = [...prev];
      updatedApps[index] = values;
      return updatedApps;
    });
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Scholarship Application</DialogTitle>
      <Formik
        initialValues={scholarshipApplication}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSave(values);
          handleClose();
        }}
      >
        {({ values, setFieldValue, touched, errors, isValid, isSubmitting }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    select
                    fullWidth
                    name="status"
                    label="Status"
                    variant="outlined"
                    value={values.status}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("status", e.target.value)
                    }
                    error={touched.status && Boolean(errors.status)}
                    helperText={touched.status && errors.status}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                {/* Ensure appliedDate Field is fullWidth */}
                {values.status !== "not applied" && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="appliedDate"
                      component={FormikDatePicker}
                      label="Applied Date"
                      format="MM/dd/yyyy"
                      maxDate={dayjs()}
                      fullWidth // Add fullWidth prop here
                    />
                    {Boolean(errors.appliedDate) ? (
                      <FormHelperText error>{errors.appliedDate}</FormHelperText>
                    ) : null}
                  </Grid>
                )}

                {/* Ensure awardDate Field is fullWidth */}
                {values.status === "awarded" && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="awardDate"
                      component={FormikDatePicker}
                      label="Award Date"
                      format="MM/dd/yyyy"
                      minDate={values.appliedDate ? dayjs(values.appliedDate) : undefined}
                      maxDate={dayjs()}
                      fullWidth // Add fullWidth prop here
                    />
                    {Boolean(errors.awardDate) ? (
                      <FormHelperText error>{errors.awardDate}</FormHelperText>
                    ) : null}
                  </Grid>
                )}

                {/* Ensure awardAmount Field is fullWidth */}
                {values.status === "awarded" && (
                  <Grid item xs={12}>
                    <AwardAmountField />
                    {Boolean(errors.awardAmount) ? (
                      <FormHelperText error>{errors.awardAmount}</FormHelperText>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ScholarshipApplicationDialog;
