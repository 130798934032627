import { Button, Grid } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { personalityTypeMenuItems } from "../../utils/quizUtils";

type Props = {
  mobile: boolean;
  currentTitle: QuizResultsOption;
  onNextClick?: (nextTitle: QuizResultsOption) => void;
  onPreviousClick?: (nextTitle: QuizResultsOption) => void;
};

const NavigationButtons = ({ mobile, currentTitle, onNextClick, onPreviousClick }: Props) => {
  const currentIndex = personalityTypeMenuItems.indexOf(currentTitle);
  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < personalityTypeMenuItems.length - 1;
  const previousTitle = hasPrevious ? personalityTypeMenuItems[currentIndex - 1] : null;
  const nextTitle = hasNext ? personalityTypeMenuItems[currentIndex + 1] : null;

  return (
    <Grid container spacing={2} direction={mobile ? "row" : "row-reverse"} sx={{ py: 6 }}>
      <Grid item xs={mobile ? 12 : 6}>
        {nextTitle && (
          <Button
            sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              backgroundColor: Color.CHALKBOARD,
              color: Color.GRAY_50,
              boxShadow: "0px 2px 8px -1px #10182814",
              textTransform: "none",
              "&:hover": {
                color: Color.CHALKBOARD,
              },
              height: "100%",
            }}
            endIcon={
              <FeatherIcon
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                icon="arrow-right"
                strokeColor="none"
                fillColor={Color.GRAY_50}
              />
            }
            color="primary"
            size="large"
            fullWidth
            onClick={() => nextTitle && onNextClick && onNextClick(nextTitle)}
          >
            {nextTitle}
          </Button>
        )}
      </Grid>
      <Grid item xs={mobile ? 12 : 6}>
        {previousTitle && (
          <Button
            sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: "12px",
              backgroundColor: Color.GREEN_700,
              border: 1,
              borderColor: Color.MINT,
              color: Color.CHALKBOARD,
              boxShadow: "0px 2px 8px -1px #10182814",
              textTransform: "none",
            }}
            startIcon={
              <FeatherIcon
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                icon="arrow-left"
                strokeColor="none"
                fillColor={Color.CHALKBOARD}
              />
            }
            color="primary"
            size="large"
            fullWidth
            onClick={() => onPreviousClick && onPreviousClick(previousTitle)}
          >
            {previousTitle}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default NavigationButtons;
