// almaConstants.ts

export const STARTER_PROMPTS = [
  {
    text: "Picking a Career",
    svg: "almaGrowth",
    prompt:
      "Hi Alma, I'm thinking about my future career. Can you help me figure out a good career path that fits my interests and strengths?",
  },
  {
    text: "Writing a Great College Essay",
    svg: "almaEdit",
    prompt:
      "Hi Alma, I need some tips on writing a college essay that will help me stand out. Can you give me advice on what to include?",
  },
  {
    text: "Picking a College",
    svg: "almaSchool",
    prompt:
      "Hi Alma, I'm trying to figure out which college might be a good fit for me. Can you ask me a few questions to help guide my choice and recommend some options?",
  },
  {
    text: "Deciding on a Major",
    svg: "almaGraduation",
    prompt:
      "Hi Alma, I'm not sure which major to choose. Can you ask me some questions to help me decide and recommend some majors based on my interests?",
  },
  {
    text: "Getting Ready for the ACT / SAT",
    svg: "almaBook",
    prompt:
      "Hi Alma, I need help preparing for the ACT / SAT. Can you give me tips on how to study effectively and remind me of the important deadlines?",
  },
  {
    text: "Study Tips",
    svg: "almaLamp",
    prompt:
      "Hi Alma, I need help with studying. Can you give me tips on how to study better and manage my time more effectively?",
  },
  {
    text: "Paying for College",
    svg: "almaMoney",
    prompt:
      "Hi Alma, I’m looking for ways to pay for college. Can you explain the different options like scholarships, grants, and loans?",
  },
  {
    text: "Getting an Internship",
    svg: "almaPerson",
    prompt:
      "Hi Alma, I want to get some work experience. Can you help me understand how to find internships or other opportunities like volunteering or job shadowing?",
  },
];
