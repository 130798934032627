import { useCallback, useEffect } from "react";
import { MentorUserRecord, ProviderUserRecord, StaffRecord, StudentRecord } from "../types/types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  loggedInStaffAtom,
  loggedInProviderAtom,
  loggedInStudentAtom,
  userTypeAtom,
  loggedInMentorAtom,
  loggedInWillowStaffAtom,
} from "../recoil/userAtoms";
import { Collection, FunctionName, KlaviyoListType, UserType } from "../types/enums";
import {
  parseSingleMentorUserResponse,
  parseSingleProviderUserResponse,
  parseSingleStaffResponse,
  parseSingleStudentResponse,
} from "../utils/parserUtils";
import { UserContext } from "../contexts/UserContext";
import { authLoadingAtom, userLoadingAtom } from "../recoil/loadingAtoms";
import { getImageUrl } from "../utils/imageUtils";
import { selectedSchoolIdAtom } from "../../staff/recoil/selectedSchoolAtom";
import { fetchData } from "../utils/fetchUtils";
import useSetFSDoc from "../hooks/db/useSetFSDoc";
import useLogger from "../hooks/logging/useLogger";
import { LogEventType } from "../types/logEnums";
type Props = {
  children: JSX.Element;
};

export const UserProvider = ({ children }: Props) => {
  const { currentAuthUser } = useAuth();
  const authLoading = useRecoilValue(authLoadingAtom);
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const setLoggedInProvider = useSetRecoilState(loggedInProviderAtom);
  const setLoggedInMentor = useSetRecoilState(loggedInMentorAtom);
  const setLoggedInWillowStaff = useSetRecoilState(loggedInWillowStaffAtom);
  const setUserType = useSetRecoilState(userTypeAtom);
  const setUserLoading = useSetRecoilState(userLoadingAtom);
  const setSelectedSchoolId = useSetRecoilState(selectedSchoolIdAtom);
  const { setFSDoc } = useSetFSDoc();
  const { submitLog } = useLogger();

  const createStudentRecord = useCallback(
    async ({ staffRecord }: { staffRecord: StaffRecord }) => {
      try {
        const results = await fetchData({
          functionName: FunctionName.GET_UID,
          payload: { email: staffRecord.email },
        });
        const uid = await results.json();

        const klaviyoResults = await fetchData({
          functionName: FunctionName.CREATE_PROFILE_AND_ADD_TO_LIST_KLAVIYO,
          payload: {
            email: staffRecord.email,
            firstName: staffRecord.firstName,
            lastName: staffRecord.lastName,
            listType: KlaviyoListType.STUDENT,
          },
        });
        const klaviyoResultsJson = await klaviyoResults.json();
        const klaviyoProfileId = klaviyoResultsJson.id ?? null;

        await setFSDoc({
          col: Collection.STUDENTS,
          id: uid.uid,
          data: {
            email: staffRecord.email,
            schoolId: staffRecord.schoolId,
            districtId: staffRecord.districtId,
            firstName: staffRecord.firstName,
            lastName: staffRecord.lastName,
            klaviyoProfileId: klaviyoProfileId,
          },
        });
        setLoggedInStudent(
          parseSingleStudentResponse({
            email: staffRecord.email,
            schoolId: staffRecord.schoolId,
            districtId: staffRecord.districtId,
            firstName: staffRecord.firstName,
            lastName: staffRecord.lastName,
            id: uid.uid,
          } as unknown as StudentRecord)
        );
        submitLog({
          eventType: LogEventType.CREATE_STUDENT_FROM_USER_PROVIDER,
          file: "UserProvider.tsx",
        });
      } catch (error) {
        submitLog({
          error: error instanceof Error ? error : new Error("Error creating student record"),
          eventType: LogEventType.CREATE_STUDENT_FROM_USER_PROVIDER_ERROR,
          file: "UserProvider.tsx",
          snackbarMessage: "Error creating student record",
        });
      }
    },
    [setFSDoc, setLoggedInStudent, submitLog]
  );

  useEffect(() => {
    if (authLoading) return;
    setUserLoading(true);
    if (!currentAuthUser) {
      setLoggedInStaff(null);
      setLoggedInStudent(null);
      setUserLoading(false);
    } else {
      const getUserRecord = async () => {
        const uid = currentAuthUser.uid;
        const staffDocRef = doc(db, Collection.STAFF, uid);
        const studentDocRef = doc(db, Collection.STUDENTS, uid);
        const providerUserDocRef = doc(db, Collection.PROVIDER_USERS, uid);
        const mentorUserDocRef = doc(db, Collection.MENTOR_USERS, uid);
        const userPromises: [
          Promise<StaffRecord | null>,
          Promise<StudentRecord | null>,
          Promise<ProviderUserRecord | null>,
          Promise<MentorUserRecord | null>
        ] = [
          getDoc(staffDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as StaffRecord) : null
          ),
          getDoc(studentDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as StudentRecord) : null
          ),
          getDoc(providerUserDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as ProviderUserRecord) : null
          ),
          getDoc(mentorUserDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as MentorUserRecord) : null
          ),
        ];
        const [staffRecord, studentRecord, providerUserRecord, mentorUserRecord] =
          await Promise.all(userPromises);
        if (staffRecord) {
          setLoggedInStaff(staffRecord ? parseSingleStaffResponse(staffRecord) : null);
          setSelectedSchoolId(staffRecord.schoolId);
          setUserType(UserType.TEACHER);
          if (!studentRecord) {
            createStudentRecord({ staffRecord });
          }
        }
        if (studentRecord) {
          const parsedStudent = parseSingleStudentResponse(studentRecord);
          const avatarURL = await getImageUrl(parsedStudent.avatar);
          setLoggedInStudent({ ...parsedStudent, avatarURL: avatarURL ?? "" });
          if (!staffRecord) {
            setUserType(UserType.STUDENT);
          }
        }
        if (providerUserRecord) {
          setLoggedInProvider(parseSingleProviderUserResponse(providerUserRecord));
          setUserType(UserType.PROVIDER);
        }
        if (mentorUserRecord) {
          setLoggedInMentor(parseSingleMentorUserResponse(mentorUserRecord));
          setUserType(UserType.MENTOR);
        }
        setUserLoading(false);
      };
      getUserRecord();
    }
  }, [
    authLoading,
    currentAuthUser,
    setLoggedInStaff,
    setLoggedInStudent,
    setLoggedInProvider,
    setLoggedInMentor,
    setUserType,
    setUserLoading,
    setLoggedInWillowStaff,
    setSelectedSchoolId,
    setFSDoc,
    createStudentRecord,
  ]);

  return <UserContext.Provider value={null}>{children}</UserContext.Provider>;
};
