import { AnswerRecord, QuestionRecord, StudentRecord } from "../../../shared/types/types";
import QuestionWrapper from "./QuestionWrapper";
import MSQuestion from "./MSQuestion";
import SelectBetweenQuestion from "./SelectBetweenQuestion";
import useQuizQuestionManager from "../../hooks/quiz/useQuizQuestionManager";

type Props = {
  loggedInStudent: StudentRecord;
  question: QuestionRecord;
  answer: AnswerRecord | null;
  goBack: () => void;
  goForward: () => void;
  isLastQuestion: boolean;
};

const QuizQuestionManager = ({
  loggedInStudent,
  question,
  answer,
  goBack,
  goForward,
  isLastQuestion,
}: Props) => {
  const { picks, setPicks, onSubmit, nextDisabled } = useQuizQuestionManager({
    loggedInStudent,
    question,
    answer,
    goForward,
    isLastQuestion,
  });

  return (
    <>
      {question && (
        <QuestionWrapper
          loggedInStudent={loggedInStudent}
          question={question}
          onSubmit={onSubmit}
          onBack={goBack}
          backDisabled={question.order === 1}
          nextDisabled={nextDisabled}
          isLastQuestion={isLastQuestion}
        >
          <>
            {question.questionType === "ms" && (
              <MSQuestion question={question} picks={picks} setPicks={setPicks} />
            )}
            {question.questionType === "selectBetween" && (
              <SelectBetweenQuestion question={question} picks={picks} setPicks={setPicks} />
            )}
          </>
        </QuestionWrapper>
      )}
    </>
  );
};

export default QuizQuestionManager;
