import CreateProfileForm from "./CreateProfileForm";
import { Box, Typography } from "@mui/material";
import { useAuth } from "../../../shared/contexts/AuthContext";
import AlmaIcon from "../../../shared/assets/icons/AlmaIcon";
import { Player } from "@lottiefiles/react-lottie-player";
import animatedAlma from "../../../shared/assets/icons/animatedAlma.json";
import { useEffect, useState } from "react";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection } from "../../../shared/types/enums";
import { DistrictRecord, SchoolRecord } from "../../../shared/types/types";
import { parseDistrictResponse, parseSchoolResponse } from "../../../shared/utils/parserUtils";
// import backgroundImage from "../../../shared/assets/static/glasses-and-notebook.svg";
const CreateProfileContainer = () => {
  const { currentAuthUser } = useAuth();
  const { getFSDocs } = useGetFSDocs();
  const [schools, setSchools] = useState<SchoolRecord[]>([]);
  const [districts, setDistricts] = useState<DistrictRecord[]>([]);
  useEffect(() => {
    const getData = async () => {
      const schoolResults = await getFSDocs<SchoolRecord>({ col: Collection.SCHOOLS });
      setSchools(parseSchoolResponse(schoolResults));
      const districtResults = await getFSDocs<DistrictRecord>({ col: Collection.DISTRICTS });
      setDistricts(parseDistrictResponse(districtResults));
    };
    getData();
  }, [getFSDocs]);
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ pr: 2, pt: 1, flexShrink: 0 }}>
            {process.env.REACT_APP_DISABLE_LOTTIE === "true" ? (
              <AlmaIcon size={30} />
            ) : (
              <Player autoplay loop src={animatedAlma} style={{ height: "30px" }} />
            )}
          </Box>
          <Box>
            <Typography variant="h4" align="left" gutterBottom>
              Welcome To Willow!
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 300, color: "#616A66" }}>
              I'm Alma, your AI mentor. I'm here to help you find the best fit for your education
              after high school.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Let's Get Started
            </Typography>
          </Box>
        </Box>
        {currentAuthUser && schools.length > 0 && (
          <CreateProfileForm currentAuthUser={currentAuthUser} schools={schools} districts={districts} />
        )}
      </Box>
      {/* <Box sx={{ position: "fixed", bottom: 0, width: "100%", zIndex: -555555 }}>
        <img
          src={backgroundImage}
          alt="background"
          style={{ width: "100%", height: "auto", zIndex: -55555 }}
        />d
      </Box> */}
    </>
  );
};

export default CreateProfileContainer;
