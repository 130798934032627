import { Box } from "@mui/material";

type Props = {
  fillColor?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  icon?: string;
  className?: string;
  strokeWidth?: string;
};

const Clipboard = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
  </svg>
);

const CircleCheck = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

const Message = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
  </svg>
);

const Download = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="7 10 12 15 17 10"></polyline>
    <line x1="12" y1="15" x2="12" y2="3"></line>
  </svg>
);

const DB = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
  </svg>
);

const Bookmark = ({ strokeColor, strokeWidth, fillColor }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    fill={fillColor}
    stroke={strokeColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Filter = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </svg>
);

const Search = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const Close = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </svg>
);

const List = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <line x1="8" y1="6" x2="21" y2="6"></line>
    <line x1="8" y1="12" x2="21" y2="12"></line>
    <line x1="8" y1="18" x2="21" y2="18"></line>
    <line x1="3" y1="6" x2="3.01" y2="6"></line>
    <line x1="3" y1="12" x2="3.01" y2="12"></line>
    <line x1="3" y1="18" x2="3.01" y2="18"></line>
  </svg>
);

const Settings = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>
);

const Add = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const Edit = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
  </svg>
);

const Pencil = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
  </svg>
);

const PhoneOff = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path>
    <line x1="23" y1="1" x2="1" y2="23"></line>
  </svg>
);

const Slash = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
    </svg>
  </Box>
);

const Clock = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
  </Box>
);

const Wallet = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="m14.2 7q0.2 0.2 0.2 0.4v3.1q0 0.3-0.2 0.4-0.2 0.2-0.4 0.2v1.6q-0.1 0.2-0.1 0.5-0.1 0.2-0.3 0.4-0.2 0.2-0.4 0.3-0.3 0.1-0.5 0.1h-10.6q-0.2 0-0.5-0.1-0.2-0.1-0.4-0.3-0.2-0.2-0.3-0.4-0.1-0.3-0.1-0.5v-7.5q0-0.2 0.1-0.4 0.1-0.3 0.3-0.5 0.2-0.1 0.4-0.2 0.3-0.1 0.5-0.1h0.7l0.9-2.4q0.1-0.1 0.2-0.3 0.2-0.1 0.3-0.2 0.2-0.1 0.4-0.1 0.2 0 0.4 0.1l6.8 2.9h0.9q0.2 0 0.5 0.1 0.2 0.1 0.4 0.2 0.2 0.2 0.3 0.5 0 0.2 0.1 0.4v1.6q0.2 0 0.4 0.2zm-10.9-3h0.7l0.4-1.1q0-0.1 0.2-0.2 0.1 0 0.2 0l3.7 1.3h1.5l-5.5-2.3q-0.1-0.1-0.2 0-0.1 0-0.1 0.1-0.1 0-0.1 0.1zm1.6-0.6l-0.2 0.6h1.9zm8.2 7.7h-2.8c-0.6 0-1.1-0.2-1.6-0.6-0.4-0.5-0.6-1-0.6-1.6 0-0.6 0.2-1.1 0.6-1.5 0.5-0.4 1-0.7 1.6-0.7h2.8v-1.5q0-0.3-0.2-0.5-0.2-0.2-0.5-0.2h-10.5q-0.3 0-0.5 0.2-0.2 0.2-0.2 0.5v7.4q0 0.3 0.2 0.5 0.2 0.2 0.5 0.2h10.5q0.3 0 0.5-0.2 0.2-0.2 0.2-0.5zm0.6-3.7h-3.4c-0.4 0-0.8 0.1-1.1 0.4-0.3 0.3-0.5 0.7-0.5 1.1 0 0.5 0.2 0.8 0.5 1.1 0.3 0.3 0.7 0.5 1.1 0.5h3.4q0 0 0 0 0 0 0 0z" />
      <path d="m11 8.3q0.2 0.3 0.3 0.7-0.1 0.2-0.2 0.5-0.2 0.2-0.4 0.3-0.3 0.1-0.6 0.1-0.2-0.1-0.5-0.3-0.1-0.2-0.2-0.5-0.1-0.2 0-0.5 0.2-0.3 0.4-0.4 0.2-0.2 0.5-0.2 0.4 0 0.7 0.3zm-0.5 0.9q0.1-0.1 0.1-0.2 0-0.1 0-0.2-0.1-0.1-0.2-0.1-0.1-0.1-0.1 0-0.1 0-0.2 0-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0.1 0.1 0.1 0.1 0.1 0.1 0.2 0.1 0.1 0 0.2-0.1z" />
      <path d="m4.4 6.1q0 0.2-0.1 0.3-0.1 0.1-0.2 0.1h-1.3q-0.1 0-0.2-0.1-0.1-0.1-0.1-0.3 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1h1.3q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2z" />
      <path d="m5.6 7.4q0 0.1-0.1 0.2-0.1 0.1-0.2 0.1h-2.5q-0.1 0-0.2-0.1-0.1-0.1-0.1-0.2 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1h2.5q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2z" />
    </svg>
  </Box>
);

const ThumbDown = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
    </svg>
  </Box>
);

const Trash = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33335 1.87109C7.05708 1.87109 6.79213 1.98084 6.59678 2.17619C6.40143 2.37154 6.29168 2.63649 6.29168 2.91276V3.95441H11.7083V2.91276C11.7083 2.63649 11.5986 2.37154 11.4033 2.17619C11.2079 1.98084 10.9429 1.87109 10.6667 1.87109H7.33335ZM12.9583 3.95441V2.91276C12.9583 2.30497 12.7169 1.72208 12.2871 1.29231C11.8574 0.862537 11.2745 0.621094 10.6667 0.621094H7.33335C6.72556 0.621094 6.14266 0.862537 5.71289 1.29231C5.28312 1.72208 5.04168 2.30497 5.04168 2.91276V3.95442L1.5 3.95441C1.15482 3.95441 0.875 4.23424 0.875 4.57941C0.875 4.92459 1.15482 5.20441 1.5 5.20441H2.54167L2.54168 16.2461C2.54168 16.8539 2.78312 17.4368 3.21289 17.8665C3.64266 18.2963 4.22556 18.5378 4.83335 18.5378H13.1667C13.7745 18.5378 14.3574 18.2963 14.7871 17.8665C15.2169 17.4368 15.4583 16.8539 15.4583 16.2461V5.20441H16.5C16.8452 5.20441 17.125 4.92459 17.125 4.57941C17.125 4.23424 16.8452 3.95441 16.5 3.95441H12.9583ZM5.67041 5.20442C5.66917 5.20442 5.66793 5.20443 5.66668 5.20443C5.66543 5.20443 5.66419 5.20442 5.66294 5.20442L3.79167 5.20441L3.79168 16.2461C3.79168 16.5224 3.90143 16.7873 4.09678 16.9827C4.29213 17.178 4.55708 17.2878 4.83335 17.2878H13.1667C13.4429 17.2878 13.7079 17.178 13.9033 16.9827C14.0986 16.7873 14.2083 16.5224 14.2083 16.2461V5.20443L12.3353 5.20442C12.3346 5.20443 12.334 5.20443 12.3333 5.20443C12.3327 5.20443 12.332 5.20443 12.3314 5.20442L5.67041 5.20442ZM7.33332 8.12109C7.6785 8.12109 7.95832 8.40092 7.95832 8.74609V13.7461C7.95832 14.0913 7.6785 14.3711 7.33332 14.3711C6.98814 14.3711 6.70832 14.0913 6.70832 13.7461V8.74609C6.70832 8.40092 6.98814 8.12109 7.33332 8.12109ZM10.6667 8.12109C11.0119 8.12109 11.2917 8.40092 11.2917 8.74609V13.7461C11.2917 14.0913 11.0119 14.3711 10.6667 14.3711C10.3215 14.3711 10.0417 14.0913 10.0417 13.7461V8.74609C10.0417 8.40092 10.3215 8.12109 10.6667 8.12109Z"
    />
  </svg>
);

const Heart = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
    </svg>
  </Box>
);

const Info = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="6"></line>
    </svg>
  </Box>
);

const ThumbsUp = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
    </svg>
  </Box>
);

const X = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </Box>
);

const AI = ({ width, height, strokeColor }: Props) => (
  <Box sx={{ width }}>
    <svg
      width={width}
      height={height}
      viewBox={`-2 0 ${width?.replace("px", "")} ${height?.replace("px", "")}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="1.5px">
        <path
          id="Vector"
          d="M12.0682 13.2098C12.0682 13.2098 11.674 13.0783 11.1264 12.8959C9.23742 12.2663 7.73371 10.7626 7.1041 8.87379C6.92172 8.32602 6.79016 7.93176 6.79016 7.93176C6.71039 7.69258 6.48648 7.53125 6.23434 7.53125C5.98219 7.53125 5.75832 7.69262 5.67852 7.9318C5.67852 7.9318 5.54703 8.32605 5.36469 8.87363C4.73504 10.7626 3.23137 12.2663 1.3425 12.8959C0.794766 13.0783 0.400508 13.2098 0.400508 13.2098C0.161328 13.2896 0 13.5135 0 13.7657C0 14.0178 0.161367 14.2417 0.400547 14.3215C0.400547 14.3215 0.794805 14.453 1.34234 14.6353C3.23133 15.265 4.73504 16.7686 5.36465 18.6575C5.54703 19.2052 5.67852 19.5995 5.67852 19.5995C5.75832 19.8386 5.98215 20 6.23434 20H6.23438C6.48652 20 6.71043 19.8387 6.79019 19.5995C6.79019 19.5995 6.92176 19.2052 7.1041 18.6576C7.73375 16.7687 9.23742 15.265 11.1263 14.6354C11.674 14.453 12.0682 14.3215 12.0682 14.3215C12.3074 14.2417 12.4688 14.0179 12.4688 13.7657C12.4688 13.5135 12.3074 13.2896 12.0682 13.2098ZM7.81184 15.3431C7.14832 16.0066 6.61059 16.7973 6.23438 17.6577C5.85816 16.7974 5.32047 16.0066 4.65688 15.3431C3.99336 14.6795 3.20258 14.1418 2.3423 13.7656C3.20254 13.3894 3.99328 12.8517 4.65688 12.1881C5.32039 11.5246 5.85813 10.7339 6.23434 9.87355C6.61055 10.7338 7.14824 11.5245 7.81184 12.1881C8.47535 12.8516 9.26609 13.3894 10.1264 13.7656C9.26617 14.1418 8.47547 14.6795 7.81184 15.3431Z"
          fill={strokeColor}
        />
        <path
          id="Vector_2"
          d="M15.021 9.95801C15.2845 9.95801 15.5073 9.7841 15.5811 9.5448C16.2114 7.66672 17.7063 6.17125 19.5841 5.54C19.8248 5.46723 20 5.24359 20 4.97906C20 4.71562 19.8261 4.49281 19.5868 4.41902C17.7087 3.78863 16.2132 2.29375 15.582 0.416016C15.5092 0.175234 15.2856 0 15.021 0C14.7576 0 14.5348 0.173945 14.461 0.413203C13.8306 2.29129 12.3357 3.7868 10.458 4.41805C10.2172 4.49082 10.042 4.71445 10.042 4.97898C10.042 5.24242 10.2159 5.46523 10.4552 5.53902C12.3332 6.16937 13.8286 7.66414 14.4599 9.54168C14.5327 9.78266 14.7563 9.95801 15.021 9.95801ZM12.0776 4.97902C12.6926 4.63828 13.2607 4.21207 13.7574 3.71535C14.2541 3.21863 14.6803 2.65059 15.0211 2.03555C15.3618 2.65059 15.788 3.21863 16.2847 3.71535C16.7814 4.21207 17.3495 4.63828 17.9645 4.97902C17.3495 5.31977 16.7814 5.74598 16.2847 6.2427C15.788 6.73941 15.3618 7.30746 15.0211 7.9225C14.6803 7.30746 14.2541 6.73941 13.7574 6.2427C13.2607 5.74598 12.6926 5.31977 12.0776 4.97902Z"
          fill={strokeColor}
        />
        <path
          id="Vector_3"
          d="M0.412695 3.65609C1.40695 3.9918 2.19895 4.7832 2.53543 5.77703C2.6084 6.01746 2.83188 6.19242 3.09617 6.19242C3.35941 6.19242 3.58211 6.01875 3.65605 5.77973C3.99176 4.78551 4.78312 3.99355 5.77695 3.65699C6.01742 3.58402 6.19238 3.36055 6.19238 3.09625C6.19238 2.83301 6.01871 2.61031 5.77969 2.53637C4.78547 2.20066 3.99352 1.4093 3.65695 0.415469C3.58398 0.174961 3.36051 0 3.09621 0C2.83297 0 2.61027 0.173672 2.53637 0.412695C2.2007 1.40688 1.4093 2.19887 0.41543 2.53543C0.174961 2.6084 0 2.83188 0 3.09621C0 3.35945 0.173711 3.58215 0.412695 3.65609ZM3.09621 1.89094C3.41895 2.36379 3.82859 2.77348 4.30145 3.09617C3.82859 3.41891 3.41891 3.82855 3.09621 4.30141C2.77352 3.82855 2.36383 3.41887 1.89098 3.09617C2.36383 2.77348 2.77352 2.36383 3.09621 1.89094Z"
          fill={strokeColor}
        />
        <path
          id="Vector_4"
          d="M19.5873 16.3439C18.593 16.0082 17.8011 15.2168 17.4646 14.223C17.3916 13.9826 17.1681 13.8076 16.9038 13.8076C16.6406 13.8076 16.4179 13.9813 16.3439 14.2203C16.0082 15.2145 15.2169 16.0065 14.223 16.343C13.9826 16.416 13.8076 16.6395 13.8076 16.9038C13.8076 17.167 13.9813 17.3897 14.2203 17.4637C15.2145 17.7994 16.0065 18.5907 16.343 19.5846C16.416 19.825 16.6395 20 16.9038 20C17.167 20 17.3897 19.8263 17.4636 19.5873C17.7993 18.5931 18.5907 17.8011 19.5846 17.4646C19.825 17.3916 20 17.1681 20 16.9038C20 16.6406 19.8263 16.4179 19.5873 16.3439ZM16.9038 18.1091C16.5811 17.6363 16.1714 17.2266 15.6986 16.9039C16.1714 16.5811 16.5811 16.1715 16.9038 15.6986C17.2265 16.1715 17.6362 16.5812 18.109 16.9039C17.6362 17.2266 17.2265 17.6362 16.9038 18.1091Z"
          fill={strokeColor}
        />
      </g>
    </svg>
  </Box>
);

const Person = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </svg>
  </Box>
);

const Home = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  </Box>
);

const Exit = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

const Next = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const Careers = ({ width, fillColor, strokeColor, strokeWidth }: Props) => (
  <Box sx={{ width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
    >
      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
    </svg>
  </Box>
);

const Programs = ({ width, strokeColor }: Props) => (
  <Box sx={{ width: width }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.0 -16.0 100.0 135.0"
      fill={strokeColor}
    >
      <path
        stroke={strokeColor}
        strokeWidth={1}
        d="m86.496 37.094-32.57-17.324c-3.1523-1.6328-6.9062-1.5977-10.027 0.09375l-30.57 17.09v0.003906c-2.543 1.2031-4.1484 3.7812-4.1094 6.5977 0.035156 2.8125 1.7109 5.3477 4.2852 6.4844l12.719 6.7656v11.395c0.003906 2.957 1.5977 5.6797 4.1719 7.1289 5.5781 3.1445 11.828 4.9102 18.227 5.1523 0.45703 0.015625 0.91016 0.023437 1.3594 0.023437 6.8594-0.023437 13.602-1.8008 19.586-5.1602 2.5938-1.4453 4.207-4.1797 4.2109-7.1484v-10.812l6.3906-3.5742v2.9492c-0.99609 0.53906-1.6484 1.5508-1.7266 2.6836-0.074218 1.1328 0.43359 2.2227 1.3516 2.8906l-2.4648 4.2695c2.5469 2.1523 6.2773 2.1523 8.8281 0l-2.4648-4.2695c0.91406-0.66797 1.4258-1.7578 1.3477-2.8906-0.074218-1.1328-0.72656-2.1445-1.7227-2.6836v-4.707l3.3555-1.875c2.543-1.207 4.1484-3.7812 4.1094-6.5977-0.035156-2.8125-1.7109-5.3477-4.2852-6.4844zm-16.914 31.102c-0.003906 1.4492-0.79297 2.7812-2.0625 3.4844-10.875 6.1758-24.195 6.1719-35.066-0.007813-1.2578-0.70312-2.0352-2.0312-2.0352-3.4727v-9.1641l4.2695 2.2734 11.375 6.1133c3.1562 1.6523 6.9258 1.6172 10.047-0.09375l10.715-6.0547 2.7578-1.543zm15.039-21.68-10.844 6.0625v-0.054687l-9.0352 5.1055-10.691 5.9766h0.003907c-1.875 0.99609-4.1211 1.0156-6.0117 0.054687l-11.371-6.0508-10.449-5.6172v0.058593l-10.75-5.7148c-1.1641-0.5-1.957-1.6016-2.0586-2.8633 0.074219-1.2422 0.82813-2.3438 1.9609-2.8555l30.57-17.09c1.875-1 4.1211-1.0195 6.0117-0.054688l32.566 17.324h0.003906c1.1641 0.49609 1.957 1.5977 2.0586 2.8594-0.070313 1.2422-0.82813 2.3438-1.9609 2.8594z"
      />
    </svg>
  </Box>
);

const Location = ({ width, height, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 37 36"
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M18.5 2C12.1487 2 7 7.14873 7 13.5C7 20.9652 18.5 34 18.5 34C18.5 34 30 20.9652 30 13.5C30 7.14873 24.8513 2 18.5 2ZM18.5 19C15.7386 19 13.5 16.7614 13.5 14C13.5 11.2386 15.7386 9 18.5 9C21.2614 9 23.5 11.2386 23.5 14C23.5 16.7614 21.2614 19 18.5 19Z" />
  </svg>
);

const Mail = ({ width, height, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="-3 -3 30 30"
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      d="M2 4H22C22.5523 4 23 4.44772 23 5V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V5C1 4.44772 1.44772 4 2 4Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path d="M2 4L12 13L22 4" stroke={strokeColor} strokeWidth={strokeWidth} />
  </svg>
);

const Phone = ({ width, height, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <rect
      x="5"
      y="2"
      width="14"
      height="20"
      rx="2"
      ry="2"
      stroke={strokeColor}
      strokeWidth="1.5"
      fill={fillColor}
    />
    <circle cx="12" cy="19" r="0.5" fill={strokeColor} />
  </svg>
);

const ChevronRight = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 18l6-6-6-6" />
  </svg>
);

const ArrowRight = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55813 3.72475C9.8022 3.48067 10.1979 3.48067 10.442 3.72474L16.2754 9.55806C16.3926 9.67527 16.4584 9.83425 16.4584 10C16.4584 10.1658 16.3926 10.3247 16.2754 10.4419L10.442 16.2753C10.1979 16.5194 9.8022 16.5194 9.55813 16.2753C9.31405 16.0312 9.31405 15.6355 9.55813 15.3914L14.3245 10.625H4.16675C3.82157 10.625 3.54175 10.3452 3.54175 10C3.54175 9.65483 3.82157 9.37501 4.16675 9.37501H14.3245L9.55813 4.60863C9.31405 4.36455 9.31405 3.96882 9.55813 3.72475Z"
    />
  </svg>
);

const ArrowLeft = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.442 3.72468C10.686 3.96876 10.686 4.36449 10.442 4.60857L5.67557 9.37495H15.8334C16.1785 9.37495 16.4584 9.65477 16.4584 9.99995C16.4584 10.3451 16.1785 10.6249 15.8334 10.6249H5.67557L10.442 15.3914C10.686 15.6354 10.686 16.0312 10.442 16.2752C10.1979 16.5193 9.80216 16.5193 9.55808 16.2752L3.72474 10.4419C3.60753 10.3247 3.54169 10.1657 3.54169 9.99995C3.54169 9.83419 3.60754 9.67521 3.72475 9.558L9.55808 3.72468C9.80216 3.48061 10.1979 3.48061 10.442 3.72468Z"
    />
  </svg>
);

const Target = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 6.74998C9.10049 6.74998 6.74998 9.10049 6.74998 12C6.74998 14.8995 9.10049 17.25 12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.10049 14.8995 6.74998 12 6.74998ZM5.24998 12C5.24998 8.27206 8.27206 5.24998 12 5.24998C15.7279 5.24998 18.75 8.27206 18.75 12C18.75 15.7279 15.7279 18.75 12 18.75C8.27206 18.75 5.24998 15.7279 5.24998 12ZM12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6903 11.3096 13.25 12 13.25C12.6903 13.25 13.25 12.6903 13.25 12C13.25 11.3096 12.6903 10.75 12 10.75ZM9.24997 12C9.24997 10.4812 10.4812 9.24997 12 9.24997C13.5188 9.24997 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.24997 13.5188 9.24997 12Z"
    />
  </svg>
);

const Star = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C12.2855 1.25 12.5462 1.41205 12.6726 1.66803L15.5882 7.57485L22.1085 8.52788C22.3909 8.56916 22.6254 8.76717 22.7135 9.03867C22.8015 9.31018 22.7278 9.60812 22.5233 9.80727L17.8059 14.402L18.9192 20.8932C18.9675 21.1746 18.8518 21.459 18.6208 21.6268C18.3898 21.7946 18.0836 21.8167 17.8309 21.6838L12 18.6174L6.16911 21.6838C5.91642 21.8167 5.6102 21.7946 5.37922 21.6268C5.14824 21.459 5.03255 21.1746 5.08082 20.8932L6.19413 14.402L1.47672 9.80727C1.27226 9.60812 1.19855 9.31018 1.28659 9.03867C1.37462 8.76717 1.60913 8.56916 1.89155 8.52788L8.41183 7.57485L11.3275 1.66803C11.4538 1.41205 11.7146 1.25 12 1.25ZM12 3.69444L9.58255 8.59197C9.4734 8.8131 9.26251 8.96645 9.01849 9.00211L3.61133 9.79245L7.52332 13.6027C7.70023 13.775 7.78097 14.0234 7.73923 14.2668L6.81616 19.6488L11.6509 17.1062C11.8695 16.9913 12.1306 16.9913 12.3491 17.1062L17.1839 19.6488L16.2608 14.2668C16.2191 14.0234 16.2998 13.775 16.4767 13.6027L20.3887 9.79245L14.9816 9.00211C14.7375 8.96645 14.5266 8.8131 14.4175 8.59197L12 3.69444Z"
      fill="#FF7E14"
    />
  </svg>
);

const DollarSign = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={strokeColor}
    strokeWidth={0.5}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.249969C12.4142 0.249969 12.75 0.585756 12.75 0.999969V4.25H17C17.4142 4.25 17.75 4.58579 17.75 5C17.75 5.41422 17.4142 5.75 17 5.75H12.75V11.25H14.5C15.6272 11.25 16.7082 11.6978 17.5052 12.4948C18.3022 13.2918 18.75 14.3728 18.75 15.5C18.75 16.6272 18.3022 17.7082 17.5052 18.5052C16.7082 19.3022 15.6272 19.75 14.5 19.75H12.75V23C12.75 23.4142 12.4142 23.75 12 23.75C11.5858 23.75 11.25 23.4142 11.25 23V19.75H6C5.58579 19.75 5.25 19.4142 5.25 19C5.25 18.5858 5.58579 18.25 6 18.25H11.25V12.75H9.5C8.37283 12.75 7.29183 12.3022 6.4948 11.5052C5.69777 10.7082 5.25 9.62717 5.25 8.5C5.25 7.37283 5.69777 6.29183 6.4948 5.4948C7.29183 4.69777 8.37283 4.25 9.5 4.25H11.25V0.999969C11.25 0.585756 11.5858 0.249969 12 0.249969ZM11.25 5.75H9.5C8.77065 5.75 8.07118 6.03973 7.55546 6.55546C7.03973 7.07118 6.75 7.77066 6.75 8.5C6.75 9.22935 7.03973 9.92882 7.55546 10.4445C8.07118 10.9603 8.77065 11.25 9.5 11.25H11.25V5.75ZM12.75 12.75V18.25H14.5C15.2293 18.25 15.9288 17.9603 16.4445 17.4445C16.9603 16.9288 17.25 16.2293 17.25 15.5C17.25 14.7707 16.9603 14.0712 16.4445 13.5555C15.9288 13.0397 15.2293 12.75 14.5 12.75H12.75Z"
      fill={strokeColor}
    />
  </svg>
);

const TrendingUp = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path d="M23 6L13.5 15.5L8.5 10.5L1 18" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 6H23V12" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Users = ({ width, height, viewBox, fillColor, strokeColor, strokeWidth }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00003 3.75C7.20511 3.75 5.75003 5.20507 5.75003 7C5.75003 8.79493 7.20511 10.25 9.00003 10.25C10.795 10.25 12.25 8.79493 12.25 7C12.25 5.20507 10.795 3.75 9.00003 3.75ZM4.25003 7C4.25003 4.37665 6.37668 2.25 9.00003 2.25C11.6234 2.25 13.75 4.37665 13.75 7C13.75 9.62335 11.6234 11.75 9.00003 11.75C6.37668 11.75 4.25003 9.62335 4.25003 7ZM15.2734 2.944C15.3762 2.54273 15.7848 2.30073 16.186 2.40347C17.2078 2.66508 18.1134 3.2593 18.7601 4.09246C19.4068 4.92562 19.7578 5.95033 19.7578 7.00503C19.7578 8.05974 19.4068 9.08444 18.7601 9.9176C18.1134 10.7508 17.2078 11.345 16.186 11.6066C15.7848 11.7093 15.3762 11.4673 15.2734 11.0661C15.1707 10.6648 15.4127 10.2562 15.814 10.1535C16.5131 9.97448 17.1327 9.5679 17.5752 8.99784C18.0177 8.42778 18.2578 7.72667 18.2578 7.00503C18.2578 6.28339 18.0177 5.58228 17.5752 5.01222C17.1327 4.44216 16.5131 4.03559 15.814 3.8566C15.4127 3.75385 15.1707 3.34527 15.2734 2.944ZM1.64124 15.6412C2.53204 14.7504 3.74022 14.25 5 14.25H13C14.2598 14.25 15.468 14.7504 16.3588 15.6412C17.2496 16.532 17.75 17.7402 17.75 19V21C17.75 21.4142 17.4142 21.75 17 21.75C16.5858 21.75 16.25 21.4142 16.25 21V19C16.25 18.138 15.9076 17.3114 15.2981 16.7019C14.6886 16.0924 13.862 15.75 13 15.75H5C4.13805 15.75 3.3114 16.0924 2.7019 16.7019C2.09241 17.3114 1.75 18.138 1.75 19V21C1.75 21.4142 1.41421 21.75 1 21.75C0.585786 21.75 0.25 21.4142 0.25 21V19C0.25 17.7402 0.750445 16.532 1.64124 15.6412ZM19.2738 14.9425C19.3774 14.5415 19.7865 14.3003 20.1875 14.4038C21.2066 14.667 22.1094 15.2611 22.7542 16.0928C23.399 16.9246 23.7492 17.947 23.75 18.9995V21C23.75 21.4142 23.4142 21.75 23 21.75C22.5858 21.75 22.25 21.4142 22.25 21V19.0006C22.2495 18.2805 22.0098 17.581 21.5686 17.0118C21.1275 16.4427 20.5098 16.0362 19.8125 15.8562C19.4115 15.7527 19.1703 15.3436 19.2738 14.9425Z"
    />
  </svg>
);

const FeatherIcon = ({
  fillColor = "none",
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  strokeColor = "currentColor",
  strokeWidth = "2",
  icon = "home",
}: Props) => {
  return (
    <>
      {icon === "clipboard" && (
        <Clipboard
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "message-circle" && (
        <Message
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "list" && (
        <List
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "settings" && (
        <Settings
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "add" && (
        <Add
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "edit" && (
        <Edit
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "pencil" && (
        <Pencil
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "phone-off" && (
        <PhoneOff
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "star" && (
        <Star
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "slash" && (
        <Slash
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "clock" && (
        <Clock
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "thumb-down" && (
        <ThumbDown
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "trash" && (
        <Trash
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "heart" && (
        <Heart
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "search" && (
        <Search
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "info" && (
        <Info
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "close" && (
        <Close
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "filter" && (
        <Filter
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "dollar-sign" && (
        <DollarSign
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "download" && (
        <Download
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "thumbs-up" && (
        <ThumbsUp
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "circle-check" && (
        <CircleCheck
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "x" && (
        <X
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "ai" && (
        <AI
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "db" && (
        <DB
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "wallet" && (
        <Wallet
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "person" && (
        <Person
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "home" && (
        <Home
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "exit" && (
        <Exit
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "careers" && (
        <Careers
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "programs" && (
        <Programs
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "next" && <Next />}
      {icon === "location" && (
        <Location
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "mail" && (
        <Mail
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "phone" && (
        <Phone
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "chevron-right" && (
        <ChevronRight
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "arrow-right" && (
        <ArrowRight
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "arrow-left" && (
        <ArrowLeft
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "target" && (
        <Target
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "trending-up" && (
        <TrendingUp
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "users" && (
        <Users
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
      {icon === "bookmark" && (
        <Bookmark
          fillColor={fillColor}
          width={width}
          height={height}
          viewBox={viewBox}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
        />
      )}
    </>
  );
};

export default FeatherIcon;
