import { Box, List, ListItemButton, Typography } from "@mui/material";
import React, { Dispatch } from "react";
import { Color, QuizResultsOption } from "../../types/enums";
import { personalityTypeMenuItems } from "../../utils/quizUtils";

type Props = {
  selectedOption: QuizResultsOption;
  setSelectedOption: Dispatch<React.SetStateAction<QuizResultsOption>>;
};

const QuizResultsSideNavigation = ({ selectedOption, setSelectedOption }: Props) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ pl: 2, pb: 2 }}>
        Explore Your Personality
      </Typography>
      <List>
        {personalityTypeMenuItems.map((item, index) => (
          <ListItemButton
            key={index}
            selected={selectedOption === item}
            onClick={() => setSelectedOption(item)}
            sx={{
              "&.Mui-selected": {
                backgroundColor: Color.GREEN_700,
              },
              padding: "14px",
              borderBottom: "1px solid",
              borderColor: "#E0E0E0",
              boxShadow: selectedOption === item ? `4px 0px 0px 0px ${Color.MINT} inset` : "none",
            }}
          >
            <Typography
              variant="body1"
              fontSize="16px"
              fontWeight={selectedOption === item ? "bold" : "normal"}
              color={selectedOption === item ? Color.BLACK : Color.DARK_GRAY}
            >
              {item}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export default QuizResultsSideNavigation;
