import { Grid, TextField } from "@mui/material";
import { Field, FieldProps } from "formik";
import { ChangeEvent, useState } from "react";

type CurrencyTextFieldProps = FieldProps & React.ComponentProps<typeof TextField>;

const CurrencyTextField = ({ field, form, ...props }: CurrencyTextFieldProps) => {
  const [value, setValue] = useState(field.value || "");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Strip out all non-numeric characters, including commas and decimals
    const numericValue = inputValue.replace(/[^\d]/g, "");

    // Convert numericValue to a number before formatting
    const numberValue = parseInt(numericValue, 10) || 0; // Default to 0 if invalid

    // Format the number as currency
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(numberValue);

    // Update the state and Formik field
    setValue(formattedValue);
    form.setFieldValue(field.name, numericValue); // Store the unformatted numeric value in Formik state
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      error={Boolean(form.errors[field.name] && form.touched[field.name])}
      helperText={
        form.errors[field.name] && form.touched[field.name] ? String(form.errors[field.name]) : ""
      }
    />
  );
};

const AwardAmountField = () => {
  return (
    <Grid item xs={12}>
      <Field name="awardAmount" label="Award Amount" component={CurrencyTextField} fullWidth />
    </Grid>
  );
};

export default AwardAmountField;
