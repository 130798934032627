import { Dispatch, SetStateAction } from "react";
import { PersonalityTypeRecord } from "../../types/types";
import { Box } from "@mui/material";
import Introduction from "./Introduction";
import HowYouCommunicate from "./HowYouCommunicate";
import YourWorkStyle from "./YourWorkStyle";
import RelationshipTips from "./RelationshipTips";
import RecommendedCareersToExplore from "./RecommendedCareersToExplore";
import CollegeMajorsYouMightEnjoy from "./CollegeMajorsYouMightEnjoy";
import ProfessionalProgramsToConsider from "./ProfessionalProgramsToConsider";
import PrioritizedPersonalDevelopmentGoals from "./PrioritizedPersonalDevelopmentGoals";
import StudyTips from "./StudyTips";
import InspirationalQuotesFromFamousMentors from "./InspirationalQuotesFromFamousMentors";
import { Color, QuizResultsOption } from "../../types/enums";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";

type Props = {
  personalityType: PersonalityTypeRecord;
  selectedOption: QuizResultsOption;
  setSelectedOption: Dispatch<SetStateAction<QuizResultsOption>>;
};

const QuizResultsContainer = ({ personalityType, selectedOption, setSelectedOption }: Props) => {
  const { width } = useWindowDimensions();

  const mobile = width < 900;

  if (!personalityType) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        padding: mobile ? 0 : "8px",
        gap: mobile ? 0 : "8px",
        backgroundColor: Color.GRAY_50,
      }}
    >
      <Box sx={{ px: "20px", width: "calc(100% - 40px)", maxWidth: mobile ? "100%" : "820px" }}>
        {selectedOption === QuizResultsOption.INTRODUCTION && (
          <Introduction
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.HOW_YOU_COMMUNICATE && (
          <HowYouCommunicate
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.YOUR_WORK_STYLE && (
          <YourWorkStyle
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.RELATIONSHIP_TIPS && (
          <RelationshipTips
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.RECOMMENDED_CAREERS_TO_EXPLORE && (
          <RecommendedCareersToExplore
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.COLLEGE_MAJORS_YOU_MIGHT_ENJOY && (
          <CollegeMajorsYouMightEnjoy
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.PROFESSIONAL_PROGRAMS_TO_CONSIDER && (
          <ProfessionalProgramsToConsider
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.PRIORITIZED_PERSONAL_DEVELOPMENT_GOALS && (
          <PrioritizedPersonalDevelopmentGoals
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.STUDY_TIPS && (
          <StudyTips
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
        {selectedOption === QuizResultsOption.INSPIRATIONAL_QUOTES_FROM_FAMOUS_MENTORS && (
          <InspirationalQuotesFromFamousMentors
            mobile={mobile}
            personalityType={personalityType}
            onActionButtonClick={(value) => setSelectedOption(value)}
          />
        )}
      </Box>
    </Box>
  );
};

export default QuizResultsContainer;
