import { Dispatch } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useEditStudentCardDialog from "../../hooks/profile/useEditStudentCardDialog";
import { Field, Form, Formik } from "formik";
import FormikAddress from "../../../shared/components/googleAddress/FormikAddress";
import FormikPhone from "../../../shared/components/phone/FormikPhone";
import { formatLocation, formatPhoneNumber } from "../../../shared/utils/formatUtils";
import { useRecoilValue } from "recoil";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const EditStudentCardDialog = ({ open, setOpen }: Props) => {
  const { loading, handleSave, handleClose, initialValues, validationSchema, gradeLevels } =
    useEditStudentCardDialog({ open, setOpen });
  const schools = useRecoilValue(allSchoolsAtom);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h3"
        sx={{ textAlign: "center", backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}
      >
        Edit Personal Info
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({ values, handleChange, handleBlur, isValid, errors, touched }) => (
          <Form>
            <DialogContent sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}>
              <Typography variant="body1">First Name</Typography>
              <Field
                as={TextField}
                value={values.firstName}
                fullWidth
                variant="outlined"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Last Name
              </Typography>
              <Field
                as={TextField}
                value={values.lastName}
                fullWidth
                variant="outlined"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Phone Number
              </Typography>
              <Field
                as={FormikPhone}
                value={formatPhoneNumber({ phoneNumber: values.phone })}
                name="phone"
                variant="standard"
                fullWidth
                required
                margin="normal"
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Location
              </Typography>
              <Field
                as={FormikAddress}
                value={formatLocation({ location: values.address })}
                name="address"
                variant="standard"
                fullWidth
                required
                margin="normal"
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address?.address}
              />
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                School
              </Typography>
              <Field
                as={Select}
                name="schoolId"
                value={values.schoolId}
                fullWidth
                id="schoolId"
                error={touched.schoolId && Boolean(errors.schoolId)}
              >
                {schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Field>
              {touched.schoolId && Boolean(errors.schoolId) ? (
                <FormHelperText error>{errors.schoolId}</FormHelperText>
              ) : null}
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Grade Level
              </Typography>
              <Field
                as={Select}
                name="gradeLevel"
                value={values.gradeLevel}
                fullWidth
                id="gradeLevel"
                error={touched.gradeLevel && Boolean(errors.gradeLevel)}
              >
                {gradeLevels.map((gradeLevel) => (
                  <MenuItem key={gradeLevel} value={gradeLevel}>
                    {gradeLevel}
                  </MenuItem>
                ))}
              </Field>
              {touched.gradeLevel && Boolean(errors.gradeLevel) ? (
                <FormHelperText error>{errors.gradeLevel}</FormHelperText>
              ) : null}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}>
              <Button
                variant="contained"
                disabled={!isValid || loading}
                type="submit"
                startIcon={<FeatherIcon icon="thumbs-up" width="16px" />}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<FeatherIcon icon="x" width="16px" />}
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditStudentCardDialog;
