import React, { Dispatch } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { FilterProps } from "../../../../shared/types/types";
import { Color } from "../../../../shared/types/enums";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const FocusFilter = ({ filters, setFilters }: Props) => {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    setFilters((pV) => ({ ...pV, [name]: checked }));
  };

  return (
    <>
      <FormControl
        sx={{ px: 2, py: 1 }}
        component="fieldset"
        variant="standard"
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          Focus
        </Typography>
        <FormGroup sx={{ mt: 1, gap: 1.5 }}>
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.hbcuStatus}
                onChange={handleSwitchChange}
                name="hbcuStatus"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="HBCU Only"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.menOnly}
                onChange={handleSwitchChange}
                name="menOnly"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="Men Only"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              <Checkbox
                checked={filters.womenOnly}
                onChange={handleSwitchChange}
                name="womenOnly"
                sx={{
                  color: Color.MINT,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                }}
              />
            }
            label="Women Only"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default FocusFilter;
