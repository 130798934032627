import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { useState } from "react";
import { Color } from "../../../shared/types/enums";
import EditMyWhyDialog from "./EditMyWhyDialog";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

const MyWhy = () => {
  const [open, setOpen] = useState(false);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox title="My Why" titleSX={{ color: Color.CHALKBOARD }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 1,
            height: 80,
            overflowY: "scroll",
          }}
        >
          <Typography variant="body1" color={Color.GRAY_800}>
            {selectedStudent?.myWhy}
          </Typography>
        </Box>
      </DisplayBox>
      <EditMyWhyDialog open={open} setOpen={setOpen} />
    </Box>
  );
};

export default MyWhy;
