import { RefObject } from "react";
import { Box } from "@mui/material";
import QuizResultsContainer from "./QuizResultsContainer";
import ResultsNavigation from "./ResultsNavigation";
import useQuizResultsContainer from "../../hooks/quizResults/useQuizResultsContainer";

type Props = {
  dialogContentRef: RefObject<HTMLDivElement>;
};

const QuizResultsMobile = ({ dialogContentRef }: Props) => {
  const { personalityType, selectedOption, setSelectedOption } = useQuizResultsContainer({ dialogContentRef });
  if (!personalityType) return null;
  return (
    <>
      <Box sx={{ p: 0 }}>
        <ResultsNavigation selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

        <Box sx={{ height: "calc(100VH - 198px)", overflow: "scroll" }}>
          <QuizResultsContainer
            personalityType={personalityType}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
      </Box>
    </>
  );
};

export default QuizResultsMobile;
