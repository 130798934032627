import { StudentRecord, StudentsAllForExport } from "../../shared/types/types";

export const generateStudentExportBlankObj = (student: StudentRecord) => {
    const studentsExportData: StudentsAllForExport = {
      firstName: student.firstName,
      lastName: student.lastName,
      schoolEmail: student.schoolEmail,
      providerName1: "",
      programName1: "",
      program1hearted: "No",
      providerName2: "",
      programName2: "",
      program2hearted: "No",
      providerName3: "",
      programName3: "",
      program3hearted: "No",
      providerName4: "",
      programName4: "",
      program4hearted: "No",
      providerName5: "",
      programName5: "",
      program5hearted: "No",
      providerName6: "",
      programName6: "",
      program6hearted: "No",
    };
    return studentsExportData;
  };