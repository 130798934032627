import React from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import PieChartTooltip from "./PieChartTooltip";
import { Box, Typography } from "@mui/material";
import { ScholarshipStudentTableRow } from "../../../shared/types/types";
import useStudentPieCharts from "../../hooks/scholarships/useStudentPieCharts";

const StudentPieCharts = ({ rows }: { rows: ScholarshipStudentTableRow[] }) => {
  const { applicationData, awardData } = useStudentPieCharts({ rows });

  // Colors for each category
  const COLORS = {
    "No Applications": "#F92F2F",
    "Applied for 1+": "#1DBC9D",
    "Applied for 3+": "#00A3FF",
    "No Awards": "#F92F2F",
    "Awarded 1+": "#1DBC9D",
    "Awarded 3+": "#00A3FF",
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4, gap: 4 }}>
      <Box>
        <Typography variant="h6" align="center">
          Student Scholarship Applications
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={applicationData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
            >
              {applicationData.map((entry) => (
                <Cell key={entry.name} fill={COLORS[entry.name as keyof typeof COLORS]} />
              ))}
            </Pie>
            <Tooltip content={<PieChartTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box>
        <Typography variant="h6" align="center">
          Student Scholarship Awards
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={awardData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
            >
              {awardData.map((entry) => (
                <Cell key={entry.name} fill={COLORS[entry.name as keyof typeof COLORS]} />
              ))}
            </Pie>
            <Tooltip content={<PieChartTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default StudentPieCharts;
