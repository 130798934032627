import { createContext, useContext } from "react";

type Props = {
  loading: boolean;
};

export const StudentAlmaDataContext = createContext<Props>({ loading: true });

export const useAlmaDataContext = () => {
  return useContext(StudentAlmaDataContext);
};
