import { LogSeverity, LogEventType } from "../../types/logEnums";
import { fetchData } from "../../utils/fetchUtils";
import { Log } from "../../types/types";
import { useAuth } from "../../contexts/AuthContext";
import { FunctionName } from "../../types/enums";
import { useCallback } from "react";
import * as Sentry from "@sentry/react";
import { errorMessageAtom } from "../../recoil/errorAtoms";
import { useSetRecoilState } from "recoil";

type SubmitLogProps = {
  severity?: LogSeverity;
  eventType: LogEventType;
  changeLog?: string;
  error?: Error | null | unknown;
  file: string;
  snackbarMessage?: string | null;
};

const useLogger = () => {
  const { currentAuthUser } = useAuth();
  const setErrorMessage = useSetRecoilState(errorMessageAtom);

  const submitLog = useCallback(
    async ({
      eventType,
      changeLog = "",
      error = null,
      file,
      snackbarMessage = null,
    }: SubmitLogProps) => {
      const isError = !!error;
      const severity = isError ? LogSeverity.ERROR : LogSeverity.INFO;
      const formattedError =
        error instanceof Error ? error : new Error("An unknown error occurred");
      try {
        if (!currentAuthUser?.uid) {
          console.warn("No user ID available, skipping log");
          return; // Optionally handle missing user IDs
        }

        await fetchData({
          functionName: FunctionName.EVENT_LOGGER,
          payload: {
            severity: error ? LogSeverity.ERROR : severity,
            eventType,
            changeLog,
            error: isError ? formattedError : undefined,
            file,
            timestamp: new Date().toISOString(),
            userId: currentAuthUser.uid,
          } as Log,
        });
        if (error) {
          console.log("Calling Sentry");
          Sentry.captureException(formattedError);
        }
      } catch (err) {
        console.error("Error logging event: ", err);
        if (snackbarMessage) {
          setErrorMessage(snackbarMessage);
        }
      }
    },
    [currentAuthUser?.uid, setErrorMessage]
  );

  return { submitLog };
};

export default useLogger;
