import { Paper, Container, Box } from "@mui/material";
import Copyright from "./Copyright";
import LoginForm from "./LoginForm";
import logo from "../../assets/branding/willow-logo.svg";
import useLoginContainer from "../../hooks/login/useLoginContainer";

const LoginContainer = () => {
  const { mobile, currentAuthUser } = useLoginContainer();
  return (
    <Container maxWidth="sm" sx={{ pt: !mobile ? "calc(4VH)" : 2 }}>
      <>
        {!currentAuthUser && (
          <>
            <Box sx={{ margin: "0 auto", textAlign: "center" }}>
              <img style={{ width: "60%" }} src={logo} alt="Willow" />
            </Box>
            <Paper
              sx={{
                mt: !mobile ? "calc(2VH)" : 2,
                pt: !mobile ? "calc(1VH)" : 1,
                pl: 4,
                pr: 4,
                pb: !mobile ? 3 : 3,
                backgroundColor: "rgb(244,240,220)",
                borderRadius: 8,
              }}
            >
              <LoginForm />
            </Paper>
          </>
        )}
      </>
      <Box sx={{ mt: !mobile ? 8 : 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginContainer;
