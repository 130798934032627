import { GetRecoilValue, atom, selector } from "recoil";
import { allSchoolStudentsAtom } from "./studentsAtoms";
import { StaffDashboardStudentRow } from "../../shared/types/types";
import { GradeLevel } from "../../shared/types/enums";
import { staffUsersAtom } from "../../shared/recoil/userAtoms";

type StudentFilter = {
  search: string;
  stages: string[];
  personalityTypes: string[];
  mostInterestedPathways: string[];
  lastActivity: number;
  gpa: string[];
  grades: string[];
};

const getGrade = (gradeLevel: string, graduationYear: string, birthday: string) => {
  if (gradeLevel) {
    if (gradeLevel === "9") {
      return GradeLevel.NINTH;
    } else if (gradeLevel === "10") {
      return GradeLevel.TENTH;
    } else if (gradeLevel === "11") {
      return GradeLevel.ELEVENTH;
    } else if (gradeLevel === "12") {
      return GradeLevel.TWELFTH;
    } else {
      return gradeLevel as GradeLevel;
    }
  }
  if (graduationYear) {
    if (graduationYear === "2024") return GradeLevel.TWELFTH;
    if (graduationYear === "2025") return GradeLevel.ELEVENTH;
    if (graduationYear === "2026") return GradeLevel.TENTH;
    if (graduationYear === "2027") return GradeLevel.NINTH;
  }
  if (birthday === "") return null;
  const twelfthStartDate = new Date("2005-10-01T00:00:00Z");
  const twelfthEndDate = new Date("2006-09-30T23:59:59Z");
  const eleventhStartDate = new Date("2006-10-01T00:00:00Z");
  const eleventhEndDate = new Date("2007-09-30T23:59:59Z");
  const tenthStartDate = new Date("2007-10-01T00:00:00Z");
  const tenthEndDate = new Date("2008-09-30T23:59:59Z");
  const birthdayDate = new Date(birthday);
  if (birthdayDate >= twelfthStartDate && birthdayDate <= twelfthEndDate) return GradeLevel.TWELFTH;
  if (birthdayDate >= eleventhStartDate && birthdayDate <= eleventhEndDate)
    return GradeLevel.ELEVENTH;
  if (birthdayDate >= tenthStartDate && birthdayDate <= tenthEndDate) return GradeLevel.TENTH;
  return null;
};

const studentRowsGetter = ({ get }: { get: GetRecoilValue }) => {
  const students = get(allSchoolStudentsAtom);
  const studentFilters = get(studentFiltersAtom);
  const staff = get(staffUsersAtom);
  const staffEmails = staff.map((s) => s.email);
  //   const applicationData = get(allStudentApplicationDataAtom);

  //filter out students not meeting filter criteria before building new row object
  const studentsForFilter = students.filter((student) => {
    const { firstName, lastName, lastActivity, lastUpdatedAt, email } = student;
    if (staffEmails.includes(email)) return false;
    const searchText = studentFilters.search.toLowerCase();
    const studentLastActivity = lastActivity || lastUpdatedAt;
    const studentGPAValue = student.gpaValue ? Number(student.gpaValue) : null;
    const studentGPAMax = student.gpaMax ? Number(student.gpaMax) : null;
    const GPAInPercentage =
      studentGPAValue && studentGPAMax ? (studentGPAValue / studentGPAMax) * 100 : null;
    const combinedName = `${firstName} ${lastName}`.toLowerCase();

    if (studentFilters.search && !combinedName.includes(searchText)) {
      return false;
    }

    if (studentFilters.stages.length > 0) {
      const stageResult =
        (studentFilters.stages.includes("not-finished-quiz") && !student.quizComplete) ||
        (studentFilters.stages.includes("finished-quiz") && student.quizComplete) ||
        (studentFilters.stages.includes("bookmarked-careers") &&
          student.favoriteJobIds.length > 0) ||
        (studentFilters.stages.includes("bookmarked-programs") &&
          student.favoriteProgramIds.length > 0);

      if (!stageResult) return false;
    }

    if (
      studentLastActivity &&
      Date.now() - new Date(studentLastActivity).getTime() > studentFilters.lastActivity
    ) {
      return false;
    }

    const grade = getGrade(student.gradeLevel, student.graduationYear, student?.birthday ?? "");
    //remove all students that's grade are not in the grade filter item grades, unless filter.grades is empty
    if (
      studentFilters.grades.length > 0 &&
      (grade === null || !studentFilters.grades.includes(grade))
    ) {
      return false;
    }

    if (Array.isArray(studentFilters.gpa) && studentFilters.gpa.length === 0) return true;
    if (GPAInPercentage === null) return false;

    return studentFilters.gpa.some((gpaValue) => {
      switch (gpaValue) {
        case "90up":
          return GPAInPercentage >= 90;
        case "75to89":
          return GPAInPercentage >= 75 && GPAInPercentage < 90;
        case "50to74":
          return GPAInPercentage >= 50 && GPAInPercentage < 75;
        case "below50":
          return GPAInPercentage < 50;
        default:
          return false;
      }
    });
  });

  const parsedRows = studentsForFilter.map((student) => {
    const personalityType = student.personalityType;

    return {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      gpaValue: student.gpaValue,
      gpaMax: student.gpaMax,
      grade: getGrade(student.gradeLevel, student.graduationYear, student?.birthday ?? ""),
      phone: student.phone,
      lastActivity: student.lastActivity ? student.lastActivity : student.lastUpdatedAt,
      avatarURL: student.avatarURL,
      bookmarkedPrograms: student.favoriteProgramIds.length,
      bookmarkedJobs: student.favoriteJobIds.length,
      avatar: student.avatar ?? "",
      personalityType: personalityType ? personalityType : "",
      myWhy: student.myWhy,
    };
  });
  return parsedRows as StaffDashboardStudentRow[];
};

export const studentRowsAtom = selector({
  key: "studentRows",
  get: studentRowsGetter,
});

export const studentFiltersAtom = atom<StudentFilter>({
  key: "studentFilters",
  default: {
    search: "",
    stages: [],
    personalityTypes: [],
    mostInterestedPathways: [],
    lastActivity: 999999999999,
    gpa: [],
    grades: [],
  },
});
