import ExploreProgramsContainer from "../components/explorePrograms/ExploreProgramsContainer";

const StaffExploreProgramsPage = () => {
  return (
    <>
      <ExploreProgramsContainer />
    </>
  );
};

export default StaffExploreProgramsPage;
