import { SyntheticEvent, useEffect, useState } from "react";
import { Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import { useRecoilState } from "recoil";
import { errorMessageAtom } from "../../recoil/errorAtoms";

const ErrorSnackbar = () => {
  // Control Snackbar state if no closing mechanism is needed
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageAtom);

  useEffect(() => {
    setOpen(!!errorMessage);
  }, [errorMessage]);

  const handleClose = (_: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason && reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMessage(null);
  };

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity="error" onClose={handleClose}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
