import { atom, selector } from "recoil";
import { StaffAlmaChatRecord } from "../../shared/types/types";

export const staffAlmaChatsAtom = atom<StaffAlmaChatRecord[]>({
  key: "staffAlmaChats",
  default: [],
});

export const currentStaffAlmaChatAtom = selector<StaffAlmaChatRecord | null>({
  key: "currentStaffAlmaChat",
  get: ({ get }) => {
    const staffAlmaChats = get(staffAlmaChatsAtom);
    return staffAlmaChats.find((chat) => chat.current === true) || null;
  },
});
