import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";

type Props = {
  information: {
    title: string;
    description: string;
  };
};

const WhatIsThat = ({ information }: Props) => {
  const [open, setOpen] = useState(false);

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  }

  return (
    <>
      <IconButton
        onClick={handleIconClick}
        sx={{ height: "12px", ":hover": { backgroundColor: "transparent" } }}
      >
        <FeatherIcon icon="info" width="12px" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: "black" }}>{information.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{information.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WhatIsThat;
