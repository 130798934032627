import { Dispatch, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { BLANK_EFC_DATA_FORM } from "../../../shared/utils/blankUtils";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { EFCDataRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import { LogEventType } from "../../../shared/types/logEnums";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";

type Props = {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const useEditFinancialsDialog = ({ setOpen }: Props) => {
  const [efc, setEFC] = useState<number | null>(null);
  const [form, setForm] = useState<EFCDataRecord>(BLANK_EFC_DATA_FORM);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { getFSDoc } = useGetFSDoc();
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  useEffect(() => {
    const getEFCData = async () => {
      if (!loggedInStudent) return;

      const results = await getFSDoc<EFCDataRecord>({
        col: Collection.EFCDATA,
        id: loggedInStudent.id,
      });

      if (results) {
        setForm(results);
        setEFC(loggedInStudent.efc);
      } else {
        setForm({
          state: loggedInStudent.address.state ? loggedInStudent.address.state : "",
          maritalStatus: "married",
          householdSize: undefined,
          childrenUnder18: undefined,
          childrenInCollege: undefined,
          parentIncome: undefined,
          parentUntaxedIncome: undefined,
          parentAssets: undefined,
          studentIncome: undefined,
          studentAssets: undefined,
          ageOfOldestParent: undefined,
          id: "",
          createdAt: "",
          lastUpdatedAt: "",
        });
        setEFC(null);
      }
    };
    getEFCData();
  }, [loggedInStudent, getFSDoc]);

  const handleSave = async (formData: EFCDataRecord, calculatedEFC: number) => {
    if (!loggedInStudent) return;

    try {
      const promises: Promise<void | string | null | Response>[] = [];

      if (formData.id) {
        promises.push(
          updateFSDoc({
            col: Collection.EFCDATA,
            data: { ...formData },
            id: formData.id,
          })
        );
      } else {
        promises.push(
          setFSDoc({
            col: Collection.EFCDATA,
            data: { ...formData, id: loggedInStudent.id },
            id: loggedInStudent.id,
          })
        );
      }
      promises.push(
        updateFSDoc({
          col: Collection.STUDENTS,
          data: { efc: calculatedEFC },
          id: loggedInStudent.id,
        })
      );

      await Promise.all(promises);
      setLoggedInStudent({ ...loggedInStudent, efc: calculatedEFC });
      submitLog({
        eventType: LogEventType.PROFILE_EFC_SUBMITTED,
        file: "useEditFinancialsDialog.ts",
      });
    } catch (error) {
      submitLog({
        error,
        snackbarMessage: "There was an error updating the EFC, please refresh and try again.",
        eventType: LogEventType.PROFILE_EFC_ERROR,
        file: "useEditFinancialsDialog.ts",
      });
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    efc,
    form,
    setForm,
    handleSave,
    handleClose,
  };
};

export default useEditFinancialsDialog;
