import { useEffect, useState } from "react";
import { Avatar, Box, Typography, Grid } from "@mui/material";
import { ProviderProgramRecord } from "../../types/types";
import { formatCurrency, formatPercentage } from "../../utils/formatUtils";
import { getProgramStatImage } from "../../utils/imageUtils";
import WhatIsThat from "../whatIsThat/WhatIsThat";
import programDescriptions from "../../assets/data/programDescriptions.json";

type Props = {
  currentProgram: ProviderProgramRecord;
};

type RenderDurationProps = {
  months: number;
  years: number;
};

const renderDuration = ({ months, years }: RenderDurationProps) => {
  const duration = months / 12 + years;

  if (duration === 0) {
    return "Unknown";
  } else if (duration === 1) {
    return "1 year";
  } else {
    // Check if the duration is an integer
    if (Number.isInteger(duration)) {
      return `${duration} years`;
    } else {
      // Round to two decimal places
      const roundedDuration = Math.round(duration * 100) / 100;

      // If the rounded value has no decimal part, return as integer
      if (Number.isInteger(roundedDuration)) {
        return `${Math.floor(roundedDuration)} years`;
      }

      // Otherwise, return with one or two decimal places
      return `${roundedDuration.toFixed(2).replace(/\.?0+$/, "")} years`;
    }
  }
};

const ProgramStats = ({ currentProgram }: Props) => {
  const formatModality = (modality: string | null, onlineOnly: boolean | null) => {
    if (onlineOnly) {
      return "All Online";
    } else if (modality === "1") {
      return "All In Person";
    } else if (modality === "2") {
      return "A Mix of In Person and Online";
    } else if (modality === "3") {
      return "Can all be done online, but some in person options are available";
    } else {
      return "Unknown";
    }
  };

  const formatTimeCommitment = (timeCommitment: string | null) => {
    if (timeCommitment !== null && timeCommitment !== "null" && timeCommitment !== "NULL") {
      return timeCommitment;
    } else {
      return "Unknown";
    }
  };

  type StatProps = {
    information: { title: string; description: string };
    label: string;
    value: string | number | null;
    icon: string;
    width: number;
    height: number;
  };

  const Stat = ({ information, label, value, icon, width, height }: StatProps) => {
    const [statImage, setStatImage] = useState<string | undefined>(undefined);

    useEffect(() => {
      const loadImage = async () => {
        try {
          const imageModule = await getProgramStatImage({ name: icon });
          setStatImage(imageModule.default);
        } catch (error) {
          console.error("Error loading image:", error);
          setStatImage(undefined);
        }
      };

      loadImage();
    }, [icon]);

    return (
      <>
        <Box
          sx={{
            display: "flex",
            mt: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Avatar src={statImage} variant="square" sx={{ width, height }} />
          <Typography sx={{ textAlign: "left", fontWeight: 700, ml: 1 }}>{`${label}`}</Typography>
          <Typography variant="body1" sx={{ fontSize: 14, color: "#333", ml: 1 }}>
            {value}
          </Typography>
          <WhatIsThat information={information} />
        </Box>
      </>
    );
  };

  const GridItem = ({ children }: { children: JSX.Element }) => (
    <Grid item md={6} sm={12}>
      {children}
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2}>
        <GridItem>
          <Stat
            information={programDescriptions.average_starting_salary}
            icon="average"
            label="Average starting salary"
            width={30}
            height={30}
            value={
              currentProgram.startingSalary
                ? formatCurrency({ amount: currentProgram.startingSalary })
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.average_cost}
            icon="estimated"
            label="Average cost"
            width={30}
            height={30}
            value={
              currentProgram.totalProgramCost !== null
                ? formatCurrency({ amount: currentProgram.totalProgramCost })
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.average_graduation_rate}
            icon="graduate"
            label="Average graduation rate"
            width={30}
            height={30}
            value={
              currentProgram.completionRate
                ? formatPercentage({ num: currentProgram.completionRate })
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.employment_rate}
            icon="employment"
            label="Employment rate"
            width={30}
            height={30}
            value={
              currentProgram.rateOfEmployment
                ? formatPercentage({ num: currentProgram.rateOfEmployment })
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.admission_rate}
            icon="admission"
            label="Admission rate"
            width={30}
            height={30}
            value={
              currentProgram.admissionPercent
                ? formatPercentage({ num: currentProgram.admissionPercent })
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.length_of_program}
            icon="length"
            label="Length of program"
            width={30}
            height={30}
            value={renderDuration({
              months: currentProgram?.durationMonths ?? 0,
              years: currentProgram?.durationYears ?? 0,
            })}
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.time_commitment}
            icon="time"
            label="Time commitment"
            width={30}
            height={28}
            value={formatTimeCommitment(currentProgram.commitment)}
          />
        </GridItem>
        <GridItem>
          <Stat
            information={programDescriptions.format}
            icon="location"
            label="Format"
            width={30}
            height={32}
            value={formatModality(currentProgram.modality, currentProgram.onlineOnly)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default ProgramStats;
