import { Box, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { useState } from "react";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import EditMyWhyDialog from "./EditMyWhyDialog";

const MyWhy = () => {
  const [open, setOpen] = useState(false);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox
        title="My Why"
        actionButton={
          <IconButton
            sx={{ color: Color.STANDARD_GRAY }}
            disableRipple
            onClick={() => setOpen(true)}
          >
            <FeatherIcon width="30" icon="edit" />
          </IconButton>
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography sx={{ color: "#000" }} variant="body1">
            {loggedInStudent?.myWhy}
          </Typography>
        </Box>
      </DisplayBox>
      <EditMyWhyDialog open={open} setOpen={setOpen} />
    </Box>
  );
};

export default MyWhy;
