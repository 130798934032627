import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Message, StaffRecord } from "../../../shared/types/types";
import { staffUsersAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { formatMessageTime } from "../../../shared/utils/formatUtils";

type Props = {
  message: Message;
};

const SenderMessage = ({ message }: Props) => {
  const staffUsers = useRecoilValue(staffUsersAtom);
  const [senderStaff, setSenderStaff] = useState<StaffRecord>();

  useEffect(() => {
    if (!staffUsers) return;
    setSenderStaff(staffUsers.find((s) => s.id === message.staffId));
  }, [staffUsers, message]);

  return (
    <>
      <Box className="mine messages">
        <Box className="message last">
          <Typography sx={{ fontSize: 14, whiteSpace: "pre-line" }} variant="body1">
            {message?.message || (message as any)?.body}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }} variant="body2">
              {`${senderStaff?.firstName} ${senderStaff?.lastName}`}
            </Typography>
            {message.timestamp && (
              <Typography sx={{ fontSize: 12, fontWeight: 500, color: "#656767" }} variant="body2">
                {formatMessageTime({ timestamp: message.timestamp })}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SenderMessage;
