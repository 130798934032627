import { useMyListContext } from "../../contexts/myListContext";
import JobCard from "../exploreCareers/StudentJobCard";
import { Box, Grid } from "@mui/material";
import JobDialog from "./JobDialog";
import Empty from "./Empty";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const Careers = () => {
  const { jobs } = useMyListContext();
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <>
      <Box
        sx={{ height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 110px)", overflowY: "auto" }}
      >
        <Box sx={{ p: 1 }}>
          {jobs.length === 0 && <Empty />}
          <Grid container spacing={2}>
            {jobs.map((job) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={job.id}>
                <JobCard job={job} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <JobDialog />
    </>
  );
};

export default Careers;
