import { Route, Routes } from "react-router-dom";
import { PageRoute } from "../types/enums";
import Login from "../pages/LoginPage";
import CustomRoute from "./CustomRoute";
import { UserType } from "../types/enums";
import StudentSignupPage from "../../students/pages/StudentSignupPage";
import StudentCreateProfilePage from "../../students/pages/StudentCreateProfilePage";
import StudentProfilePage from "../../students/pages/StudentProfilePage";
import ExploreProgramsPage from "../../students/pages/ExploreProgramsPage";
import ExploreCareersPage from "../../students/pages/ExploreCareersPage";
import StudentAlmaPage from "../../students/pages/StudentAlmaChatPage";
import StudentFeedPage from "../../students/pages/StudentFeedPage";
import StudentTakeQuizPage from "../../students/pages/StudentTakeQuizPage";
import JobPage from "../pages/JobPage";
import StaffDashboardPage from "../../staff/pages/StaffDashboardPage";
import StaffAlmaPage from "../../staff/pages/StaffAlmaPage";
import StaffMessagesPage from "../../staff/pages/StaffMessagesPage";
import StaffProgramListPage from "../../staff/pages/StaffProgramListPage";
import StudentListPage from "../../students/pages/StudentListPage";
import StaffExploreCareersPage from "../../staff/pages/StaffExploreCareersPage";
import StaffExploreProgramsPage from "../../staff/pages/StaffExploreProgramsPage";
import StaffSettingsPage from "../../staff/pages/StaffSettingsPage";
import DemoLoginPage from "../pages/DemoLoginPage";
import UploadVideosPage from "../pages/UploadVideosPage";
import CleverOAuth from "../pages/CleverOAuth";
import StaffScholarshipsPage from "../../staff/pages/StaffScholarshipsPage";
import StudentExploreScholarshipsPage from "../../students/pages/StudentExploreScholarshipsPage";

const all = [
  UserType.PUBLIC,
  UserType.ADMIN,
  UserType.STUDENT,
  UserType.DEVELOPER,
  UserType.MENTOR,
  UserType.PROVIDER,
  UserType.TEACHER,
];

// const staff = [UserType.ADMIN, UserType.DEVELOPER, UserType.TEACHER];
// TODO figure out what / path is for staff, provider, and mentors
export const SiteRoutes = () => {
  return (
    // Public Routes
    <Routes>
      <Route
        path="/upload-videos"
        element={
          <CustomRoute permission={all} path={PageRoute.LOGIN}>
            <UploadVideosPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.LOGIN}
        element={
          <CustomRoute permission={all} path={PageRoute.LOGIN}>
            <Login />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.Clever_Auth}
        element={
          <CustomRoute permission={[UserType.DEVELOPER, UserType.PUBLIC]}>
            <CleverOAuth />
          </CustomRoute>
        }
      />
      {/**************** Student Routes ****************/}
      <Route
        path={PageRoute.STUDENT_SIGNUP}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.PUBLIC]}
            path={PageRoute.STUDENT_SIGNUP}
          >
            <StudentSignupPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_CREATE_PROFILE}
        element={
          <CustomRoute
            permission={[UserType.STUDENT, UserType.DEVELOPER]}
            path={PageRoute.STUDENT_CREATE_PROFILE}
          >
            <StudentCreateProfilePage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_TAKE_QUIZ}
        element={
          <CustomRoute
            permission={[UserType.STUDENT, UserType.DEVELOPER]}
            path={PageRoute.STUDENT_TAKE_QUIZ}
          >
            <StudentTakeQuizPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_PROFILE}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT]}
            path={PageRoute.STUDENT_PROFILE}
          >
            <StudentProfilePage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.FEED}
        element={
          <CustomRoute permission={[UserType.DEVELOPER, UserType.STUDENT]} path={PageRoute.FEED}>
            <StudentFeedPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_MY_LIST}
        element={
          <CustomRoute permission={[UserType.DEVELOPER, UserType.STUDENT]} path={PageRoute.FEED}>
            <StudentListPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_EXPLORE_SCHOLARSHIPS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT]}
            path={PageRoute.STUDENT_EXPLORE_SCHOLARSHIPS}
          >
            <StudentExploreScholarshipsPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STUDENT_ALMA}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT]}
            path={PageRoute.STUDENT_ALMA}
          >
            <StudentAlmaPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.EXPLORE_CAREERS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT, UserType.TEACHER]}
            path={PageRoute.EXPLORE_CAREERS}
          >
            <ExploreCareersPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.EXPLORE_PROGRAMS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT, UserType.TEACHER]}
            path={PageRoute.EXPLORE_PROGRAMS}
          >
            <ExploreProgramsPage />
          </CustomRoute>
        }
      />

      {/**************** Staff Routes ****************/}
      <Route
        path={PageRoute.STAFF_DASHBOARD}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_DASHBOARD}
          >
            <StaffDashboardPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_SCHOLARSHIPS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_SCHOLARSHIPS}
          >
            <StaffScholarshipsPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_ALMA}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_ALMA}
          >
            <StaffAlmaPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_SETTINGS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_SETTINGS}
          >
            <StaffSettingsPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_EXPLORE_CAREERS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_EXPLORE_CAREERS}
          >
            <StaffExploreCareersPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_EXPLORE_PROGRAMS}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_EXPLORE_PROGRAMS}
          >
            <StaffExploreProgramsPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_MESSAGES}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_MESSAGES}
          >
            <StaffMessagesPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.STAFF_PROGRAM_LIST}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.TEACHER]}
            path={PageRoute.STAFF_PROGRAM_LIST}
          >
            <StaffProgramListPage />
          </CustomRoute>
        }
      />

      {/**************** Shared Routes ****************/}

      <Route
        path={PageRoute.JOB}
        element={
          <CustomRoute
            permission={[UserType.DEVELOPER, UserType.STUDENT, UserType.TEACHER]}
            path={PageRoute.JOB}
          >
            <JobPage />
          </CustomRoute>
        }
      />
      <Route
        path={PageRoute.DEMO_LOGIN}
        element={
          <CustomRoute permission={[UserType.PUBLIC]} path={PageRoute.DEMO_LOGIN}>
            <DemoLoginPage />
          </CustomRoute>
        }
      />
      <Route
        path={"/*"}
        element={
          <CustomRoute permission={[UserType.STUDENT]} path={PageRoute.FEED}>
            <StudentFeedPage />
          </CustomRoute>
        }
      />
    </Routes>
  );
};
