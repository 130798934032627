import { useEffect, useMemo, useState } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import fields from "../../assets/data/fields.json";
import FieldCard from "./FieldCard";
import { Field } from "../../types/types";
import industries from "../../assets/data/industries.json";
import { Color } from "../../types/enums";
import { getCareerImage } from "../../utils/imageUtils";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { selectedIndustryIdAtom } from "../../recoil/exploreCareersAtoms";

const Fields = () => {
  const selectedIndustryId = useRecoilValue(selectedIndustryIdAtom);
  const [industryImage, setIndustryImage] = useState<string | undefined>(undefined);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const filteredFields = fields.data.filter(
    (field: Field) => field.industry_id === selectedIndustryId
  );

  const industry = useMemo(
    () => industries.data.find((industry) => industry.id === selectedIndustryId),
    [selectedIndustryId]
  );

  useEffect(() => {
    if (!industry?.id) return;
    const loadImage = async () => {
      try {
        const imageModule = await getCareerImage({ id: industry.id });
        setIndustryImage(imageModule.default);
      } catch (error) {
        console.error("Failed to load industry image:", error);
        // You might want to set a default image here
      }
    };

    loadImage();
  }, [industry?.id]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      {industry && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0.5,
            }}
          >
            <img
              src={industryImage}
              alt="industry"
              style={{ width: mobile ? 64 : 240, height: mobile ? 64 : 240 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: mobile ? "column" : "column-reverse",
              alignItems: "start",
              gap: 1,
            }}
          >
            <Typography>{industry.description}</Typography>
            <Typography variant="h6" sx={{ lineHeight: 1.2, color: Color.CHALKBOARD }}>
              {industry.title}
            </Typography>
            <Chip
              label="Willow Recommended"
              size="small"
              sx={{ color: Color.SURFACE_GREEN, backgroundColor: Color.MINT }}
            />
          </Box>
        </Box>
      )}
      <Grid container alignContent="start" spacing="20px">
        {filteredFields.map((field: Field, index: number) => (
          <Grid key={index} item xs={12} sm={6} lg={4}>
            <FieldCard field={field} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Fields;
