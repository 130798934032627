import { Box, Divider, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import StageSummaryBar from "./StageSummaryBar";

const StageSummary = () => {
  const allStudents = useRecoilValue(allSchoolStudentsAtom);
  const quizNotCompletedStudents = allStudents.filter((student) => !student.quizComplete);
  const quizCompleteStudents = allStudents.filter((student) => student.quizComplete);
  const bookmarkedOneCareerStudents = allStudents.filter(
    (student) => student.favoriteJobIds.length > 0
  );
  const bookmarkedOneProgramStudents = allStudents.filter(
    (student) => student.favoriteProgramIds.length > 0
  );
  return (
    <>
      <Box sx={{ mt: 0 }}>
        <Grid container spacing={3} columns={4}>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={allStudents.length} students={quizNotCompletedStudents} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={allStudents.length} students={quizCompleteStudents} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar
              denominator={allStudents.length}
              students={bookmarkedOneCareerStudents}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar
              denominator={allStudents.length}
              students={bookmarkedOneProgramStudents}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 0 }} />
        <Grid container spacing={3} columns={5} sx={{ pt: "2px" }}>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Not Finished Quiz
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Finished Quiz
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Bookmarked Careers
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Bookmarked Programs
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StageSummary;
