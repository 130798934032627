import { Box, Button, Grid, Typography } from "@mui/material";
import { MouseEvent } from "react";
import almaEdit from "../../../shared/assets/icons/almaIcons/almaEdit.svg";
import almaSchool from "../../../shared/assets/icons/almaIcons/almaSchool.svg";
import almaPerson from "../../../shared/assets/icons/almaIcons/almaPerson.svg";
import almaMoney from "../../../shared/assets/icons/almaIcons/almaMoney.svg";
import almaLamp from "../../../shared/assets/icons/almaIcons/almaLamp.svg";
import almaGrowth from "../../../shared/assets/icons/almaIcons/almaGrowth.svg";
import almaGraduation from "../../../shared/assets/icons/almaIcons/almaGraduation.svg";
import almaBook from "../../../shared/assets/icons/almaIcons/almaBook.svg";
import { STARTER_PROMPTS } from "../../utils/almaConstants";

const svgIcons = {
  almaBook,
  almaEdit,
  almaSchool,
  almaPerson,
  almaMoney,
  almaLamp,
  almaGrowth,
  almaGraduation,
};

type Props = {
  handleSubmit: (newMessage: string) => void;
  resetChat: () => void;
};

type StarterPrompt = {
  text: string;
  svg: string;
  prompt: string;
};

const ChatSidebar = ({ handleSubmit, resetChat }: Props) => {
  const handlePromptClick = (event: MouseEvent<HTMLElement>) => {
    const prompt = event.currentTarget.dataset.name;
    handleSubmit(prompt || "");
  };
  return (
    <Box
      sx={{
        height: "calc(100vh - 132px)",
        p: 2,
        mt: 0,
        mr: 0,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ height: "80vh", overflow: "scroll" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Discover
        </Typography>
        <Typography>
          <b>Not sure where to start?</b> Below are some common topics to get you started!
        </Typography>
        <Grid container spacing={1}>
          {STARTER_PROMPTS.map((prompt: StarterPrompt) => (
            <Grid item xs={6} key={prompt.svg}>
              <Box
                sx={{
                  p: 1,
                  fontWeight: 300,
                  borderRadius: 4,
                  backgroundColor: "#F2F3F2",
                  mt: 2,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "end",
                  fontSize: 12,
                  height: 80,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#1DBC9D",
                    color: "#fff",
                  },
                }}
                data-name={prompt.prompt}
                onClick={handlePromptClick}
              >
                <Typography>
                  <b>{prompt.text}</b>
                </Typography>
                <img src={svgIcons[prompt.svg as keyof typeof svgIcons]} alt="icon" width="40px" />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        onClick={resetChat}
        disableRipple
        fullWidth
        type="submit"
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Reset Chat
      </Button>
    </Box>
  );
};

export default ChatSidebar;
