import { Box, Typography } from "@mui/material";
import { JobDetailsRecord } from "../../../shared/types/types";
import { Color, ProjectionGrowthOption } from "../../../shared/types/enums";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import WhatIsThat from "../whatIsThat/WhatIsThat";
import jobDescriptions from "../../assets/data/jobDescriptions.json";

type Props = {
  job: JobDetailsRecord;
};

const QuickLook = ({ job }: Props) => {
  const formatGrowth = (value: string | null) => {
    const lowercase = value?.toLowerCase();
    switch (lowercase) {
      case ProjectionGrowthOption.DECLINE:
        return <Typography sx={{ color: Color.RED, fontWeight: 600 }}>Declining</Typography>;
      case ProjectionGrowthOption.LITTLE_OR_NO_CHANGE:
        return (
          <Typography sx={{ color: Color.MEDIUM_DARK_ORANGE, fontWeight: 600 }}>
            Low Growth
          </Typography>
        );
      case ProjectionGrowthOption.FASTER_THAN_AVERAGE:
        return <Typography sx={{ color: Color.MINT, fontWeight: 600 }}>Good</Typography>;
      case ProjectionGrowthOption.MUCH_FASTER_THAN_AVERAGE:
        return <Typography sx={{ color: Color.MINT, fontWeight: 600 }}>Great</Typography>;
      case ProjectionGrowthOption.AVERAGE:
        return <Typography sx={{ color: Color.ORANGE_700, fontWeight: 600 }}>Average</Typography>;
      default:
        return <Typography>{value}</Typography>;
    }
  };

  const formatCompetition = (value: number) => {
    switch (true) {
      case value > 80:
        return <Typography sx={{ color: Color.RED, fontWeight: 600 }}>Very High</Typography>;
      case value > 60:
        return (
          <Typography sx={{ color: Color.MEDIUM_DARK_ORANGE, fontWeight: 600 }}>High</Typography>
        );
      case value > 40:
        return <Typography sx={{ color: Color.ORANGE_700, fontWeight: 600 }}>Medium</Typography>;
      case value > 20:
        return <Typography sx={{ color: Color.MINT, fontWeight: 600 }}>Low</Typography>;
      case value > -1:
        return (
          <Typography sx={{ color: Color.ELECTRIC_BLUE, fontWeight: 600 }}>Very Low</Typography>
        );
      default:
        return <Typography>{value}</Typography>;
    }
  };

  const formatSalary = (value: number) => {
    switch (true) {
      case value > 60000:
        return (
          <Typography sx={{ color: Color.MINT, fontWeight: 600 }}>
            {formatCurrency({ amount: value, decimalPlaces: 0 })}
          </Typography>
        );
      case value > 30000:
        return (
          <Typography sx={{ color: Color.ORANGE_700, fontWeight: 600 }}>
            {formatCurrency({ amount: value, decimalPlaces: 0 })}
          </Typography>
        );
      case value <= 30000:
        return (
          <Typography sx={{ color: Color.RED, fontWeight: 600 }}>
            {formatCurrency({ amount: value, decimalPlaces: 0 })}
          </Typography>
        );
      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">Job Prospect</Typography>
          <WhatIsThat information={jobDescriptions.job_prospect} />
        </Box>
        <Box>{formatGrowth(job.projectedGrowth)}</Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">Job Competition</Typography>
          <WhatIsThat information={jobDescriptions.job_competition} />
        </Box>
        <Box>
          {job.competition ? formatCompetition(job.competition) : <Typography>Unknown</Typography>}
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">Median Salary</Typography>
          <WhatIsThat information={jobDescriptions.median_salary} />
        </Box>
        <Box>
          {job.medianSalary ? formatSalary(job.medianSalary) : <Typography>Unknown</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

export default QuickLook;
