import { Box } from "@mui/material";
import StudentProfileContainer from "../components/profile/StudentProfileContainer";
import { navigationSteps } from "../static/tourData";
import { TourName } from "../../shared/types/tourEnums";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { stepsAtom, tourNameAtom } from "../../shared/recoil/tourAtoms";
import ProgramDetailsDialog from "../../shared/components/programDetails/ProgramDetailsDialog";
import { ProviderProgramRecord } from "../../shared/types/types";

const StudentProfilePage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const setTourName = useSetRecoilState(tourNameAtom);
  const setSteps = useSetRecoilState(stepsAtom);
  //There are currently two different tours that happen on the profile page, one for the top nav and one for bottom. Currently, this is my best solution for navigating two tours on one page that can't get combined into one tour because some user action needs to happen before the second tour loads
  useEffect(() => {
    if (!loggedInStudent || !loggedInStudent.hasViewedPersonalityType) return;
    if (loggedInStudent.setupComplete) {
      setSteps(navigationSteps);
      setTourName(TourName.NAVIGATION);
    }
  }, [loggedInStudent, setSteps, setTourName]);
  const [selectedProgram, setSelectedProgram] = useState<ProviderProgramRecord | null>(null);
  return (
    <>
      {loggedInStudent && (
        <Box sx={{ minHeight: "calc(100VH-64px)" }}>
          <StudentProfileContainer loggedInStudent={loggedInStudent} />
          {selectedProgram && (
            <ProgramDetailsDialog
              isEditable={true}
              selectedProgram={selectedProgram}
              setSelectedProgram={setSelectedProgram}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default StudentProfilePage;
