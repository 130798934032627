import { createTheme } from "@mui/material/styles";
import { Color } from "./shared/types/enums";

declare module "@mui/material/Chip" {
  export interface ChipPropsColorOverrides {
    gray: true;
    white: true;
    lightPink: true;
    lightBlue: true;
    lightOrange: true;
    lightGreen: true;
    lightPurple: true;
    surfaceGreen: true;
  }
}

declare module "@mui/material/Button" {
  export interface ButtonPropsColorOverrides {
    gray: true;
    white: true;
    mint: true;
    close: true;
  }
}

declare module "@mui/material/AppBar" {
  export interface AppBarPropsColorOverrides {
    gray: true;
    white: true;
    mint: true;
    surfaceGreen: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
    subHeading: React.CSSProperties;
    cardProviderName: React.CSSProperties;
    cardLocation: React.CSSProperties;
    cardProgramName: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
    subHeading?: React.CSSProperties;
    cardProviderName?: React.CSSProperties;
    cardLocation?: React.CSSProperties;
    cardProgramName?: React.CSSProperties;
  }
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    gray: {
      main: string;
      contrastText: string;
    };
    close: {
      main: string;
      contrastText: string;
    };
    white: {
      main: string;
      contrastText: string;
    };
    charcoal: {
      main: string;
      contrastText: string;
    };
    chalkboard: {
      main: string;
      contrastText: string;
    };
    mint: {
      main: string;
      contrastText: string;
    };
    chalk: {
      main: string;
      contrastText: string;
    };
    black: {
      main: string;
      contrastText: string;
    };
    lightPink: {
      main: string;
      contrastText: string;
    };
    lightBlue: {
      main: string;
      contrastText: string;
    };
    lightOrange: {
      main: string;
      contrastText: string;
    };
    lightGreen: {
      main: string;
      contrastText: string;
    };
    lightPurple: {
      main: string;
      contrastText: string;
    };
    surfaceGreen: {
      main: string;
      contrastText: string;
    };
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    leadCardTitle: true;
    leadCardDate: true;
    leadCardInfo: true;
    subHeading: true;
    cardProviderName: true;
    cardLocation: true;
    cardProgramName: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: Color.MINT,
      contrastText: Color.WHITE,
    },
    secondary: {
      main: Color.CHALKBOARD,
      contrastText: Color.WHITE,
    },
    close: {
      main: "#555",
      contrastText: "#fff",
    },
    white: {
      main: Color.WHITE,
      contrastText: Color.BLACK,
    },
    gray: {
      main: Color.GRAY_200,
      contrastText: Color.BLACK,
    },
    charcoal: {
      main: Color.CHARCOAL,
      contrastText: Color.WHITE,
    },
    chalkboard: {
      main: Color.CHALKBOARD,
      contrastText: Color.WHITE,
    },
    mint: {
      main: Color.MINT,
      contrastText: Color.WHITE,
    },
    chalk: {
      main: Color.CHALK,
      contrastText: Color.BLACK,
    },
    black: {
      main: Color.BLACK,
      contrastText: Color.CHALK,
    },
    lightPink: {
      main: Color.LIGHT_PINK,
      contrastText: Color.DARK_PINK,
    },
    lightBlue: {
      main: Color.LIGHT_BLUE,
      contrastText: Color.DARK_BLUE,
    },
    lightOrange: {
      main: Color.LIGHT_ORANGE,
      contrastText: Color.DARK_ORANGE,
    },
    lightGreen: {
      main: Color.LIGHT_GREEN,
      contrastText: Color.DARK_GREEN,
    },
    lightPurple: {
      main: Color.LIGHT_PURPLE,
      contrastText: Color.DARK_PURPLE,
    },
    surfaceGreen: {
      main: Color.SURFACE_GREEN,
      contrastText: Color.CHALKBOARD,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h1: {
      fontSize: 56,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h2: {
      fontSize: 44,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h3: {
      fontSize: 32,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    subHeading: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
    },
    cardProviderName: {
      fontSize: 16,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      color: Color.MINT,
    },
    cardLocation: {
      fontSize: 12,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
      color: Color.BLACK,
    },
    cardProgramName: {
      fontSize: 20,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      color: Color.CHALK,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        // Set default color, border radius, and border color here
        sx: {
          borderRadius: 2, // Adjust border radius as needed
          borderColor: "mint", // Set border color
          fontSize: 12,
          fontWeight: 400,
          paddingY: 1,
          paddingX: 1.5,
          textTransform: "none",
          boxShadow:
            "0px 2px 4px -1px rgba(16, 24, 40, 0.08), 0px 2px 4px -1px rgba(16, 24, 40, 0.08)",
          "&:hover": {
            fontWeight: "bold",
          },
        },
      },
      styleOverrides: {
        // Override specific button variants if needed
        contained: {
          // Styles for contained buttons
        },
        outlined: {
          // Styles for outlined buttons
        },
        text: {
          // Styles for text buttons
        },
      },
    },
  },
});
